<template>
  <v-row>
    <v-col v-for="(group, index) in groupedResponses" :key="index" md="4" cols="12">
      <v-card outlined class="pa-4 mb-4" elevation="0">
        <v-card-title>{{ group.name_group }}</v-card-title>

        <v-list v-for="question in group.questions" :key="question.question_id">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-wrap mb-2">{{
                question.question_text
              }}</v-list-item-title>

              <v-list-item-subtitle
                class="text-wrap d-block"
                v-if="question.question_type === 'text'"
              >
                <b>Respuesta:</b> {{ question.text_response }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                class="text-wrap d-block"
                v-else-if="question.question_type === 'single_choice'"
              >
                <b>Opción seleccionada:</b> {{  question.selected_options.text }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else-if="question.question_type === 'multiple_choice'"
              >
                <b>Opciones seleccionadas:</b>
                <v-chip-group column>
                  <v-chip
                    class="text-wrap d-block py-2 h-auto"
                    v-for="option in question.selected_options"
                    :key="option.id"
                  >
                    {{ option.text }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    responses: {
      type: Array,
      required: true,
    },
  },
  name: "FormResponses9",
  data() {
    return {
      groupConfig: [
        { name_group: "Identificación", question_ids: [242, 255] },
        {
          name_group: "Datos Personales y Residencia",
          question_ids: [243, 314, 315, 316, 317],
        },
        { name_group: "Educación y Contacto", question_ids: [244, 318, 319] },
        { name_group: "Alojamiento Utilizado", question_ids: [320] },
        { name_group: "Detalles del Grupo de Viaje", question_ids: [230, 231] },
        {
          name_group: "Información sobre el Descubrimiento del Destino",
          question_ids: [237, 238],
        },
        {
          name_group: "Motivo y Medio de Transporte del Viaje",
          question_ids: [234, 235, 239, 240],
        },
        { name_group: "Municipios Visitados", question_ids: [321] },
        { name_group: "Actividades en el Guaviare", question_ids: [322] },
        {
          name_group: "Establecimientos de Alimentación",
          question_ids: [323,
324,
325,
326,
327,
328],
        },
        { name_group: "Valor de Gastos del Viaje", question_ids: [329, 330,
331,
332,
333,
334,
335,
336,
337] },
        {
          name_group: "Percepción General del Departamento",
          question_ids: [338,
339,
340,
341],
        },
        {
          name_group: "Información Recibida sobre la Oferta del Departamento",
          question_ids: [342, 343],
        },
        {
          name_group: "Acceso y Recomendación del Destino",
          question_ids: [344, 345],
        },
        {
          name_group: "Información y Fechas de Viaje",
          question_ids: [346, 347, 348],
        },
      ],
    };
  },
  computed: {
    groupedResponses() {
      return this.groupConfig
        .map((group) => {
          // Filtra las preguntas que pertenecen al grupo actual
          const questions = this.responses.filter((response) =>
            group.question_ids.includes(response.question_id)
          );

          // Retorna el grupo solo si contiene preguntas
          return questions.length > 0
            ? { name_group: group.name_group, questions }
            : null;
        })
        .filter((group) => group !== null); // Filtra los grupos que son null
    },
  },
};
</script>
