<template>
  <v-row>
    <v-col v-for="(group, index) in groupedResponses" :key="index" md="4" cols="12">
      <v-card outlined class="pa-4 mb-4" elevation="0">
        <v-card-title>{{ group.name_group }}</v-card-title>

        <v-list v-for="question in group.questions" :key="question.question_id">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-wrap mb-2">{{
                question.question_text
              }}</v-list-item-title>

              <v-list-item-subtitle class="text-wrap d-block" v-if="question.question_type === 'text'">
                <b>Respuesta:</b> {{ question.text_response }}
              </v-list-item-subtitle>

              <v-list-item-subtitle class="text-wrap d-block"
                v-else-if="question.question_type === 'single_choice'"
              >
                <b>Opción seleccionada:</b> {{ question.selected_options.text }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else-if="question.question_type === 'multiple_choice'"
              >
                <b>Opciones seleccionadas:</b>
                <v-chip-group column>
                  <v-chip class="text-wrap d-block py-2 h-auto" v-for="option in question.selected_options" :key="option.id">
                    {{ option.text }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    responses: {
      type: Array,
      required: true,
    },
  },
  name: "FormResponses10",
  data() {
    return {
      groupConfig: [
        { name_group: "Identificación", question_ids: [1, 10] },
        { name_group: "Ubicación del Atractivo", question_ids: [349, 350, 351] },
        { name_group: "Actividades de turismo de naturaleza", question_ids: [352] },
        { name_group: "Servicios en el Atractivo", question_ids: [353, 354] },
        { name_group: "Ecosistemas en el Atractivo", question_ids: [355] },
        { name_group: "Area protegida", question_ids: [356, 357] },
        { name_group: "Elementos e Infraestructura", question_ids: [358] },
        { name_group: "Estado de Elementos e Infraestructura", question_ids: [
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371,
          372,
          373
        ] },
        { name_group: "Tipo de Propiedad y vía de acceso", question_ids: [374, 375] },
        { name_group: "Precio de la entrada", question_ids: [376, 377, 378] },
        { name_group: "Tarifas", question_ids: [379, 380, 381, 382, 383] },
        { name_group: "Disponibilidad mensual", question_ids: [384] },
        { name_group: "Temporada Alta", question_ids: [385, 385] },
        {
          name_group: "Estudio de Capacidad de Carga",
          question_ids: [386, 387, 388, 389, 390],
        },
      ],
    };
  },
  computed: {
    groupedResponses() {
      return this.groupConfig
        .map((group) => {
          // Filtra las preguntas que pertenecen al grupo actual
          const questions = this.responses.filter((response) =>
            group.question_ids.includes(response.question_id)
          );

          // Retorna el grupo solo si contiene preguntas
          return questions.length > 0
            ? { name_group: group.name_group, questions }
            : null;
        })
        .filter((group) => group !== null); // Filtra los grupos que son null
    },
  },
};
</script>
