<template>
  <v-expansion-panels v-model="panel" class="mb-6" variant="accordion" elevation="0">
    <v-expansion-panel>
      <v-expansion-panel-title class="expansion-custom-h" expand-icon="mdi-menu-down">
        <p>{{ title }}</p>
        <template v-slot:actions>
          <v-btn
            variant="text"
            @click.stop="isMaximized = true"
            icon="mdi-window-maximize"
            class="mr-2"
            density="compact"
          >
          </v-btn>
          <v-btn variant="text" icon="mdi-chevron-down" density="compact"> </v-btn>
        </template>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <div v-if="!isMaximized">
          <div class="position-relative">
            <div
              ref="legendsContainer"
              :id="'legend-container' + graph_id"
              class="initial-legends mb-3"
            ></div>
            <div v-if="showAllInfo" class="bottom-shadow">
              <div
                @click="isMaximized = true"
                class="text-decoration-none text-primary font-weight-bold"
                role="button"
                tabindex="0"
              >
                Ver más información.
              </div>
            </div>
          </div>

          <canvas :id="graph_id"></canvas>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="isMaximized" transition="dialog-bottom-transition" fullscreen>
    <v-card>
      <v-card-title class="d-flex">
        <v-spacer></v-spacer>
        <v-btn icon variant="text" @click="isMaximized = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="justify-center align-center d-flex">
        <div v-if="isMaximized">
          <p class="text-h5 mb-5 text-center">{{ title }}</p>
          <div>
            <canvas class="mx-16" width="800" height="200" :id="graph_id"></canvas>
            <div
              style="column-count: 2; column-gap: 20px; display: block"
              :id="'legend-container' + graph_id"
              class="custom-legend"
            ></div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Chart from "chart.js/auto";

export default {
  props: {
    question_id: {
      type: Number,
      required: true,
    },
    form_id: {
      type: Number,
      required: true,
    },
    graph_id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  name: "Graph1Form1",
  data() {
    return {
      panel: 0,
      isMaximized: false,
      showAllInfo: false,
    };
  },
  computed: {},

  mounted() {
    this.getGraph();
  },
  watch: {
    panel() {
      this.getGraph();
    },
    isMaximized() {
      this.$nextTick(() => {
        this.getGraph();
      });
    },
  },

  methods: {
    validateShowAllInfo() {
      const legends = this.$refs.legendsContainer;
      if (legends) {
        if (legends.scrollTop < legends.scrollHeight - legends.clientHeight) {
          this.showAllInfo = true;
        } else {
          this.showAllInfo = false;
        }
      }
    },
    getGraph() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            `/charts/chart1form1/${this.question_id}?user_id=${this.$store.state.user.id}&form_id=${this.form_id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          }
        )
        .then((response) => {
          // Acorta el texto de cada label para el eje X
          const shortenedLabels = response.data.labels.map((label) =>
            label.length > 10 ? label.slice(0, 5) + "..." : label
          );
          // Guarda el texto completo para usar en el tooltip
          const fullLabels = response.data.labels;

          const ctx1 = document.getElementById(this.graph_id);
          const data1 = {
            labels: shortenedLabels,
            datasets: response.data.datasets,
          };

          const chart = new Chart(ctx1, {
            type: "scatter",
            data: data1,
            options: {
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                },
              },

              plugins: {
                legend: {
                  display: false,
                  position: this.position_labels,
                },
                tooltip: {
                  callbacks: {
                    title: function (tooltipItem) {
                      return fullLabels[tooltipItem[0].dataIndex];
                    },
                    label: function (tooltipItem) {
                      const datasetLabel =
                        data1.datasets[tooltipItem.datasetIndex].label || "";
                      // Usa `fullLabels` para el texto completo en el tooltip

                      const value =
                        data1.datasets[tooltipItem.datasetIndex].data[
                          tooltipItem.dataIndex
                        ];
                      return `${datasetLabel}: ${value}`;
                    },
                  },
                },
              },
            },
          });

          this.createCustomLegend(chart);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    createCustomLegend(chart) {
      // Selecciona el contenedor HTML donde se mostrará la leyenda
      const legendContainer = document.getElementById("legend-container" + this.graph_id);
      legendContainer.innerHTML = ""; // Limpia cualquier contenido anterior

      // Genera los elementos de la leyenda basados en los datasets del gráfico
      chart.data.datasets.forEach((dataset, index) => {
        // Crea un elemento de la leyenda
        const legendItem = document.createElement("div");
        legendItem.classList.add("legend-item");
        legendItem.style.cursor = "pointer";
        legendItem.style.display = "flex";
        legendItem.style.alignItems = "center";

        // Aplica la clase `active` inicialmente
        legendItem.classList.add("active");

        // Muestra el color del dataset
        const colorBox = document.createElement("span");
        colorBox.style.backgroundColor = dataset.backgroundColor;
        colorBox.style.width = "15px";
        colorBox.style.height = "15px";
        colorBox.style.display = "inline-block";
        colorBox.style.marginRight = "8px";
        colorBox.style.borderRadius = "3px";

        // Muestra el nombre del dataset
        const text = document.createElement("span");
        text.classList.add("text-caption");
        text.textContent = dataset.label;

        // Agrega el color y el texto al elemento de la leyenda
        legendItem.appendChild(colorBox);
        legendItem.appendChild(text);

        // Agrega funcionalidad al hacer clic en la leyenda
        legendItem.addEventListener("click", () => {
          // Alterna la visibilidad del dataset
          const meta = chart.getDatasetMeta(index);
          meta.hidden = !meta.hidden;

          // Alterna la clase `active` para subrayar el texto
          legendItem.classList.toggle("disabled", meta.hidden);
          chart.update();
        });

        // Agrega el elemento de la leyenda al contenedor
        legendContainer.appendChild(legendItem);
        this.validateShowAllInfo();
      });
    },
  },
};
</script>

<style>
.initial-legends {
  overflow: hidden; /* Permite scroll cuando el contenido excede el tamaño */
  max-height: 80px; /* Limita la altura del contenedor */
  position: relative; /* Necesario para que los elementos dentro se posicionen en relación al contenedor */
}

/* Sombra inferior */
.bottom-shadow {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 32px;
  background: linear-gradient(to top, rgb(255, 255, 255), #ffffffc4);
  z-index: 5;
}

.expansion-custom-h {
  max-height: 45px !important;
  min-height: 45px !important;
}
.custom-legend {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  margin-top: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s;
}

.legend-item.disabled span:last-child {
  text-decoration: line-through; /* Subraya el texto del label activo */
  color: grey;
}
</style>
