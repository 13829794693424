<template>
  <v-container fluid class="px-0" style="height: 100%">
    <v-card-text class="px-0">
      <v-row style="height: 100%">
        <v-col cols="12" md="6" offset-md="3" class="">
          <v-card rounded="lg">
            <v-card-title class="d-flex py-4">
              Tus Notificaciones
              <v-spacer></v-spacer>
              <!-- <v-btn color="primary" icon size="x-small" variant="text">
            <v-icon size="20">mdi-dots-horizontal</v-icon>
          </v-btn> -->
            </v-card-title>
            <div
              class="d-flex align-center justify-center bg-grey-lighten-4 py-0 rounded-lg mx-3"
            >
              <v-btn
                @click="filterNotification = 'General'"
                style="flex-grow: 1"
                height="28"
                rounded="lg"
                class="mx-0 text-caption text-capitalize"
                :variant="filterNotification === 'General' ? 'outlined' : 'text'"
                :class="
                  filterNotification === 'General'
                    ? 'bg-white no-hover select-option-filter'
                    : 'no-select-filter'
                "
                value="General"
              >
                <v-badge
                  v-if="totalNotifications > 0"
                  color="red-app"
                  :content="totalNotifications"
                  inline
                >
                  <span> General &nbsp;</span>
                </v-badge>
                <span v-else>General</span>
              </v-btn>
              <v-btn
                @click="filterNotification = 'Encuestas'"
                style="flex-grow: 1"
                height="28"
                rounded="lg"
                class="mx-0 text-caption text-capitalize"
                :variant="filterNotification === 'Encuestas' ? 'outlined' : 'text'"
                :class="
                  filterNotification === 'Encuestas'
                    ? 'bg-white no-hover select-option-filter'
                    : 'no-select-filter'
                "
                value="Encuestas"
              >
                Encuestas
              </v-btn>
              <v-btn
                @click="filterNotification = 'Inbox'"
                style="flex-grow: 1"
                height="28"
                rounded="lg"
                class="mx-0 text-caption text-capitalize"
                :variant="filterNotification === 'Inbox' ? 'outlined' : 'text'"
                :class="
                  filterNotification === 'Inbox'
                    ? 'bg-white no-hover select-option-filter'
                    : 'no-select-filter'
                "
                value="Inbox"
              >
                Inbox
              </v-btn>
            </div>

            <v-card-text class="px-0">
              <v-list  lines="three">
                <template v-for="(item, index) in itemsNotifications.slice(0, 5)" :key="index">
                  <v-list-item
                    v-if="item.message"
                    class="py-2 px-5"
                    :class="{ 'bg-grey-lighten-3': item.status == 'unread' }"
                  >
                    <template v-slot:prepend>
                      <v-avatar color="transparent">
                        <v-img
                          v-if="item.type == 'diligenciar-formulario'"
                          src="@/assets/img/admin/diligenciar-formulario.png"
                        >
                        </v-img>
                      </v-avatar>
                    </template>
                    <v-list-item-title
                      v-html="item.title"
                      class="text-caption font-weight-bold text-capitalize"
                    >
                    </v-list-item-title>
                    <v-list-item-subtitle v-html="item.message" class="text-caption">
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-caption">
                      <span
                        class="text-disabled text-primary"
                        v-html="item.fecha_string"
                      ></span>
                      <!-- • 
                  <span class="text-disabled text-primary" > {{  item.type == 'diligenciar-formulario' ? 'Diligenciar Fomulario' : null }} </span> -->
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>

            <!-- <v-card-actions class="py-3">
          <v-btn
            rounded="lg"
            block
            color="primary"
            variant="tonal"
            class="text-capitalize"
            @click="menuNotifications = false"
            :to="$store.state.user.roles[0] == 2 ? 'notificaciones' : 'notificaciones'"
          >
            Ver Notificaciones Anteriores
          </v-btn>
        </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>
<script>
import axios from 'axios';

export default {
  name: "NotificationsUser",
  data() {
    return {
      loadingGetData: false,
      menuProfile: "profile",
      watchPage: true,
      visible: false,

      totalNotifications: 0,
      filterNotification: "General",
      itemsNotifications: [],
    };
  },
  mounted() {
    this.listNotifications();
  },
  methods: {

    listNotifications() {
      this.loadingGetData = true;
      let url = process.env.VUE_APP_API_URL;

      axios
        .get(url + "/notifications/list?user_id=" + this.$store.state.user.id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.itemsNotifications = response.data;
          // this.totalNotifications = 0;
          setTimeout(() => {
            this.loadingGetData = false;
          }, 1000);
        })
        .catch((error) => {
          console.error("Error listing notifications", error);
        });
    },

  },
};
</script>

<style>
.profile-heading {
  margin-block-start: -6.5rem;
}
</style>
