<template>
  <v-select
    :menu-props="{ 'max-width': '100%' }"
    v-model="selectedValue"
    :items="items"
    item-value="id"
    item-title="text"
    :rules="validations"
    :density="dens == 1 ? 'compact' : 'default'"
    :variant="customv"
    :hide-details="hidedetails"
    @update:modelValue="emitSelectedValue"
  >
    

    <template v-slot:item="{ props, item, index }">
      <v-list-item v-bind="props" class="text-caption-font">
        <template v-slot:prepend="{ isActive }">
          <v-list-item-action start>
            <v-radio
              color="primary"
              :true-value="item.raw.id"
              :model-value="isActive"
              v-model="selectedValue"
            ></v-radio>
          </v-list-item-action>
        </template>
        <v-list-item-title class="text-wrap text-caption">
          <span class="text-caption">{{ item.raw.title }} </span>
        </v-list-item-title>
      </v-list-item>
      <v-divider v-if="index + 1 < items.length"></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "CustomSelectSingleComponent",
  props: {
    hidedetails: {
      type: Boolean,
      default: false,
    },
    customv: {
      type: String,
      default: "underlined",
    },
    dens: {
      type: String,
      default: "default",
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    validations: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.selectedValue = newValue;
    },
  },
  methods: {
    emitSelectedValue(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
