<template>
  <v-app>
    <component :is="layout" :countNotifications="countNotifications"></component>
  </v-app>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import LoginLayout from "./layouts/LoginLayout.vue";
import FormsLayout from "./layouts/FormsLayout.vue";
import AdminLayout from "./layouts/AdminLayout.vue";
import EntrepreneurLayout from "./layouts/EntrepreneurLayout.vue";
import AccountConfigLayout from "./layouts/AccountConfigLayout.vue";
import axios from "axios";
// import Pusher from "pusher-js";

export default {
  name: "App",
  components: {
    LoginLayout,
    FormsLayout,
    AdminLayout,
    EntrepreneurLayout,
    AccountConfigLayout
  },

  setup() {
//     window.Echo.channel("notifications-form-13").listen(
//   ".notification_sent", // Nota el punto "." antes del nombre
//   (data) => {
//     console.log("new message received");
//     console.log(data);
//     alert("New notification received");
//   }
// );


    const route = useRoute();

    const layout = computed(() => {
      switch (route.meta.role) {
        case "superadmin":
          return AdminLayout;
        case "admin":
          return FormsLayout;
        case "login":
          return LoginLayout;
        case "entrepreneur":
          return EntrepreneurLayout;
        case "account":
          return AccountConfigLayout;
        default:
          return null;
      }
    });

    return { layout };
  },

  data() {
    return {
      userId: null,
      countNotifications: 0,
    };
  },
  created() {
    // Observa cambios en el ID de usuario
    this.$watch(
      () => this.$store.state.user, // Observa el objeto user
      (newVal) => {
        if (newVal && newVal.id) {
          this.userId = newVal.id;
          this.initializeNotifications();
          this.countUnreadNotifications();
        }
      },
      { immediate: true } // Esto ejecuta el watcher inmediatamente al crear el componente si ya existe el valor
    );
  },

  methods: {
    initializeNotifications() {
      // const selfThis = this;
      window.Echo.channel('notifications-form-' + this.userId)
        .listen('.notification_sent', (e) => {
          if(e){
            this.countUnreadNotifications();
          }
        });
    },


    countUnreadNotifications() {
      let url = process.env.VUE_APP_API_URL;
      axios
        .get(url + "/notifications/count-unread?user_id=" + this.userId, {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`
         }
       })
        .then((response) => {
          this.countNotifications = response.data.unread_count ? response.data.unread_count : 0;
        })
        .catch((error) => {
          console.error("Error counting notifications", error);
        });
    },

  },
};
</script>

<style>
html {
  overflow-y: auto !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
