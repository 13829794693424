<template>
  <v-container
    class="pa-0"
    style="height: 100%; width: 100%; min-width: 100%; position: relative"
  >
    <NavBarAnimation1
      v-if="isMdOrLower"
      title="Formulario Percepción Turista Alojamiento"
      subtitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es muy importante para seguir construyendo un destino turístico sostenible. 
        Recuerde que digitar bien su número de identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos unas preguntas adicionales sobre su llegada al departamento."
    >
    </NavBarAnimation1>

    <v-app-bar class="elevation-0" v-if="isMdOrLower">
      <v-slide-group
        v-model="stepFormServiceOrder"
        class="py-4"
        center-active
        show-arrows
      >
        <v-slide-group-item
          v-for="(item, index) in stepTitles.filter((step) => step.show)"
          :key="index"
          v-slot="{ isSelected }"
          :value="item.id"
        >
          <v-list-item
            :color="isSelected ? 'primary' : 'grey-lighten-1'"
            @click="stepFormServiceOrder = index + 1"
          >
            <template v-slot:prepend>
              <v-avatar
                :color="
                  isSelected || stepFormServiceOrder > index + 1
                    ? 'primary'
                    : 'grey-lighten-1'
                "
                class="text-white"
                size="x-small"
              >
                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1"
                  >mdi-pencil</v-icon
                >
                <span v-else>{{ index + 1 }}</span>
              </v-avatar>
            </template>
            <v-list-item-title
              class="text-caption"
              :class="
                isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-slide-group-item>
      </v-slide-group>
    </v-app-bar>

    <v-row class="h-100 mh-100 auth">
      <v-col
        lg="5"
        xl="4"
        cols="12"
        class="d-lg-flex d-none align-center justify-center bg-primary pa-0"
        style="position: fixed; height: 100%"
      >
        <LeftPanelAnimation1
          title="Formulario Percepción Turista"
          subTitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es muy importante para seguir construyendo un destino turístico sostenible.
          Recuerde que digitar bien su número de identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos unas preguntas adicionales sobre su llegada al departamento."
          :wordsAnimate="['Alojamiento']"
        ></LeftPanelAnimation1>
      </v-col>
      <v-col class="ml-auto" lg="7" xl="8" cols="12" style="background: #fff; z-index: 9">
        <v-row class="h-100 mh-100">
          <v-col
            lg="5"
            xl="4"
            cols="12"
            class="d-lg-flex d-none align-center justify-center position-relative"
          >
            <div class="scroll-container-wrapper">
              <div
                class="scroll-container position-relative"
                ref="scrollContainer"
                @scroll="handleScroll"
              >
                <div class="item">
                  <div
                    class="mouse m-2"
                    :class="{
                      'animate__animated animate__fadeInDown': showIndicator,
                      'animate__animated animate__fadeOutDown': !showIndicator,
                    }"
                  ></div>
                </div>
                <v-timeline class="py-8" align="start" side="end" :line-thickness="1">
                  <v-timeline-item
                    v-for="(item, index) in stepTitles.filter((step) => step.show)"
                    :key="index"
                    :class="stepFormServiceOrder > item.id - 1 ? 'success-step' : ''"
                    :dot-color="
                      stepFormServiceOrder > item.id - 1 ? 'primary' : 'dot-customer'
                    "
                    size="small"
                  >
                    <template v-slot:opposite>
                      <p class="font-weight-medium text-end">
                        {{ showGroupLabel(item, index) ? item.label : null }}
                      </p>
                    </template>
                    <template v-slot:icon>
                      <v-btn
                        width="20"
                        height="20"
                        @click="stepFormServiceOrder = item.id"
                        size="x-small"
                        icon
                        variant="text"
                      >
                        <v-icon v-if="stepFormServiceOrder == item.id">mdi-pencil</v-icon>
                        <v-icon v-else-if="stepFormServiceOrder > item.id"
                          >mdi-check</v-icon
                        >
                        <span v-else>{{ index + 1 }}</span>
                      </v-btn>
                    </template>
                    <div class="d-flex">
                      <div :ref="'timelineItem' + item.id"></div>

                      <div
                        class="text-left text-body-2"
                        :class="{ 'text-primary': stepFormServiceOrder > item.id }"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-if="showTopShadow" class="top-shadow"></div>
              <div v-if="showBottomShadow" class="bottom-shadow"></div>
            </div>
          </v-col>

          <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
            <v-card width="100%" elevation="0">
              <v-card-text>
                <p class="text-h4 font-weight-bold text-primary">
                  {{ currentTitle.label }}
                </p>
                <p class="text-h5 font-weight-bold">
                  {{ currentTitle.title }}
                </p>
              </v-card-text>
              <v-col md="12" cols="12" class="py-1">
                <br />
              </v-col>

              <v-window
                :touch="false"
                direction="vertical"
                v-model="stepFormServiceOrder"
              >
                <v-window-item :value="1">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep1">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Lugar de Alojamiento</span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_g_accommodation_place"
                            :items="formItems.itemsAccommodationPlace"
                            item-value="user_id"
                            item-title="name_estable"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.user_id"
                                      :model-value="isActive"
                                      v-model="form.field_g_accommodation_place"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsAccommodationPlace.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Tipo de documento de identificación</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.items_type_doc"
                            :items="formItems.itemsDocumentType"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.id"
                                      :model-value="isActive"
                                      v-model="form.items_type_doc"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsDocumentType.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Número de documento</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_f_document_number"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep2">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es su nivel de percepción de las siguientes
                            caracteristicas del alojamiento, teniendo en cuenta que 5
                            <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y
                            1 "Muy insatisfecho"?
                          </span>

                          <v-list style="width: 100%">
                            <v-list-item
                              v-for="(item, index) in ratings_zone_comun"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="ratings_zone_comun_required"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep3">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es su nivel de percepción de las siguientes
                            caracteristicas del alojamiento, teniendo en cuenta que 5
                            <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y
                            1 "Muy insatisfecho"?
                          </span>

                          <v-list style="width: 100%">
                            <v-list-item
                              v-for="(item, index) in ratings_services"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="ratings_services_required"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="4">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep4">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es su nivel de percepción de las siguientes
                            caracteristicas del alojamiento, teniendo en cuenta que 5
                            <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y
                            1 "Muy insatisfecho"?
                          </span>

                          <v-list style="width: 100%">
                            <v-list-item
                              v-for="(item, index) in rating_room"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="rating_room_required"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="5">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep5">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es su nivel de percepción de las siguientes
                            caracteristicas del alojamiento, teniendo en cuenta que 5
                            <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y
                            1 "Muy insatisfecho"?
                          </span>

                          <v-list style="width: 100%">
                            <v-list-item class="px-0">
                              <v-list-item-title class="text-wrap"> </v-list-item-title>
                              <template v-slot:append>
                                <div
                                  class="d-flex text-center align-center justify-center"
                                  style="width: 100px"
                                >
                                  Sin servicio/No utilizó
                                </div>
                              </template>
                            </v-list-item>
                            <v-list-item
                              v-for="(item, index) in rating_services_lodging"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                              <template v-slot:append>
                                <div
                                  class="d-flex text-center align-center justify-center"
                                  style="width: 100px"
                                >
                                  <v-checkbox
                                    v-model="form[item.model]"
                                    value="NA"
                                    color="primary"
                                    hide-details
                                  ></v-checkbox>
                                </div>
                              </template>
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="rating_services_lodging_required"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="6">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep6">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Consumio Alimentos ofertados por el establecimiento de
                            alojamiento?</span
                          >
                          <span class="font-weight-bold">*</span>
                          
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_g_food_consumed_offered_by_establishment"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.itemsFoodConsumedOfferedByEstablishment"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_g_food_consumed_offered_by_establishment ==
                                  item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="7">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep7">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es su nivel de percepción de las siguientes
                            caracteristicas respecto a la comida adquirida, teniendo en
                            cuenta que 5 <v-icon color="yellow">mdi-star</v-icon> es "Muy
                            satisfecho" y 1 "Muy insatisfecho"?
                          </span>

                          <v-list style="width: 100%">
                            <v-list-item
                              v-for="(item, index) in rating_shop_food"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="rating_shop_food_required"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="8">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep8">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es su nivel de percepción respecto a la condición de
                            los edificios e instalaciones del alojamiento, teniendo en
                            cuenta que 5 <v-icon color="yellow">mdi-star</v-icon> es "Muy
                            buen estado" y 1 "Muy mal estado"?
                          </span>
                          <v-list style="width: 100%">
                            <v-list-item
                              v-for="(item, index) in rating_status_location"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="rating_status_location_required"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="9">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep9">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es su nivel de percepción de la relación calidad precio
                            del alojamiento, teniendo en cuenta que 5
                            <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y
                            1 "Muy insatisfecho"?</span
                          >

                          <v-list style="width: 100%">
                            <v-list-item
                              v-for="(item, index) in rating_calidad_precio"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="rating_calidad_precio_required"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="10">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep10">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es su nivel de satisfacción general del alojamiento,
                            teniendo en cuenta que 5
                            <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y
                            1 "Muy insatisfecho"?
                          </span>

                          <v-list style="width: 100%">
                            <v-list-item
                              v-for="(item, index) in rating_satisfaccion_general"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="rating_satisfaccion_general_required"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <v-card-actions>
                <br />
              </v-card-actions>
              <v-card-actions class="mb-9">
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  :disabled="stepFormServiceOrder > 1 ? false : true"
                  variant="text"
                  @click="goToPreviousStep"
                >
                  Anterior
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  v-if="
                    stepFormServiceOrder <
                    stepTitles.filter((step) => step.show)[
                      stepTitles.filter((step) => step.show).length - 1
                    ].id
                  "
                  color="primary"
                  variant="text"
                  @click="goToNextStep"
                >
                  Siguiente
                </v-btn>
                <v-btn
                  @click="saveForm"
                  class="text-capitalize"
                  v-else
                  color="primary"
                  variant="flat"
                >
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-overlay
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center position-fixed"
      scrim="#FFF"
      :opacity="1"
      v-model="loadingPageOverlay"
    >
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h4 class="text-h4 mt-4 font-weight-medium text-primary">Enviando formulario</h4>
        <p class="text-h6 font-weight-medium mt-2">
          Por favor, espera mientras procesamos tu solicitud.
        </p>
      </div>
    </v-overlay>
    <UserExperience></UserExperience>
    <DialogResponse
      @cancel="cancelDialogResponse"
      v-if="dialogResponse"
      :dialog="dialogResponse"
    ></DialogResponse>
  </v-container>
  <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import { useDisplay } from "vuetify";
import validationFields from "../functions/validationFields";
import LeftPanelAnimation1 from "./includes/LeftPanelAnimation1.vue";
import NavBarAnimation1 from "./includes/NavBarAnimation1.vue";
import UserExperience from "./includes/UserExperience.vue";
import axios from "axios";
import { useToast } from "vue-toastification";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import DialogResponse from "@/components/dialog/DialogResponse.vue";

export default {
  name: "FormPerceptionTouristLodging",
  mixins: [validationFields],
  components: {
    LeftPanelAnimation1,
    NavBarAnimation1,
    UserExperience,
    DialogResponse,
  },

  setup() {
    const { mdAndDown, smAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
      issmAndDown: smAndDown,
    };
  },

  data() {
    return {
      showTopShadow: false,
      showBottomShadow: true,
      form_relations_question: null,
      toast: useToast(),
      loadingPageOverlay: false,
      dialogResponse: null,
      formConfig: null,
      formItems: {
        itemsAccommodationPlace: [],
        itemsDocumentType: [],
        itemsFoodConsumedOfferedByEstablishment: [],
      },
      form: {
        field_g_accommodation_place: null,
        items_type_doc: null,
        field_f_document_number: null,
        field_g_accommodation_cleanliness: null,
        field_g_accommodation_furniture_condition: null,
        field_g_accommodation_wifi_connectivity: null,
        field_g_accommodation_promptness: null,
        field_g_accommodation_staff_service: null,
        field_g_accommodation_phone_service: null,
        field_g_accommodation_information_provided: null,
        field_g_accommodation_room_comfort: null,
        field_g_accommodation_furniture_state: null,
        field_g_accommodation_hygiene_cleanliness: null,
        field_g_accommodation_wifi_connectivity_duplicate: null,
        field_g_accommodation_wellness_activities: null,
        field_g_accommodation_hiking_birdwatching: null,
        field_g_accommodation_nature_activities: null,
        field_g_accommodation_wet_areas: null,
        field_g_accommodation_sports_areas: null,
        field_g_accommodation_laundry_service: null,
        field_g_accommodation_events: null,
        field_g_accommodation_transportation: null,
        field_g_accommodation_guidance: null,
        field_g_accommodation_parking: null,
        field_g_accommodation_wifi_service: null,
        field_g_accommodation_other_services: null,
        field_g_food_consumed_offered_by_establishment: null,
        field_g_food_menu_variety: null,
        field_g_food_consumed_menu: null,
        field_g_food_service_time: null,
        field_g_food_hygiene_cleanliness: null,
        field_g_food_price_quality: null,
        field_g_food_staff_treatment: null,
        field_g_building_condition: null,
        field_g_accommodation_price_quality: null,
        field_g_accommodation_overall_satisfaction: null,
      },
      ratings_zone_comun_required: null,
      ratings_zone_comun: [
        {
          model: "field_g_accommodation_cleanliness",
          label: "Higiene y Limpieza",
          value: 0,
        },
        {
          model: "field_g_accommodation_furniture_condition",
          label: "Estado de muebles",
          value: 0,
        },
        {
          model: "field_g_accommodation_wifi_connectivity",
          label: "Conectividad al WiFi",
          value: 0,
        },
      ],
      ratings_services_required: null,
      ratings_services: [
        { model: "field_g_accommodation_promptness", label: "Prontitud", value: 0 },
        {
          model: "field_g_accommodation_staff_service",
          label: "Trato del personal",
          value: 0,
        },
        {
          model: "field_g_accommodation_phone_service",
          label: "Servcicio telefonico",
          value: 0,
        },
        {
          model: "field_g_accommodation_information_provided",
          label: "Información brindada",
          value: 0,
        },
      ],
      //Rating de habitación

      rating_room_required: null,
      rating_room: [
        {
          model: "field_g_accommodation_room_comfort",
          label: "Comodidad de la habitación",
          value: 0,
        },
        {
          model: "field_g_accommodation_furniture_state",
          label: "Estado de muebles",
          value: 0,
        },
        {
          model: "field_g_accommodation_hygiene_cleanliness",
          label: "Higiene y Limpieza",
          value: 0,
        },
        {
          model: "field_g_accommodation_wifi_connectivity_duplicate",
          label: "Conectividad al WiFi",
          value: 0,
        },
      ],

      rating_services_lodging_required: null,
      rating_services_lodging: [
        {
          model: "field_g_accommodation_wellness_activities",
          label: "Actividades de bienestar (SPA, masajes, yoga)",
          value: 0,
        },
        {
          model: "field_g_accommodation_hiking_birdwatching",
          label: "Senderismo, aviturismo, observación de flora y fauna",
          value: 0,
        },
        {
          model: "field_g_accommodation_nature_activities",
          label: "Otras actividades en áreas naturales",
          value: 0,
        },
        { model: "field_g_accommodation_wet_areas", label: "Zonas húmedas", value: 0 },
        {
          model: "field_g_accommodation_sports_areas",
          label: "Zonas deportivas",
          value: 0,
        },
        {
          model: "field_g_accommodation_laundry_service",
          label: "Lavandería (enfocado al cliente)",
          value: 0,
        },
        { model: "field_g_accommodation_events", label: "Eventos", value: 0 },
        { model: "field_g_accommodation_transportation", label: "Transporte", value: 0 },
        { model: "field_g_accommodation_guidance", label: "Guianza", value: 0 },
        { model: "field_g_accommodation_parking", label: "Parqueadero", value: 0 },
        { model: "field_g_accommodation_wifi_service", label: "WIFI", value: 0 },
        { model: "field_g_accommodation_other_services", label: "Otros", value: 0 },
      ],

      rating_shop_food_required: null,
      rating_shop_food: [
        { model: "field_g_food_menu_variety", label: "Variedad del Menú", value: 0 },
        { model: "field_g_food_consumed_menu", label: "Menú consumido", value: 0 },
        { model: "field_g_food_service_time", label: "Tiempo de atención", value: 0 },
        {
          model: "field_g_food_hygiene_cleanliness",
          label: "Aseo e higiene de las instalaciones gastronómicas",
          value: 0,
        },
        {
          model: "field_g_food_price_quality",
          label: "Relación calidad precio",
          value: 0,
        },
        {
          model: "field_g_food_staff_treatment",
          label: "Trato del personal del restaurante",
          value: 0,
        },
      ],

      rating_status_location_required: null,
      rating_status_location: [
        {
          model: "field_g_building_condition",
          label: "Edificios e instalaciones físicas",
          value: 0,
        },
      ],

      rating_calidad_precio_required: null,
      rating_calidad_precio: [
        {
          model: "field_g_accommodation_price_quality",
          label: "Calidad precio del  alojamiento",
          value: 0,
        },
      ],

      rating_satisfaccion_general_required: null,
      rating_satisfaccion_general: [
        {
          model: "field_g_accommodation_overall_satisfaction",
          label: "Satisfacción general del alojamiento",
          value: 0,
        },
      ],
      guianzaTuristica: 0,
      shopingFood: 0,

      typesDocs: [
        "Cédula de ciudadanía",
        "Cédula de extranjería",
        "Pasaporte",
        "Permiso de permanencia",
        "Otro",
      ],

      placesLodging: ["Lugar 1", "Lugar 2", "Lugar 3"],

      isMobile: false,

      num: 120,
      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

      
     
      drawerStep: true,
      stepFormServiceOrder: 1,
      stepTitles: [
        {
          group: 1,
          label: "Alojamiento",
          id: 1,
          title: "Información general",
          show: true,
        },
        {
          group: 2,
          label: "Percepción del turista",
          id: 2,
          title: "Zonas Comunes",
          show: true,
        },
        {
          group: 2,
          label: "Percepción del turista",
          id: 3,
          title: "Servicio de recepción y servicio a la habitación",
          show: true,
        },
        {
          group: 2,
          label: "Percepción del turista",
          id: 4,
          title: "Habitación",
          show: true,
        },
        {
          group: 2,
          id: 5,
          label: "Percepción del turista",
          title: "Alojamiento",
          show: true,
        },
        {
          group: 2,
          id: 6,
          label: "Percepción del turista",
          title: "Consumo de alimentos",
          show: true,
        },
        {
          group: 2,
          id: 7,
          label: "Percepción del turista",
          title: "Alimentos",
          show: false,
        },
        {
          group: 2,
          id: 8,
          label: "Percepción del turista",
          title: "Condiciones de las instalaciones",
          show: true,
        },
        {
          group: 2,
          id: 9,
          label: "Percepción del turista",
          title: "Relación calidad precio",
          show: true,
        },
        {
          group: 2,
          id: 10,
          label: "Percepción del turista",
          title: "Satisfacción general",
          show: true,
        },
      ],

      showIndicator: true, // Initially show the indicator
    };
  },
  watch: {
    "form.field_g_accommodation_cleanliness"() {
      this.validateRatingsZoneComunRequired();
    },
    "form.field_g_accommodation_furniture_condition"() {
      this.validateRatingsZoneComunRequired();
    },
    "form.field_g_accommodation_wifi_connectivity"() {
      this.validateRatingsZoneComunRequired();
    },

    "form.field_g_accommodation_promptness"() {
      this.validateRatingsServices();
    },
    "form.field_g_accommodation_staff_service"() {
      this.validateRatingsServices();
    },
    "form.field_g_accommodation_phone_service"() {
      this.validateRatingsServices();
    },
    "form.field_g_accommodation_information_provided"() {
      this.validateRatingsServices();
    },

    "form.field_g_accommodation_room_comfort"() {
      this.validateRatingRoom();
    },
    "form.field_g_accommodation_furniture_state"() {
      this.validateRatingRoom();
    },
    "form.field_g_accommodation_hygiene_cleanliness"() {
      this.validateRatingRoom();
    },
    "form.field_g_accommodation_wifi_connectivity_duplicate"() {
      this.validateRatingRoom();
    },

    "form.field_g_accommodation_wellness_activities"() {
      this.validateRatingServicesLodging();
    },
    "form.field_g_accommodation_hiking_birdwatching"() {
      this.validateRatingServicesLodging();
    },
    "form.field_g_accommodation_nature_activities"() {
      this.validateRatingServicesLodging();
    },
    "form.field_g_accommodation_wet_areas"() {
      this.validateRatingServicesLodging();
    },
    "form.field_g_accommodation_sports_areas"() {
      this.validateRatingServicesLodging();
    },
    "form.field_g_accommodation_laundry_service"() {
      this.validateRatingServicesLodging();
    },
    "form.field_g_accommodation_events"() {
      this.validateRatingServicesLodging();
    },
    "form.field_g_accommodation_transportation"() {
      this.validateRatingServicesLodging();
    },
    "form.field_g_accommodation_guidance"() {
      this.validateRatingServicesLodging();
    },
    "form.field_g_accommodation_parking"() {
      this.validateRatingServicesLodging();
    },
    "form.field_g_accommodation_wifi_service"() {
      this.validateRatingServicesLodging();
    },
    "form.field_g_accommodation_other_services"() {
      this.validateRatingServicesLodging();
    },

    "form.field_g_food_menu_variety"() {
      this.validateRatingShopFood();
    },
    "form.field_g_food_consumed_menu"() {
      this.validateRatingShopFood();
    },
    "form.field_g_food_service_time"() {
      this.validateRatingShopFood();
    },
    "form.field_g_food_hygiene_cleanliness"() {
      this.validateRatingShopFood();
    },
    "form.field_g_food_price_quality"() {
      this.validateRatingShopFood();
    },
    "form.field_g_food_staff_treatment"() {
      this.validateRatingShopFood();
    },

    "form.field_g_building_condition"() {
      if (this.form.field_g_building_condition) {
        this.rating_status_location_required = 1;
      } else {
        this.rating_status_location_required = null;
      }
    },
    "form.field_g_accommodation_price_quality"() {
      if (this.form.field_g_accommodation_price_quality) {
        this.rating_calidad_precio_required = 1;
      } else {
        this.rating_calidad_precio_required = null;
      }
    },
    "form.field_g_accommodation_overall_satisfaction"() {
      if (this.form.field_g_accommodation_overall_satisfaction) {
        this.rating_satisfaccion_general_required = 1;
      } else {
        this.rating_satisfaccion_general_required = null;
      }
    },

    "form.field_g_food_consumed_offered_by_establishment"(){
      this.rating_shop_food_required = null;
      this.form.field_g_food_menu_variety = null;
      this.form.field_g_food_consumed_menu = null;
      this.form.field_g_food_service_time = null;
      this.form.field_g_food_hygiene_cleanliness = null;
      this.form.field_g_food_price_quality = null;
      this.form.field_g_food_staff_treatment = null;

      if(this.form.field_g_food_consumed_offered_by_establishment == 522){
        const form7 = this.stepTitles.findIndex((step) => step.id === 7);
        this.stepTitles[form7].show = true;
      }else{
        const form7 = this.stepTitles.findIndex((step) => step.id === 7);
        this.stepTitles[form7].show = false;
      }
    },

    stepFormServiceOrder(newVal) {
      if (newVal < this.stepTitles.length) {
        this.scrollToItem(newVal);
      }
    },
  },
  methods: {
    validateRatingsZoneComunRequired() {
      if (
        this.form.field_g_accommodation_cleanliness &&
        this.form.field_g_accommodation_furniture_condition &&
        this.form.field_g_accommodation_wifi_connectivity
      ) {
        this.ratings_zone_comun_required = 1;
      } else {
        this.ratings_zone_comun_required = null;
      }
    },
    validateRatingsServices() {
      if (
        this.form.field_g_accommodation_promptness &&
        this.form.field_g_accommodation_staff_service &&
        this.form.field_g_accommodation_phone_service &&
        this.form.field_g_accommodation_information_provided
      ) {
        this.ratings_services_required = 1;
      } else {
        this.ratings_services_required = null;
      }
    },
    validateRatingRoom() {
      if (
        this.form.field_g_accommodation_room_comfort &&
        this.form.field_g_accommodation_furniture_state &&
        this.form.field_g_accommodation_hygiene_cleanliness &&
        this.form.field_g_accommodation_wifi_connectivity_duplicate
      ) {
        this.rating_room_required = 1;
      } else {
        this.rating_room_required = null;
      }
    },

    validateRatingServicesLodging() {
      if (
        this.form.field_g_accommodation_wellness_activities &&
        this.form.field_g_accommodation_hiking_birdwatching &&
        this.form.field_g_accommodation_nature_activities &&
        this.form.field_g_accommodation_wet_areas &&
        this.form.field_g_accommodation_sports_areas &&
        this.form.field_g_accommodation_laundry_service &&
        this.form.field_g_accommodation_events &&
        this.form.field_g_accommodation_transportation &&
        this.form.field_g_accommodation_guidance &&
        this.form.field_g_accommodation_parking &&
        this.form.field_g_accommodation_wifi_service &&
        this.form.field_g_accommodation_other_services
      ) {
        this.rating_services_lodging_required = 1;
      } else {
        this.rating_services_lodging_required = null;
      }
    },

    validateRatingShopFood() {
      if (
        this.form.field_g_food_menu_variety &&
        this.form.field_g_food_consumed_menu &&
        this.form.field_g_food_service_time &&
        this.form.field_g_food_hygiene_cleanliness &&
        this.form.field_g_food_price_quality &&
        this.form.field_g_food_staff_treatment
      ) {
        this.rating_shop_food_required = 1;
      } else {
        this.rating_shop_food_required = null;
      }
    },

    async saveForm() {
      this.loadingPageOverlay = true;
      let otherThis = this;
      await setTimeout(async function () {
        const allValid = await otherThis.validateAllSteps();

        if (allValid) {
          otherThis.formResponseStore();
        } else {
          otherThis.loadingPageOverlay = false;
          console.log("Hay errores en algunos de los formularios.");
        }
      }, 1000);
    },
    async validateAllSteps() {
      let allValid = true;

      // Filtramos los pasos visibles y recorremos su longitud
      const visibleSteps = this.stepTitles.filter((step) => step.show);

      for (let i = 0; i < visibleSteps.length; i++) {
        this.stepFormServiceOrder = visibleSteps[i].id; // Navegar al paso correspondiente
        await this.$nextTick(); // Esperar a que el DOM se actualice y el formulario se monte

        const formRef = this.$refs[`formStep${visibleSteps[i].id}`];

        if (formRef && formRef.validate) {
          const { valid } = await formRef.validate();

          if (!valid) {
            allValid = false;

            // Resaltar los campos con errores
            this.$nextTick(() => {
              const firstErrorField = formRef.$el.querySelector(".v-input--error");
              if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: "smooth" });
              }
            });

            break; // Detener la validación en el primer error encontrado
          }
        }
      }

      return allValid;
    },
    formResponseStore() {
      let url = process.env.VUE_APP_API_URL;

      axios
        .post(url + "/form-responses/store", {
          form: this.form,
          form_relations_question: this.form_relations_question,
          form_id: 8,
          season_id: this.season_id,
        })
        .then((response) => {
          this.dialogResponse = {
            title: "¡Formulario enviado con éxito!",
            message:
              "Tus respuestas han sido registradas correctamente. Gracias por tomarte el tiempo de completar el formulario. Puedes estar seguro de que tus datos han sido guardados de manera segura.",
            type: "success",
            color: "secondary",
            icon: "mdi-check",
            show: true,
            redirect: false,
          };
          console.log(response);
          this.loadingPageOverlay = false;
          //resetear todo el componente
          this.resetFormFields();
          this.stepFormServiceOrder = 1;
        })
        .catch((error) => {
          this.loadingPageOverlay = false;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status == 422
          ) {
            //ERROR RETORNADO POR LAS VALIDACIONES
            this.toast.error({
              component: ErrorLayoutComponent,
              props: {
                errorMessage: error.response.data,
              },
              timeout: 8000,
            });
          } else {
            this.dialogResponse = {
              title: "Error al enviar el formulario",
              message:
                "Ocurrió un problema al intentar guardar tus respuestas. Por favor, revisa los campos y vuelve a intentarlo. Si el problema persiste, contacta al soporte técnico.",
              type: "success",
              color: "red-app",
              icon: "mdi-cloud-alert",
              show: true,
              redirect: false,
            };
          }

          console.log(error);
        });
    },
    resetFormFields() {
      this.form = {
        field_g_accommodation_place: null,
        items_type_doc: null,
        field_f_document_number: null,
        field_g_accommodation_cleanliness: null,
        field_g_accommodation_furniture_condition: null,
        field_g_accommodation_wifi_connectivity: null,
        field_g_accommodation_promptness: null,
        field_g_accommodation_staff_service: null,
        field_g_accommodation_phone_service: null,
        field_g_accommodation_information_provided: null,
        field_g_accommodation_room_comfort: null,
        field_g_accommodation_furniture_state: null,
        field_g_accommodation_hygiene_cleanliness: null,
        field_g_accommodation_wifi_connectivity_duplicate: null,
        field_g_accommodation_wellness_activities: null,
        field_g_accommodation_hiking_birdwatching: null,
        field_g_accommodation_nature_activities: null,
        field_g_accommodation_wet_areas: null,
        field_g_accommodation_sports_areas: null,
        field_g_accommodation_laundry_service: null,
        field_g_accommodation_events: null,
        field_g_accommodation_transportation: null,
        field_g_accommodation_guidance: null,
        field_g_accommodation_parking: null,
        field_g_accommodation_wifi_service: null,
        field_g_accommodation_other_services: null,
        field_g_food_consumed_offered_by_establishment: null,
        field_g_food_menu_variety: null,
        field_g_food_consumed_menu: null,
        field_g_food_service_time: null,
        field_g_food_hygiene_cleanliness: null,
        field_g_food_price_quality: null,
        field_g_food_staff_treatment: null,
        field_g_building_condition: null,
        field_g_accommodation_price_quality: null,
        field_g_accommodation_overall_satisfaction: null,
      };

      this.stepFormServiceOrder = 1;
    },

    async getInfoForm() {
      try {
        let url = process.env.VUE_APP_API_URL;
        const token = this.$store.state.token;

        axios
          .get(url + `/forms/show/8`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.formConfig = response.data.form_config;
            this.form_relations_question = response.data.form_relations_question;

            if (this.formConfig && this.formConfig.questions) {
              const field_g_accommodation_place = this.formConfig.questions.find(
                (q) => q.v_model === "field_g_accommodation_place"
              );
              if (field_g_accommodation_place) {
                this.getAccommodationPlace();
              }

              const items_type_doc = this.formConfig.questions.find(
                (q) => q.v_model === "items_type_doc"
              );
              if (items_type_doc) {
                this.formItems.itemsDocumentType = items_type_doc.options;
              }

              const field_g_food_consumed_offered_by_establishment = this.formConfig.questions.find(
                (q) => q.v_model === "field_g_food_consumed_offered_by_establishment"
              );
              if (field_g_food_consumed_offered_by_establishment) {
                this.formItems.itemsFoodConsumedOfferedByEstablishment =
                  field_g_food_consumed_offered_by_establishment.options;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.showDeleteConfirmation = false;
      }
    },

    getAccommodationPlace() {
      /* this.formItems.itemsAccommodationPlace; */

      let url = process.env.VUE_APP_API_URL;
      axios
        .get(url + "/select-option/get-accommodations")
        .then((response) => {
          this.formItems.itemsAccommodationPlace = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

        // Control de las sombras
        this.showTopShadow = container.scrollTop > 0;
        this.showBottomShadow =
          container.scrollTop < container.scrollHeight - container.clientHeight;
      }
    },
    scrollToItem(index) {
      const item = this.$refs["timelineItem" + index][0]; // Acceso directo al primer elemento
      if (item) {
        const container = this.$refs.scrollContainer;
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Ajuste adicional para el margen superior
        const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

        container.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
        console.log(
          "Item Rect:",
          itemRect,
          "Container Rect:",
          containerRect,
          "Offset Top:",
          offsetTop
        );
      }
    },

    toggleActividadesCamara(activity) {
      const index = this.selectActividadesCamara.indexOf(activity);
      if (index === -1) {
        this.selectActividadesCamara.push(activity);
      } else {
        this.selectActividadesCamara.splice(index, 1);
      }
    },

    toggleActividadesOfrecidas(activity) {
      const index = this.selectActividadesOfrecidas.indexOf(activity);
      if (index === -1) {
        this.selectActividadesOfrecidas.push(activity);
      } else {
        this.selectActividadesOfrecidas.splice(index, 1);
      }
    },

    toggleServicioAdicional(activity) {
      const index = this.selectServicioAdicional.indexOf(activity);
      if (index === -1) {
        this.selectServicioAdicional.push(activity);
      } else {
        this.selectServicioAdicional.splice(index, 1);
      }
    },

    toggleGremiosAsociaciones(activity) {
      const index = this.selectGremiosAsociaciones.indexOf(activity);
      if (index === -1) {
        this.selectGremiosAsociaciones.push(activity);
      } else {
        this.selectGremiosAsociaciones.splice(index, 1);
      }
    },

    starryNight() {
      anime({
        targets: ["#sky .star"],
        opacity: [
          { duration: 700, value: "0" },
          { duration: 700, value: "1" },
        ],
        easing: "linear",
        loop: true,
        delay: (el, i) => 50 * i,
      });
    },
    shootingStars() {
      anime({
        targets: ["#shootingstars .wish"],
        easing: "linear",
        loop: true,
        delay: (el, i) => 1000 * i,
        opacity: [{ duration: 700, value: "1" }],
        width: [{ value: "150px" }, { value: "0px" }],
        translateX: 350,
      });
    },
    randomRadius() {
      return Math.random() * 0.7 + 0.6;
    },
    getRandomX() {
      return Math.floor(Math.random() * Math.floor(this.vw)).toString();
    },
    getRandomY() {
      return Math.floor(Math.random() * Math.floor(this.vh)).toString();
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    },

    goToPreviousStep() {
      if (this.previousStep) {
        this.stepFormServiceOrder = this.previousStep.id;
      }
    },
    async goToNextStep() {
      if (this.nextStep) {
        //VALIDAR FORMULARIO ACTUAL EN REF formStep1,2,3... etc
        const { valid } = await this.$refs[
          "formStep" + this.stepFormServiceOrder
        ].validate();
        if (valid) {
          this.stepFormServiceOrder = this.nextStep.id;
        }
      }
    },
    showGroupLabel(item, index) {
      // Verifica si es el primer paso del grupo visible
      return index === 0 || item.group !== this.visibleSteps[index - 1].group;
    },
    async cancelDialogResponse() {
      this.dialogResponse = null;
    },
  },

  mounted() {
    this.starryNight();
    this.shootingStars();

    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);

    this.getInfoForm();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  computed: {
    visibleSteps() {
      // Filtrar los pasos visibles
      return this.stepTitles.filter((item) => item.show);
    },
    currentTitle() {
      const step = this.stepTitles[this.stepFormServiceOrder - 1];
      // Busca el label del grupo al que pertenece el step actual
      const groupLabel = this.stepTitles.find(
        (item) => item.group === step.group && item.label
      )?.label;

      return {
        title: step.title,
        label: groupLabel || "", // Si no se encuentra el label, se asigna una cadena vacía
      };
    },

    nextStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return this.stepTitles.slice(currentIndex + 1).find((step) => step.show) || null;
    },

    previousStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return (
        this.stepTitles
          .slice(0, currentIndex)
          .reverse()
          .find((step) => step.show) || null
      );
    },
  },
};
</script>
<style>
.v-field__input {
  padding-top: 8px;
}

.v-row {
  margin: 0;
}
</style>
