<template>
  <v-navigation-drawer
    temporary
    touchless
    v-model="drawerUser"
    width="400"
    location="right"
    persistent
    style="height: 100%; top: 0; z-index: 1006; position: fixed"
  >
    <template v-slot:prepend>
      <v-list-item v-if="!loadingGetData">
        <v-list-item-action>
          <v-card-title class="font-weight-bold text-h5">Detalle Usuario</v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="close"
            icon
            size="x-small"
            variant="outlined"
            style="border: 2px solid"
            ><v-icon size="20">mdi-close</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
    </template>

    <v-card v-if="!loadingGetData && infoUser" elevation="0" color="transparent">
      <v-card-text class="pt-0">
        <v-row>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Nombre Completo</p>
            <p class="text-body-1 d-inline-block">{{ infoUser.name }}</p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Correo Electrónico</p>
            <p class="text-body-1 d-inline-block">{{ infoUser.email }}</p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Teléfono</p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.entrepreneur && infoUser.entrepreneur.phone
                  ? infoUser.entrepreneur.phone
                  : ""
              }}
              {{ infoUser.entity && infoUser.entity.phone ? infoUser.entity.phone : "" }}
              {{
                infoUser.tourist_attraction && infoUser.tourist_attraction.phone
                  ? infoUser.tourist_attraction.phone
                  : ""
              }}
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Rol</p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.roles && infoUser.roles[0] ? infoUser.roles[0].name : "&nbsp;"
              }}
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">
              Nombre de la Empresa o Entidad
            </p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.entity && infoUser.entity.entity_name
                  ? infoUser.entity.entity_name
                  : ""
              }}
              {{
                infoUser.entrepreneur && infoUser.entrepreneur.company_name
                  ? infoUser.entrepreneur.company_name
                  : ""
              }}
              {{
                infoUser.tourist_attraction && infoUser.tourist_attraction.company_name
                  ? infoUser.tourist_attraction.company_name
                  : ""
              }}
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Nit</p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.entrepreneur && infoUser.entrepreneur.nit
                  ? infoUser.entrepreneur.nit
                  : ""
              }}
              {{
                infoUser.tourist_attraction && infoUser.tourist_attraction.nit
                  ? infoUser.tourist_attraction.nit
                  : ""
              }}
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Municipio</p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.entrepreneur && infoUser.entrepreneur.town
                  ? infoUser.entrepreneur.town.text
                  : ""
              }}
              {{
                infoUser.tourist_attraction && infoUser.tourist_attraction.town
                  ? infoUser.tourist_attraction.town.text
                  : ""
              }}
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Dirección</p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.entrepreneur && infoUser.entrepreneur.address
                  ? infoUser.entrepreneur.address
                  : ""
              }}
              {{
                infoUser.tourist_attraction && infoUser.tourist_attraction.address
                  ? infoUser.tourist_attraction.address
                  : ""
              }}
            </p>
          </v-col>

          <v-divider class="mt-5 mb-3"></v-divider>

          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1 align-center">

            <div class="d-md-flex">
              <p class="font-weight-bold mb-1 text-body-1">Estado del usuario </p>

              <v-chip
              label
              size="small"
              :color="
                infoUser.status == 'Verificado'
                  ? 'green-app-2'
                  : infoUser.status == 'Registrado'
                  ? 'primary'
                  : infoUser.status == 'Rechazado'
                  ? 'red-app'
                  : 'grey-app-2'
              "
              variant="flat"
              class="d-flex justify-center font-weight-bold ml-auto"
              :class="{ 'text-white': infoUser.status == 'Verificado' }"
            >
              {{ infoUser.status }}
            </v-chip>
            </div>

            



            <v-form ref="formStatus">
              <div class="ml-auto">
                <v-radio-group
                  v-model="formUser.status"
                  color="secondary"
                  :rules="[validations.required]"
                >
                  <v-radio label="Verificado" value="Verificado"></v-radio>
                  <v-radio label="Rechazado" value="Rechazado"></v-radio>
                </v-radio-group>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <template v-slot:append>
      <div v-if="!loadingGetData" class="px-8 pb-9">
        <v-btn
          rounded="lg"
          :disabled="loading"
          :loading="loading"
          class="text-capitalize mb-3"
          color="primary"
          @click="updateStatusUser"
          block
        >
          Guardar
        </v-btn>
        <v-btn rounded="lg" @click="close" class="text-capitalize" variant="text" block>
          Cancelar
        </v-btn>
      </div>
    </template>

    <v-overlay
      v-model="loadingGetData"
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center"
      scrim="#FFF"
      :opacity="1"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";
import validationFields from "@/views/functions/validationFields";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import { useToast } from "vue-toastification";

export default {
  name: "DetailUser",
  mixins: [validationFields],

  props: {
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      toast: useToast(),
      loadingGetData: true,
      loading: false,
      drawerUser: true,
      visible: false,
      infoUser: null,
      formUser: {
        status: null,
      },
    };
  },

  watch: {
    userId: {
      immediate: true,
      handler(newValue) {
        this.getDataUser(newValue);
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async updateStatusUser() {
      const { valid } = await this.$refs.formStatus.validate();

      if (valid) {
        this.loading = true;
        let url = process.env.VUE_APP_API_URL;
        let data = {
          status: this.formUser.status,
        };

        axios
          .put(url + "/users/update-status/" + this.userId, data)
          .then((response) => {
            this.loading = false;
            console.log(response);

            this.$emit("update");
            this.close();
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status == 422) {
              //ERROR RETORNADO POR LAS VALIDACIONES
              this.toast.error({
                component: ErrorLayoutComponent,
                props: {
                  errorMessage: error.response.data,
                },
                timeout: 8000,
              });
            }
            console.log(error);
          });
      }
    },

    async getDataUser(userId) {
      this.loadingGetData = true;
      let url = process.env.VUE_APP_API_URL;

      axios
        .get(url + "/users/show/" + userId)
        .then((response) => {
          console.log(response);
          this.infoUser = response.data;
          if(this.infoUser.status == 'Verificado'){
            this.formUser.status = 'Verificado';
          }else if(this.infoUser.status == 'Rechazado'){
            this.formUser.status = 'Rechazado';
          } else {
            this.formUser.status = null;
          }
          this.loadingGetData = false;
        })
        .catch((error) => {
          this.loadingGetData = false;
          console.log(error);
        });
    },
  },
};
</script>
