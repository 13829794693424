<template>
  <v-row>
    <v-col v-for="(group, index) in groupedResponses" :key="index" md="4" cols="12">
      <v-card outlined class="pa-4 mb-4" elevation="0">
        <v-card-title>{{ group.name_group }}</v-card-title>

        <v-list v-for="question in group.questions" :key="question.question_id">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-wrap mb-2">{{
                question.question_text
              }}</v-list-item-title>

              <v-list-item-subtitle
                class="text-wrap d-block"
                v-if="question.question_type === 'text'"
              >
                <b>Respuesta:</b> {{ question.text_response }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                class="text-wrap d-block"
                v-else-if="question.question_type === 'single_choice'"
              >
                <b>Opción seleccionada:</b> {{ question.selected_options.text }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else-if="question.question_type === 'multiple_choice'"
              >
                <b>Opciones seleccionadas:</b>
                <v-chip-group column>
                  <v-chip
                    class="text-wrap d-block py-2 h-auto"
                    v-for="option in question.selected_options"
                    :key="option.id"
                  >
                    {{ option.text }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    responses: {
      type: Array,
      required: true,
    },
  },
  name: "FormResponses7",
  data() {
    return {
      groupConfig: [
        { name_group: "Información general", question_ids: [246, 242, 255] },
        {
          name_group: "Sitio turístico",
          question_ids: [256, 257, 258, 259, 260, 261, 262, 263, 264, 265],
        },
        { name_group: "Sostenibilidad ambiental", question_ids: [266] },
        { name_group: "Sostenibilidad social", question_ids: [267] },
        { name_group: "Servicio de guianza turística", question_ids: [268] },
        {
          name_group: "Percepción de la guianza turística",
          question_ids: [269, 270, 271, 272],
        },
        { name_group: "Compra de comidas", question_ids: [273] },
        {
          name_group: "Percepción de comidas",
          question_ids: [274, 275, 276, 277, 278, 279],
        },
      ],
    };
  },
  computed: {
    groupedResponses() {
      return this.groupConfig
        .map((group) => {
          // Filtra las preguntas que pertenecen al grupo actual
          const questions = this.responses.filter((response) =>
            group.question_ids.includes(response.question_id)
          );

          // Retorna el grupo solo si contiene preguntas
          return questions.length > 0
            ? { name_group: group.name_group, questions }
            : null;
        })
        .filter((group) => group !== null); // Filtra los grupos que son null
    },
  },
};
</script>
