<template>
  <v-row>
    <v-col v-for="(group, index) in groupedResponses" :key="index" md="4" cols="12">
      <v-card outlined class="pa-4 mb-4" elevation="0">
        <v-card-title>{{ group.name_group }}</v-card-title>

        <v-list v-for="question in group.questions" :key="question.question_id">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-wrap mb-2">{{
                question.question_text
              }}</v-list-item-title>

              <v-list-item-subtitle class="text-wrap d-block" v-if="question.question_type === 'text'">
                <b>Respuesta:</b> {{ question.text_response }}
              </v-list-item-subtitle>

              <v-list-item-subtitle class="text-wrap d-block"
                v-else-if="question.question_type === 'single_choice'"
              >
                <b>Opción seleccionada:</b> {{ question.selected_options.text }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else-if="question.question_type === 'multiple_choice'"
              >
                <b>Opciones seleccionadas:</b>
                <v-chip-group column>
                  <v-chip class="text-wrap d-block py-2 h-auto" v-for="option in question.selected_options" :key="option.id">
                    {{ option.text }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    responses: {
      type: Array,
      required: true,
    },
  },
  name: "FormResponses11",
  data() {
    return {
      groupConfig: [
        { name_group: "Datos Básicos", question_ids: [14, 15, 1] },
        { name_group: "Ubicación y contacto", question_ids: [16, 18, 19] },
        { name_group: "Registro Nacional de Turismo", question_ids: [24, 25] },
        { name_group: "Cámara de Comercio", question_ids: [27] },
        { name_group: "Actualizaciones y Avales", question_ids: [391, 392] },
        {
          name_group: "Gestión de Recursos en Productos Turísticos",
          question_ids: [393, 394],
        },
        {
          name_group: "Verificación de Capacidad de Carga en Espacios Turísticos",
          question_ids: [395, 396],
        },
        {
          name_group: "Impacto de Buenas Prácticas en Sostenibilidad",
          question_ids: [397],
        },
        {
          name_group: "Aspectos Empresariales y Oportunidades Inclusivas",
          question_ids: [398, 399],
        },
        {
          name_group: "Atención a Turistas con Discapacidad",
          question_ids: [400, 401, 402, 403, 404],
        },
        {
          name_group: "Contratación de Colaboradores con Discapacidad",
          question_ids: [405, 406, 407, 408, 409],
        },
        {
          name_group: "Residuos en la Empresa",
          question_ids: [
            // 410,
            411,
            // 412,
            413,
            // 414,
            415,
            // 416,
            417,
            // 418,
            419,
            // 420,
            421,
            // 422,
            423,
            // 424,
            425,
            // 426,
            427,
            // 428,
            429,
            // 430,
            431,
            // 432,
            433,
            // 434,
            435,
            // 436,
            437,
            // 438,
            439,
            // 440,
            441,
            // 442,
            443,
            // 444,
            445,
            // 446,
            447,
          ],
        },
        { name_group: "Manejo de Residuos", question_ids: [448, 449, 450] },
        { name_group: "Capacitación y Barreras en Gestión", question_ids: [451, 452] },
        {
          name_group: "Información sobre energía en su empresa",
          question_ids: [453, 454, 455],
        },
        {
          name_group: "Medidas Implementadas y Desafíos en Eficiencia Energética",
          question_ids: [456, 457, 458],
        },
        {
          name_group: "Fuentes de Abastecimiento, Registro y Ahorro de Agua",
          question_ids: [459, 460, 461],
        },
        {
          name_group: "Capacitación y Desafíos en Ahorro de Agua",
          question_ids: [462, 463],
        },
        {
          name_group: "Manejo y Normativa de Aguas Residuales",
          question_ids: [464, 465],
        },
        { name_group: "Sensibilización y Retos", question_ids: [466, 467] },
        {
          name_group: "Garantías de Seguridad de Empleados y Clientes",
          question_ids: [468, 469, 470],
        },
        {
          name_group: "Medidas y Desafíos en la Reducción de Emisiones GEI",
          question_ids: [471, 472, 473],
        },
        { name_group: "Fuentes de Contaminación del Aire", question_ids: [474, 475] },
        {
          name_group: "Gestión y Sensibilización Ambiental",
          question_ids: [476, 477, 478],
        },
        {
          name_group: "Actividades y Normativas Ambientales",
          question_ids: [479, 480, 481],
        },
        { name_group: "Identificación de Obstáculos", question_ids: [482, 483] },
        { name_group: "Identificación de Obstáculos", question_ids: [484, 485] },
        { name_group: "Fomento del Desarrollo Local", question_ids: [486, 487, 488] },
        { name_group: "Desafíos para el Comercio Justo", question_ids: [489, 490] },
        {
          name_group: "Apoyo y Protección de la Cultura Local",
          question_ids: [491, 492, 493],
        },
        {
          name_group: "Acceso a Instancias y Espacios de Desarrollo",
          question_ids: [494, 495, 496],
        },
        {
          name_group: "Conocimiento y Colaboración en Espacios Comunitarios y Nacionales",
          question_ids: [497, 498, 499],
        },
        {
          name_group:
            "Conocimiento de Innovación, Valor Agregado y Tendencias de Mercado",
          question_ids: [500, 501, 502],
        },
      ],
    };
  },
  computed: {
    groupedResponses() {
      return this.groupConfig
        .map((group) => {
          // Filtra las preguntas que pertenecen al grupo actual
          const questions = this.responses.filter((response) =>
            group.question_ids.includes(response.question_id)
          );

          // Retorna el grupo solo si contiene preguntas
          return questions.length > 0
            ? { name_group: group.name_group, questions }
            : null;
        })
        .filter((group) => group !== null); // Filtra los grupos que son null
    },
  },
};
</script>
