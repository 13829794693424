<template>
  <v-layout class="rounded rounded-md" style="height: 100%">
    <v-main class="align-center justify-center">
      <v-row class="fill-height">
        <v-col cols="12" md="5" class="">
          <v-main style="height: 100%">
            <router-view />
          </v-main>

          <!-- <div class="d-flex pt-3">
                        <v-img height="60" :src="require('@/assets/img/logo-guaviare-color.png')"></v-img>
                        <v-spacer></v-spacer>
                    </div>

                    <div class="d-flex justify-center align-center mb-16 pb-6" style="height: 100%;">

                        <v-form ref="formlogin">
                            <v-card elevation="0" max-width="410" class="mx-auto">
                                <v-card-title class="">
                                    <h4 class="text-left text-h4 font-weight-bold"
                                        style="white-space: normal;word-break: auto-phrase;">
                                        Administrador encuestas de turismo</h4>
                                </v-card-title>
                                <v-card-subtitle class="text-wrap pb-9">
                                    Ingresa tu correo electrónico y contraseña para continuar.
                                </v-card-subtitle>
                                <v-card-text>

                                    <v-responsive class="mx-auto">
                                        <span class="font-weight-medium">
                                            Correo electrónico
                                        </span>
                                        <v-text-field rounded="lg" color="secondary" @keydown.enter="login" required
                                            name="email" autocomplete="new-password" variant="outlined"
                                            density="compact" type="input" v-model="email"></v-text-field>
                                    </v-responsive>
                                    <v-responsive class="mx-auto">
                                        <span class="font-weight-medium">
                                            Contraseña
                                        </span>
                                        <v-text-field rounded="lg" color="secondary" @keydown.enter="login" required
                                            autocomplete="new-password" v-model="password" variant="outlined"
                                            density="compact" type="password"></v-text-field>
                                    </v-responsive>
                                    <div class="d-flex">
                                      
                                        <v-spacer></v-spacer>
                                        <a class="text-decoration-none text-primary font-weight-medium d-flex align-self-center"
                                            href="/password/reset">¿Olvidó su
                                            contraseña?</a>
                                    </div>
                                    <v-responsive class="mx-auto mt-5 pt-5">

                                        <v-hover>
                                            <template v-slot:default="{ isHovering, props }">
                                                <v-btn v-bind="props" :disabled="loadingForm" rounded="lg"
                                                    :loading="loadingForm" @click="login" color="secondary" block
                                                    :variant="isHovering ? 'outlined' : 'flat'" class="text-capitalize">
                                                    <span v-if="isHovering"
                                                        :class="{ 'animate__animated  animate__slideInLeft': isHovering }">
                                                        <v-icon> mdi-arrow-right </v-icon> Ingresar Ahora </span>
                                                    <span class="animate__animated animate__slideInRight"
                                                        :class="{ 'd-none': isHovering }"> Ingresar Ahora </span>
                                                    <div class="area">
                                                        <ul class="circles">
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                        </ul>
                                                    </div>
                                                </v-btn>
                                            </template>
                                        </v-hover>

                                    </v-responsive>
                                   
                                    <v-responsive class="mx-auto py-5 mt-4 text-center">
                                        <span> ¿No tienes una cuenta? <a href="#"
                                                class="text-decoration-none text-primary font-weight-medium">Registrate</a>
                                        </span>
                                    </v-responsive>


                                </v-card-text>
                            </v-card>
                        </v-form>

                    </div> -->
        </v-col>

        <v-col
          cols="12"
          md="7"
          class="hidden-sm-and-down slider-login right-0 position-fixed"
        >
          <div style="position: absolute; height: 100%; width: 100%; z-index: 1">
            <div class="d-flex justify-center align-end pb-6" style="height: 90%">
              <div class="rounded-lg card-testimonial" zn_id="24">
                <div class="content-testimonial overflow-hidden">
                  <v-window v-model="testimonialActive">
                    <v-window-item v-for="(item, index) in testimonials" :key="index">
                      <v-card
                        class="elevation-0 ma-5 text-white"
                        elevation="2"
                        max-width="500"
                        color="transparent"
                      >
                        <v-card-text class="text-center">
                          <p class="font-weight-regular text-body-1 font-italic">
                            ❝ {{ item.testimonial }} ❞
                          </p>
                        </v-card-text>
                        <v-card-actions class="pa-0">
                          <div class="text-start">
                            <p class="font-weight-bold text-body-1">-{{ item.name }}-</p>
                            <p class="font-weight-regular text-body-2">{{ item.role }}</p>
                          </div>
                        </v-card-actions>
                      </v-card>
                    </v-window-item>
                  </v-window>
                </div>
              </div>
            </div>
            <div class="d-flex justify-end align-end pb-6" style="height: 10%">
              <v-card-actions class="justify-space-between px-5">
                <v-btn
                class="mx-2"
                  icon="mdi-arrow-left"
                  variant="tonal"
                  color="white"
                  @click="prevTestimonial"
                ></v-btn>

                <v-btn
                class="mx-2"
                  icon="mdi-arrow-right"
                  variant="tonal"
                  color="white"
                  @click="nextTestimonial"
                ></v-btn>
              </v-card-actions>
            </div>
          </div>

          <v-carousel
            hide-delimiters
            cycle
            :show-arrows="false"
            height="100%"
            class="app-carousel"
            style="z-index: 0"
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
              cover
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-main>
  </v-layout>
</template>

<script>
export default {
  name: "LoginLayout",

  data: () => ({
    testimonialActive: 0,
    testimonials: [
      {
        name: "Camila Rojas",
        role: "Turista",
        testimonial:
          "Visitar el Guaviare ha sido una experiencia transformadora. La riqueza natural y cultural que encontré aquí superó todas mis expectativas. Desde los paisajes hasta la calidez de su gente, cada momento fue inolvidable. ¡Recomiendo a todos conocer este hermoso destino!",
        image: "camila_rojas.jpg", // Ruta a la imagen del testimonio
      },
      {
        name: "José Martínez",
        role: "Empresario",
        testimonial:
          "El Observatorio de Turismo del Guaviare ha sido un aliado clave en el crecimiento de mi empresa. Gracias a la información y las herramientas que proporcionan, hemos podido adaptar nuestros servicios a las necesidades de los turistas y mejorar la experiencia de nuestros clientes.",
        image: "jose_martinez.jpg",
      },
      {
        name: "Laura Gómez",
        role: "Turista",
        testimonial:
          "El Guaviare es un destino que te conecta con la naturaleza de una manera única. Nunca había visto un cielo tan lleno de estrellas ni escuchado el canto de tantos pájaros diferentes. Es un lugar mágico que invita a volver una y otra vez.",
        image: "laura_gomez.jpg",
      },
      {
        name: "Andrés Ramírez",
        role: "Guía Turístico",
        testimonial:
          "Ser guía en el Guaviare es un privilegio. Cada día es una nueva aventura, mostrando a los visitantes las maravillas que este lugar ofrece. La biodiversidad, la historia y la cultura hacen de cada recorrido una experiencia educativa y emocionante.",
        image: "andres_ramirez.jpg",
      },
      {
        name: "Sofía Herrera",
        role: "Turista",
        testimonial:
          "El Guaviare es un destino que todos deberían visitar al menos una vez en la vida. Desde los imponentes cerros hasta los ríos cristalinos, cada rincón es una postal. Además, la hospitalidad de la gente local hace que uno se sienta en casa desde el primer momento.",
        image: "sofia_herrera.jpg",
      },
    ],
    items: [
      {
        src: require("@/assets/img/slider-2.jpg"),
      },
      {
        src: require("@/assets/img/slider-3.jpg"),
      },
      {
        src: require("@/assets/img/slider-1.jpg"),
      },

      {
        src: require("@/assets/img/slider-4.jpg"),
      },
      {
        src: require("@/assets/img/slider-5.jpg"),
      },
    ],
  }),
  methods: {
    nextTestimonial() {

      this.testimonialActive = this.testimonialActive + 1 > (this.testimonials.length - 1)
          ? 0
          : this.testimonialActive + 1

    },
    prevTestimonial() {
        this.testimonialActive = this.testimonialActive - 1 <= 0
          ? (this.testimonials.length - 1)
          : this.testimonialActive - 1
    },
  },
};
</script>

<style>
.card-testimonial {
  background-color: #00a5504d;
}

.content-testimonial {
  position: relative;
  padding: 20px;
}

.content-testimonial::before {
  content: "\F0757";
  font: normal normal normal 24px / 1 "Material Design Icons" !important;
  position: absolute;
  color: #00a551;
  font-size: 129px !important;
  top: -45px;
  left: 0;
}
</style>
