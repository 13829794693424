<template>
  <v-container
    class="pa-0"
    style="height: 100%; width: 100%; min-width: 100%; position: relative"
  >
    <NavBarAnimation2
      classCustom="custom-color-circles1"
      v-if="isMdOrLower"
      title="Formulario Registro de Visitantes"
      subtitle="En este formulario se realiza el registro del (los) turista (s) según 
                su grupo de viaje. Si se registra la información de manera correcta, sus datos quedarán guardados efectivamente en el sistema y será 
                más fácil realizar los registros posteriores en los atractivos que visiten, así como evaluar la percepción del servicio de alojamiento, 
                actividades turísticas y del destino en general."
    ></NavBarAnimation2>

    <v-app-bar class="elevation-0" v-if="isMdOrLower">
      <v-slide-group
        v-model="stepFormServiceOrder"
        class="py-4"
        center-active
        show-arrows
      >
        <v-slide-group-item
          v-for="(item, index) in stepTitles.filter((step) => step.show)"
          :key="index"
          v-slot="{ isSelected }"
          :value="item.id"
        >
          <v-list-item
            :color="isSelected ? 'primary' : 'grey-lighten-1'"
            @click="stepFormServiceOrder = index + 1"
          >
            <template v-slot:prepend>
              <v-avatar
                :color="
                  isSelected || stepFormServiceOrder > index + 1
                    ? 'primary'
                    : 'grey-lighten-1'
                "
                class="text-white"
                size="x-small"
              >
                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1"
                  >mdi-pencil</v-icon
                >
                <span v-else>{{ index + 1 }}</span>
              </v-avatar>
            </template>
            <v-list-item-title
              class="text-caption"
              :class="
                isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-slide-group-item>
      </v-slide-group>
    </v-app-bar>

    <v-row class="h-100 mh-100 auth">
      <v-col
        lg="5"
        xl="4"
        cols="12"
        class="d-lg-flex d-none align-center justify-center text-white pa-0 bg-secondary"
        style="position: fixed; height: 100%"
      >
        <LeftPanelAnimation2
          classCustom="custom-color-circles1"
          title="Formulario Registro de"
          subTitle="En este formulario se realiza el registro del (los) turista (s) según 
                su grupo de viaje. Si se registra la información de manera correcta, sus datos quedarán guardados efectivamente en el sistema y será 
                más fácil realizar los registros posteriores en los atractivos que visiten, así como evaluar la percepción del servicio de alojamiento, 
                actividades turísticas y del destino en general."
          :wordsAnimate="['Visitantes']"
        ></LeftPanelAnimation2>
      </v-col>
      <v-col lg="7" xl="8" cols="12" style="background: #fff; z-index: 9" class="ml-auto">
        <v-row class="h-100 mh-100">
          <v-col
            lg="5"
            xl="4"
            cols="12"
            class="d-lg-flex d-none align-center justify-center position-relative"
          >
            <div class="scroll-container-wrapper">
              <div
                class="scroll-container position-relative"
                ref="scrollContainer"
                @scroll="handleScroll"
              >
                <!-- <div class="item">
                  <div
                    class="mouse m-2"
                    :class="{
                      'animate__animated animate__fadeInDown': showIndicator,
                      'animate__animated animate__fadeOutDown': !showIndicator,
                    }"
                  ></div>
                </div> -->
                <v-timeline class="py-8" align="start" side="end" :line-thickness="1">
                  <v-timeline-item
                    v-for="(item, index) in stepTitles.filter((step) => step.show)"
                    :key="index"
                    :class="stepFormServiceOrder > item.id - 1 ? 'success-step' : ''"
                    :dot-color="
                      stepFormServiceOrder > item.id - 1 ? 'primary' : 'dot-customer'
                    "
                    size="small"
                  >
                    <template v-slot:opposite>
                      <p class="font-weight-medium text-end">
                        {{ showGroupLabel(item, index) ? item.label : null }}
                      </p>
                    </template>
                    <template v-slot:icon>
                      <v-btn
                        width="20"
                        height="20"
                        @click="stepFormServiceOrder = item.id"
                        size="x-small"
                        icon
                        variant="text"
                      >
                        <v-icon v-if="stepFormServiceOrder == item.id">mdi-pencil</v-icon>
                        <v-icon v-else-if="stepFormServiceOrder > item.id"
                          >mdi-check</v-icon
                        >
                        <span v-else>{{ index + 1 }}</span>
                      </v-btn>
                    </template>
                    <div class="d-flex">
                      <div :ref="'timelineItem' + item.id"></div>

                      <div
                        class="text-left text-body-2"
                        :class="{ 'text-primary': stepFormServiceOrder > item.id }"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-if="showTopShadow" class="top-shadow"></div>
              <div v-if="showBottomShadow" class="bottom-shadow"></div>
            </div>
          </v-col>

          <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
            <v-card width="100%" elevation="0">
              <v-card-text>
                <p class="text-h4 font-weight-bold text-primary">
                  {{ currentTitle.label }}
                </p>
                <p class="text-h5 font-weight-bold">
                  {{ currentTitle.title }}
                </p>
              </v-card-text>
              <v-col md="12" cols="12" class="py-1">
                <br />
              </v-col>

              <v-window
                :touch="false"
                direction="vertical"
                v-model="stepFormServiceOrder"
              >
                <v-window-item :value="1">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep1">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Lugar de alojamiento</span>
                          <span class="font-weight-bold">*</span>
                          <v-autocomplete
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_d_accommodation_location"
                            :items="formItems.itemsAccommodationLocation"
                            item-value="user_id"
                            item-title="name_estable"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.id"
                                      :model-value="isActive"
                                      v-model="form.field_d_accommodation_location"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsAccommodationLocation.length
                                "
                              ></v-divider>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Autoriza el tratamiento de los datos personales sensibles
                            que se recolectarán en el presente formulario de
                            registro?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_d_authorize_sensitive_data_processing"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.itemsAuthorizeSensitiveDataProcessing"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_d_authorize_sensitive_data_processing ==
                                  item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Indique el tipo de viaje de acuerdo con el grupo de personas
                            que lo acompañan?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_d_type_of_trip_group"
                            :items="formItems.itemsTypeOfTripGroup"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_d_type_of_trip_group.length - 2 }}
                                {{
                                  form.field_d_type_of_trip_group.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsTypeOfTripGroup.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep2">
                      <v-row>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="
                            form.field_d_type_of_trip_group.length > 0 &&
                            !form.field_d_type_of_trip_group.includes(427) &&
                            !form.field_d_type_of_trip_group.includes(428)
                          "
                        >
                          <span class="font-weight-bold"
                            >¿El grupo de viaje incluye niños?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_d_includes_children"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsIncludesChildren"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_d_includes_children == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Fecha de la llegada al alojamiento (check in)</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-menu
                            :close-on-content-click="false"
                            v-model="menuDateCheckin"
                          >
                            <template v-slot:activator="{ props }">
                              <v-text-field
                                :rules="[validations.required]"
                                rounded="lg"
                                v-model="form.field_d_check_in_date"
                                append-inner-icon="mdi-calendar-month"
                                readonly
                                v-bind="props"
                                variant="underlined"
                              ></v-text-field>
                            </template>
                            <VueDatePicker
                              class=""
                              v-model="date_checkin"
                              locale="es"
                              :dark="false"
                              :enable-time-picker="false"
                              inline
                              auto-apply
                            />
                          </v-menu>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Fecha de la llegada al alojamiento (check out)</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-menu
                            :close-on-content-click="false"
                            v-model="menuDateCheckout"
                          >
                            <template v-slot:activator="{ props }">
                              <v-text-field
                                :rules="[validations.required]"
                                rounded="lg"
                                v-model="form.field_d_check_out_date"
                                append-inner-icon="mdi-calendar-month"
                                readonly
                                v-bind="props"
                                variant="underlined"
                              ></v-text-field>
                            </template>
                            <VueDatePicker
                              class=""
                              v-model="date_checkout"
                              locale="es"
                              :dark="false"
                              :enable-time-picker="false"
                              inline
                              auto-apply
                            />
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep3">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál fue el motivo principal para viajar al departamento del
                            Guaviare?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_d_main_reason_for_travel"
                            :items="formItems.itemsMainReasonForTravel"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_d_main_reason_for_travel.length - 2 }}
                                {{
                                  form.field_d_main_reason_for_travel.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsMainReasonForTravel.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>

                          <!--  -->
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_d_main_reason_for_travel.includes(445)"
                        >
                          <v-text-field
                            color="primary"
                            variant="underlined"
                            v-model="form.field_d_reason_for_travel"
                            :rules="[validations.required]"
                            placeholder="¿Cuál es el motivo?"
                          ></v-text-field>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuáles de los municipios visitó y/o visitará en el
                            departamento del Guaviare?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_d_visited_municipalities"
                            :items="formItems.itemsVisitedMunicipalities"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_d_visited_municipalities.length - 2 }}
                                {{
                                  form.field_d_visited_municipalities.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsVisitedMunicipalities.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="4">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep4">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿De qué forma se enteró usted del departamento del Guaviare
                            como destino turístico?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_d_discovered_guaviare"
                            :items="formItems.itemsDiscoveredGuaviare"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_d_discovered_guaviare.length - 2 }}
                                {{
                                  form.field_d_discovered_guaviare.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsDiscoveredGuaviare.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_d_discovered_guaviare.includes(457)"
                        >
                          <v-text-field
                            color="primary"
                            variant="underlined"
                            placeholder="¿Cuál?"
                            v-model="form.field_d_discovery_method"
                            :rules="[validations.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Por qué medio de transporte llegó/llegaron al
                            departamento?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_d_transportation_method"
                            :items="formItems.itemsTransportationMethod"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_d_transportation_method.length - 2 }}
                                {{
                                  form.field_d_transportation_method.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsTransportationMethod.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_d_transportation_method.includes(465)"
                        >
                          <v-text-field
                            color="primary"
                            variant="underlined"
                            placeholder="¿Cuál?"
                            v-model="form.field_d_transport_method"
                            :rules="[validations.required]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="5">
                  <v-card-text class="text-body-2">
                    <v-form ref="formVisit">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1 mb-3">
                          <span class="font-weight-bold"
                            >Registre los datos de cada uno de los integrantes del grupo
                            de viaje</span
                          >
                          <span class="font-weight-bold">*</span>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-medium">Tipo Documento</span>
                          <v-select
                            v-model="formIntegrantes.tipo_doc"
                            :rules="[validations.required]"
                            color="primary"
                            variant="outlined"
                            :items="items_type_doc"
                            item-value="id"
                            item-title="text"
                            density="compact"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-medium">Número de identificación</span>
                          <v-text-field
                            v-model="formIntegrantes.numero_identificacion"
                            v-numbers-only-app
                            :rules="[
                              validations.required,
                              validations.maxLength(255),
                              validations.numeric,
                            ]"
                            color="primary"
                            variant="outlined"
                            density="compact"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-medium">Nombre completo</span>
                          <v-text-field
                            v-model="formIntegrantes.nombre_completo"
                            :rules="[validations.required, validations.maxLength(255)]"
                            color="primary"
                            variant="outlined"
                            density="compact"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-medium">Sexo</span>
                          <v-select
                            v-model="formIntegrantes.sexo"
                            :rules="[validations.required]"
                            color="primary"
                            variant="outlined"
                            :items="items_gender"
                            item-value="id"
                            item-title="text"
                            density="compact"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-medium">Edad</span>
                          <v-text-field
                            v-model="formIntegrantes.edad"
                            v-numbers-only-app
                            :rules="[
                              validations.required,
                              validations.maxLength(3),
                              validations.numeric,
                            ]"
                            color="primary"
                            variant="outlined"
                            density="compact"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-medium">Grupo Poblacional</span>
                          <v-select
                            v-model="formIntegrantes.grupo_poblacional"
                            color="primary"
                            variant="outlined"
                            :items="items_population_group"
                            item-value="id"
                            item-title="text"
                            density="compact"
                            return-object
                          ></v-select>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-medium">Procedencia / Residencia</span>
                        </v-col>

                        <v-col md="4" cols="12" class="py-1">
                          <span class="font-weight-medium">País</span>
                          <v-select
                            v-model="formIntegrantes.procedencia.pais"
                            :rules="[validations.required]"
                            color="primary"
                            variant="outlined"
                            :items="itemsCountries"
                            item-value="id"
                            item-title="name"
                            density="compact"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col md="4" cols="12" class="py-1">
                          <span class="font-weight-medium">Departamento/Estado</span>
                          <v-select
                            v-model="formIntegrantes.procedencia.departamento"
                            :rules="[validations.required]"
                            color="primary"
                            variant="outlined"
                            :items="itemsDepartments"
                            item-value="id"
                            item-title="name"
                            density="compact"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col md="4" cols="12" class="py-1">
                          <span class="font-weight-medium">Ciudad</span>
                          <v-select
                            v-model="formIntegrantes.procedencia.ciudad"
                            :rules="[validations.required]"
                            color="primary"
                            variant="outlined"
                            :items="itemsCities"
                            item-value="id"
                            item-title="name"
                            density="compact"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-medium">Nivel Educativo</span>
                          <v-select
                            v-model="formIntegrantes.nivel_educativo"
                            :items="items_educational_level"
                            item-value="id"
                            item-title="text"
                            color="primary"
                            variant="outlined"
                            density="compact"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-medium">Teléfono</span>
                          <v-text-field
                            v-model="formIntegrantes.telefono"
                            v-numbers-only-app
                            :rules="
                              formIntegrantes.telefono
                                ? [
                                    validations.required,
                                    validations.maxLength(255),
                                    validations.numeric,
                                  ]
                                : []
                            "
                            color="primary"
                            variant="outlined"
                            density="compact"
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-medium">Correo Electrónico</span>
                          <v-text-field
                            v-model="formIntegrantes.correo_electronico"
                            :rules="
                              formIntegrantes.correo_electronico
                                ? [validations.emailFormat]
                                : []
                            "
                            color="primary"
                            variant="outlined"
                            density="compact"
                          ></v-text-field>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1 text-center">
                          <v-btn
                            @click="addIntegrante"
                            variant="outlined"
                            class="text-capitalize"
                            color="secondary"
                            prepend-icon="mdi-plus-circle-outline"
                          >
                            Guardar Integrante
                          </v-btn>
                        </v-col>

                        <!-- {{ itemsIntegrantes }} -->

                        <v-col
                          v-if="itemsIntegrantes.length > 0"
                          sm="12"
                          md="12"
                          cols="12"
                        >
                          <v-table density="compact" class="integrantes-table-responsive">
                            <thead>
                              <tr>
                                <th colspan="7"></th>
                                <th colspan="3" class="text-center text-caption">
                                  Procedencia
                                </th>
                                <th colspan="4"></th>
                              </tr>
                              <tr>
                                <th class="text-center text-caption"></th>
                                <th class="text-center text-caption">Tipo Documento</th>
                                <th class="text-center text-caption">
                                  Número de identificación
                                </th>
                                <th class="text-center text-caption">Nombre completo</th>
                                <th class="text-center text-caption">Sexo</th>
                                <th class="text-center text-caption">Edad</th>
                                <th class="text-center text-caption">
                                  Grupo Poblacional
                                </th>
                                <th class="text-center text-caption">País</th>
                                <th class="text-center text-caption">
                                  Departamento/Estado
                                </th>
                                <th class="text-center text-caption">Ciudad</th>
                                <th class="text-center text-caption">Nivel Educativo</th>
                                <th class="text-center text-caption">Teléfono</th>
                                <th class="text-center text-caption">
                                  Correo Electrónico
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody class="" style="display: contents">
                              <tr v-for="(item, index) in itemsIntegrantes" :key="index">
                                <td data-label="" class="text-center">
                                  <div class="d-flex">
                                    <v-btn
                                      @click="editFormIntegrantes(item)"
                                      rounded="lg"
                                      style="border: 2px solid"
                                      class="my-2 mx-1 font-weight-bold"
                                      height="35"
                                      max-width="25"
                                      size="x-small"
                                      variant="outlined"
                                      color="primary"
                                    >
                                      <v-icon size="20">mdi-file-edit-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                      @click="itemsIntegrantes.splice(index, 1)"
                                      rounded="lg"
                                      style="border: 2px solid"
                                      class="my-2 mx-1 font-weight-bold"
                                      height="35"
                                      max-width="25"
                                      size="x-small"
                                      variant="outlined"
                                      color="red-app"
                                    >
                                      <v-icon size="20">mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                  </div>
                                </td>
                                <td data-label="Tipo Documento" clas="text-caption">
                                  {{ item.tipo_doc ? item.tipo_doc.text : null }}
                                </td>
                                <td
                                  data-label="Número de identificación"
                                  clas="text-caption"
                                >
                                  {{ item.numero_identificacion }}
                                </td>
                                <td data-label="Nombre completo" clas="text-caption">
                                  {{ item.nombre_completo }}
                                </td>
                                <td data-label="Sexo" clas="text-caption">
                                  {{ item.sexo ? item.sexo.text : null }}
                                </td>
                                <td data-label="Edad" clas="text-caption">
                                  {{ item.edad }}
                                </td>
                                <td data-label="Grupo Poblacional" clas="text-caption">
                                  {{
                                    item.grupo_poblacional
                                      ? item.grupo_poblacional.text
                                      : null
                                  }}
                                </td>
                                <td data-label="País" clas="text-caption">
                                  {{
                                    item.procedencia && item.procedencia.pais
                                      ? item.procedencia.pais.name
                                      : null
                                  }}
                                </td>
                                <td data-label="Departamento/Estado" clas="text-caption">
                                  {{
                                    item.procedencia && item.procedencia.departamento
                                      ? item.procedencia.departamento.name
                                      : null
                                  }}
                                </td>
                                <td data-label="Ciudad" clas="text-caption">
                                  {{
                                    item.procedencia && item.procedencia.ciudad
                                      ? item.procedencia.ciudad.name
                                      : null
                                  }}
                                </td>
                                <td data-label="Nivel Educativo" clas="text-caption">
                                  {{
                                    item.nivel_educativo
                                      ? item.nivel_educativo.text
                                      : null
                                  }}
                                </td>
                                <td data-label="Teléfono" clas="text-caption">
                                  {{ item.telefono }}
                                </td>
                                <td data-label="Correo Electrónico" clas="text-caption">
                                  {{ item.correo_electronico }}
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </v-table>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <v-card-actions>
                <br />
              </v-card-actions>
              <v-card-actions class="mb-9">
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  :disabled="stepFormServiceOrder > 1 ? false : true"
                  variant="text"
                  @click="goToPreviousStep"
                >
                  Anterior
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  v-if="
                    stepFormServiceOrder <
                    stepTitles.filter((step) => step.show)[
                      stepTitles.filter((step) => step.show).length - 1
                    ].id
                  "
                  color="primary"
                  variant="text"
                  @click="goToNextStep"
                >
                  Siguiente
                </v-btn>
                <v-btn
                  @click="saveForm"
                  class="text-capitalize"
                  v-else
                  color="primary"
                  variant="flat"
                >
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-overlay
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center position-fixed"
      scrim="#FFF"
      :opacity="1"
      v-model="loadingPageOverlay"
    >
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h4 class="text-h4 mt-4 font-weight-medium text-primary">Enviando formulario</h4>
        <p class="text-h6 font-weight-medium mt-2">
          Por favor, espera mientras procesamos tu solicitud.
        </p>
      </div>
    </v-overlay>
    <UserExperience></UserExperience>
    <DialogResponse
      @cancel="cancelDialogResponse"
      v-if="dialogResponse"
      :dialog="dialogResponse"
    ></DialogResponse>
  </v-container>
  <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->
</template>

<script>
import { useDisplay } from "vuetify";
import validationFields from "../functions/validationFields";
import LeftPanelAnimation2 from "./includes/LeftPanelAnimation2.vue";
import NavBarAnimation2 from "./includes/NavBarAnimation2.vue";
import UserExperience from "./includes/UserExperience.vue";
import moment from "moment";
import axios from "axios";
import { useToast } from "vue-toastification";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import DialogResponse from "@/components/dialog/DialogResponse.vue";

export default {
  name: "FormTouristRegistration",
  mixins: [validationFields],
  components: {
    LeftPanelAnimation2,
    NavBarAnimation2,
    UserExperience,
    DialogResponse,
  },

  setup() {
    const { mdAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
    };
  },

  data() {
    return {
      showTopShadow: false,
      showBottomShadow: true,
      formConfig: null,
      items_type_doc: [],
      items_gender: [],
      items_educational_level: [],
      items_population_group: [],
      form_relations_question: null,
      toast: useToast(),
      loadingPageOverlay: false,
      dialogResponse: null,

      menuDateCheckin: false,
      menuDateCheckout: false,
      itemsCountries: [],
      itemsDepartments: [],
      itemsCities: [],
      formItems: {
        itemsAccommodationLocation: [],
        itemsAuthorizeSensitiveDataProcessing: [],
        itemsTypeOfTripGroup: [],
        itemsIncludesChildren: [],
        itemsMainReasonForTravel: [],
        itemsVisitedMunicipalities: [],
        itemsDiscoveredGuaviare: [],
        itemsTransportationMethod: [],
      },

      form: {
        field_d_accommodation_location: null,
        field_d_authorize_sensitive_data_processing: null,
        field_d_type_of_trip_group: [],
        field_d_includes_children: null,
        field_d_check_in_date: null,
        field_d_check_out_date: null,
        field_d_main_reason_for_travel: [],
        field_d_reason_for_travel: null,
        field_d_visited_municipalities: [],
        field_d_discovered_guaviare: [],
        field_d_discovery_method: null,
        field_d_transportation_method: [],
        field_d_transport_method: null,
        field_d_group_member_details: null,
      },
      date_checkin: null,
      date_checkout: null,

      formIntegrantes: {
        tipo_doc: null,
        numero_identificacion: null,
        nombre_completo: null,
        sexo: null,
        edad: null,
        grupo_poblacional: null,
        procedencia: {
          pais: null,
          departamento: null,
          ciudad: null,
        },
        nivel_educativo: null,
        telefono: null,
        correo_electronico: null,
      },

      itemsIntegrantes: [
        // { "tipo_doc": "Cédula de ciudadania", "numero_identificacion": "1030649098", "nombre_completo": "Yecid Alberto Tovar", "sexo": 'Hombre', "edad": "29", "grupo_poblacional": "Ninguno", "procedencia": { "pais": "Ejempo 1", "departamento": "Ejemplo 2", "ciudad": "Ejemplo 3" }, "nivel_educativo": "Técnico/Tecnológo", "telefono": "3214140263", "correo_electronico": "tyesid@hotmail.com" },
        // { "tipo_doc": "Cédula de ciudadania", "numero_identificacion": "1030649098", "nombre_completo": "Yecid Alberto Tovar", "sexo": 'Hombre', "edad": "29", "grupo_poblacional": "Ninguno", "procedencia": { "pais": "Ejempo 1", "departamento": "Ejemplo 2", "ciudad": "Ejemplo 3" }, "nivel_educativo": "Técnico/Tecnológo", "telefono": "3214140263", "correo_electronico": "tyesid@hotmail.com" },
      ],

   

      isMobile: false,

      num: 120,
      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

      stepFormServiceOrder: 1,
      stepTitles: [
        {
          group: 1,
          label: "Información del Viaje",
          id: 1,
          title: "Lugar y Autorización de Datos",
          show: true,
        },
        {
          group: 1,
          label: "Información del Viaje",
          id: 2,
          title: "Grupo de Viaje y Fechas de Alojamiento",
          show: true,
        },
        {
          group: 1,
          label: "Información del Viaje",
          id: 3,
          title: "Motivo del Viaje y Municipios Visitados",
          show: true,
        },
        {
          group: 1,
          label: "Información del Viaje",
          id: 4,
          title: "Descubrimiento y acceso al destino",
          show: true,
        },
        {
          group: 2,
          id: 5,
          label: "Información Visitantes",
          title: "Registro",
          show: true,
        },
      ],

      showIndicator: true, // Initially show the indicator
      activeWatchVisit: true,
    };
  },
  watch: {
    stepFormServiceOrder(newVal) {
      if (newVal < this.stepTitles.length) {
        this.scrollToItem(newVal);
      }
    },

    "form.field_d_type_of_trip_group"() {
      this.form.field_d_includes_children = null;
    },

    "form.field_d_main_reason_for_travel"() {
      this.form.field_d_reason_for_travel = null;
    },

    "form.field_d_discovered_guaviare"() {
      this.form.field_d_discovery_method = null;
    },

    "form.field_d_transportation_method"() {
      this.form.field_d_transport_method = null;
    },

    date_checkin() {
      if (this.date_checkin) {
        const fecha = moment(this.date_checkin);
        this.form.field_d_check_in_date = fecha.format("YYYY-MM-DD");
      } else {
        this.form.field_d_check_in_date = null;
      }
      this.menuDateCheckin = false;
    },
    date_checkout() {
      if (this.date_checkout) {
        const fecha = moment(this.date_checkout);
        this.form.field_d_check_out_date = fecha.format("YYYY-MM-DD");
      } else {
        this.form.field_d_check_out_date = null;
      }
      this.menuDateCheckout = false;
    },
    "formIntegrantes.procedencia.pais"() {
      if (this.activeWatchVisit) {
        this.itemsDepartments = [];
        this.itemsCities = [];
        this.formIntegrantes.procedencia.departamento = null;
        this.formIntegrantes.procedencia.ciudad = null;
      }

      if (this.formIntegrantes.procedencia.pais) {
        this.getDepartments(this.formIntegrantes.procedencia.pais.id);
      }
    },
    "formIntegrantes.procedencia.departamento"() {
      if (this.activeWatchVisit) {
        this.itemsCities = [];
        this.formIntegrantes.procedencia.ciudad = null;
      }
      if (this.formIntegrantes.procedencia.departamento) {
        this.getCities(this.formIntegrantes.procedencia.departamento.id);
      }
    },
  },
  methods: {
    async cancelDialogResponse() {
      this.dialogResponse = null;
    },
    editFormIntegrantes(item) {
      this.activeWatchVisit = false;
      this.formIntegrantes = item;
      this.itemsIntegrantes = this.itemsIntegrantes.filter(
        (integrante) => integrante !== item
      );

      setTimeout(() => {
        this.activeWatchVisit = true;
      }, 500);
    },

    async addIntegrante() {
      const { valid } = await this.$refs.formVisit.validate();

      if (valid) {
        this.itemsIntegrantes.push(this.formIntegrantes);
        this.form.field_d_group_member_details = this.itemsIntegrantes;

        this.formIntegrantes = {
          tipo_doc: null,
          numero_identificacion: null,
          nombre_completo: null,
          sexo: null,
          edad: null,
          grupo_poblacional: null,
          procedencia: {
            pais: null,
            departamento: null,
            ciudad: null,
          },
          nivel_educativo: null,
          telefono: null,
          correo_electronico: null,
        };
      }
    },

    async saveForm() {
      this.loadingPageOverlay = true;
      let otherThis = this;
      await setTimeout(async function () {
        const allValid = await otherThis.validateAllSteps();

        if (allValid) {
          otherThis.formResponseStore();
        } else {
          otherThis.loadingPageOverlay = false;
          console.log("Hay errores en algunos de los formularios.");
        }
      }, 1000);
    },
    async validateAllSteps() {
      let allValid = true;

      // Filtramos los pasos visibles y recorremos su longitud
      const visibleSteps = this.stepTitles.filter((step) => step.show);

      for (let i = 0; i < visibleSteps.length; i++) {
        this.stepFormServiceOrder = visibleSteps[i].id; // Navegar al paso correspondiente
        await this.$nextTick(); // Esperar a que el DOM se actualice y el formulario se monte

        const formRef = this.$refs[`formStep${visibleSteps[i].id}`];

        if (formRef && formRef.validate) {
          const { valid } = await formRef.validate();

          if (!valid) {
            allValid = false;

            // Resaltar los campos con errores
            this.$nextTick(() => {
              const firstErrorField = formRef.$el.querySelector(".v-input--error");
              if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: "smooth" });
              }
            });

            break; // Detener la validación en el primer error encontrado
          }
        }
      }

      //validar si hay visitantes agregados de lo contrario no permitir enviar el formulario
      if (allValid && this.itemsIntegrantes.length == 0) {
        //pasar al ultimo paso
        this.stepFormServiceOrder = 5;


        this.toast.error({
          component: ErrorLayoutComponent,
          props: {
            errorMessage: {"error":[["Debes agregar al menos un visitante."]]},
          },
          timeout: 8000,
        });
        allValid = false;
      }



      return allValid;
    },
    formResponseStore() {
      let url = process.env.VUE_APP_API_URL;

      axios
        .post(url + "/form-responses/store", {
          form: this.form,
          form_relations_question: this.form_relations_question,
          form_id: 5,
          user_id: this.$store.state.user.id,
          season_id: this.season_id,
        })
        .then((response) => {
          this.loadingPageOverlay = false;

          this.dialogResponse = {
            title: "¡Formulario enviado con éxito!",
            message:
              "Tus respuestas han sido registradas correctamente. Gracias por tomarte el tiempo de completar el formulario. Puedes estar seguro de que tus datos han sido guardados de manera segura.",
            type: "success",
            color: "secondary",
            icon: "mdi-check",
            show: true,
            redirect: false,
          };
          console.log(response);
          //resetear todo el componente
          this.resetFormFields();
          this.stepFormServiceOrder = 1;
        })
        .catch((error) => {
          this.loadingPageOverlay = false;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status == 422
          ) {
            //ERROR RETORNADO POR LAS VALIDACIONES
            this.toast.error({
              component: ErrorLayoutComponent,
              props: {
                errorMessage: error.response.data,
              },
              timeout: 8000,
            });
          } else {
            this.dialogResponse = {
              title: "Error al enviar el formulario",
              message:
                "Ocurrió un problema al intentar guardar tus respuestas. Por favor, revisa los campos y vuelve a intentarlo. Si el problema persiste, contacta al soporte técnico.",
              type: "success",
              color: "red-app",
              icon: "mdi-cloud-alert",
              show: true,
              redirect: false,
            };
          }

          console.log(error);
        });
    },
    resetFormFields() {
      //restablecer valores por defecto
      this.form = {
        field_d_accommodation_location: null,
        field_d_authorize_sensitive_data_processing: null,
        field_d_type_of_trip_group: [],
        field_d_includes_children: null,
        field_d_check_in_date: null,
        field_d_check_out_date: null,
        field_d_main_reason_for_travel: [],
        field_d_reason_for_travel: null,
        field_d_visited_municipalities: [],
        field_d_discovered_guaviare: [],
        field_d_discovery_method: null,
        field_d_transportation_method: [],
        field_d_transport_method: null,
        field_d_group_member_details: null,
      };

      this.stepFormServiceOrder = 1;
    },

    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

        // Control de las sombras
        this.showTopShadow = container.scrollTop > 0;
        this.showBottomShadow =
          container.scrollTop < container.scrollHeight - container.clientHeight;
      }
    },
    scrollToItem(index) {
      const item = this.$refs["timelineItem" + index][0]; // Acceso directo al primer elemento
      if (item) {
        const container = this.$refs.scrollContainer;
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Ajuste adicional para el margen superior
        const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

        container.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
        console.log(
          "Item Rect:",
          itemRect,
          "Container Rect:",
          containerRect,
          "Offset Top:",
          offsetTop
        );
      }
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    },

    showGroupLabel(item, index) {
      // Verifica si es el primer paso del grupo visible
      return index === 0 || item.group !== this.visibleSteps[index - 1].group;
    },

    goToPreviousStep() {
      if (this.previousStep) {
        this.stepFormServiceOrder = this.previousStep.id;
      }
    },
    async goToNextStep() {
      if (this.nextStep) {
        //VALIDAR FORMULARIO ACTUAL EN REF formStep1,2,3... etc
        const { valid } = await this.$refs[
          "formStep" + this.stepFormServiceOrder
        ].validate();
        if (valid) {
          this.stepFormServiceOrder = this.nextStep.id;
        }
      }
    },

    async getInfoForm() {
      try {
        let url = process.env.VUE_APP_API_URL;
        const token = this.$store.state.token;

        axios
          .get(url + `/forms/show/5`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.formConfig = response.data.form_config;
            this.form_relations_question = response.data.form_relations_question;

            if (response.data.items_select_fields) {
              console.log(response.data.items_select_fields);

              if (response.data.items_select_fields.items_type_doc) {
                this.items_type_doc = response.data.items_select_fields.items_type_doc;
              }
              if (response.data.items_select_fields.items_gender) {
                this.items_gender = response.data.items_select_fields.items_gender;
              }
              if (response.data.items_select_fields.items_educational_level) {
                this.items_educational_level =
                  response.data.items_select_fields.items_educational_level;
              }
              if (response.data.items_select_fields.items_population_group) {
                this.items_population_group =
                  response.data.items_select_fields.items_population_group;
              }
            }

            if (this.formConfig && this.formConfig.questions) {
              const field_d_accommodation_location = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_accommodation_location"
              );
              if (field_d_accommodation_location) {
                this.getAccommodationPlace();
              }

              const field_d_authorize_sensitive_data_processing = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_authorize_sensitive_data_processing"
              );
              if (field_d_authorize_sensitive_data_processing) {
                this.formItems.itemsAuthorizeSensitiveDataProcessing =
                  field_d_authorize_sensitive_data_processing.options;
              }

              const field_d_type_of_trip_group = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_type_of_trip_group"
              );
              if (field_d_type_of_trip_group) {
                this.formItems.itemsTypeOfTripGroup = field_d_type_of_trip_group.options;
              }

              const field_d_includes_children = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_includes_children"
              );
              if (field_d_includes_children) {
                this.formItems.itemsIncludesChildren = field_d_includes_children.options;
              }

              const field_d_main_reason_for_travel = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_main_reason_for_travel"
              );
              if (field_d_main_reason_for_travel) {
                this.formItems.itemsMainReasonForTravel =
                  field_d_main_reason_for_travel.options;
              }

              const field_d_visited_municipalities = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_visited_municipalities"
              );
              if (field_d_visited_municipalities) {
                this.formItems.itemsVisitedMunicipalities =
                  field_d_visited_municipalities.options;
              }

              const field_d_discovered_guaviare = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_discovered_guaviare"
              );
              if (field_d_discovered_guaviare) {
                this.formItems.itemsDiscoveredGuaviare =
                  field_d_discovered_guaviare.options;
              }

              const field_d_transportation_method = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_transportation_method"
              );
              if (field_d_transportation_method) {
                this.formItems.itemsTransportationMethod =
                  field_d_transportation_method.options;
              }
            }

            //   setTimeout(() => {
            //     this.activeWatch = true;
            //   }, 1000);

            // }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.showDeleteConfirmation = false;
      }
    },
    getAccommodationPlace() {
      /* this.formItems.itemsAccommodationPlace; */

      let url = process.env.VUE_APP_API_URL;
      axios
        .get(url + "/select-option/get-accommodations")
        .then((response) => {
          this.formItems.itemsAccommodationLocation = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCountries() {
      let url = process.env.VUE_APP_API_URL;
      const token = this.$store.state.token;

      axios
        .get(url + `/select-option/get-countries`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.itemsCountries = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDepartments(country_id) {
      let url = process.env.VUE_APP_API_URL;
      const token = this.$store.state.token;

      axios
        .get(url + `/select-option/get-states?country_id=${country_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.itemsDepartments = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCities(department_id) {
      let url = process.env.VUE_APP_API_URL;
      const token = this.$store.state.token;

      axios
        .get(url + `/select-option/get-cities?state_id=${department_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.itemsCities = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);

    this.getInfoForm();
    this.getCountries();

  
    

  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  computed: {
    visibleSteps() {
      // Filtrar los pasos visibles
      return this.stepTitles.filter((item) => item.show);
    },
    currentTitle() {
      const step = this.stepTitles[this.stepFormServiceOrder - 1];
      // Busca el label del grupo al que pertenece el step actual
      const groupLabel = this.stepTitles.find(
        (item) => item.group === step.group && item.label
      )?.label;

      return {
        title: step.title,
        label: groupLabel || "", // Si no se encuentra el label, se asigna una cadena vacía
      };
    },

    nextStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return this.stepTitles.slice(currentIndex + 1).find((step) => step.show) || null;
    },

    previousStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return (
        this.stepTitles
          .slice(0, currentIndex)
          .reverse()
          .find((step) => step.show) || null
      );
    },
  },
};
</script>
<style>
.v-field__input {
  padding-top: 8px;
}

.v-row {
  margin: 0;
}

.integrantes-table-responsive {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 800px) {
  .integrantes-table-responsive .v-table__wrapper {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .integrantes-table-responsive table {
    border-collapse: collapse;
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .integrantes-table-responsive thead {
    display: none;
  }

  .integrantes-table-responsive tbody tr {
    display: block;
  }

  .integrantes-table-responsive tbody td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
  }

  .integrantes-table-responsive tbody td::before {
    content: attr(data-label);
    flex-basis: 50%;
    text-align: left;
    font-weight: bold;
    padding-right: 10px;
  }

  .integrantes-table-responsive td {
    border-bottom: 1px solid #e0e0e0;
    height: 40px !important;
  }
}
</style>
