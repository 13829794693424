<template>
    <v-main>
        <router-view />
    </v-main>
</template>

<script>

export default {
    name: 'FormsLayout',

    data: () => ({

    }),
}
</script>

<style>
body {
    font-family: 'Be Vietnam Pro', sans-serif !important;
}

* {
    font-family: 'Be Vietnam Pro', sans-serif !important;
}

.text-wrap .v-toolbar-title__placeholder {
    padding-top: 15px;
    padding-bottom: 15px;
    white-space: normal;
}

.bg {
    animation: slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #00A650 50%, #00679F 50%);
    bottom: 0;
    left: -50%;
    opacity: .5;
    position: absolute;
    right: -50%;
    top: 0;
    z-index: -1;
}

.bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 4s;
}

.bg3 {
    animation-duration: 5s;
}

.animation-option-1 {
    background-image: linear-gradient(60deg, #009688 50%, #CDDC39 50%) !important;
    opacity: .7 !important;
}

.content-animation {
    background-color: rgb(64 64 64 / 33%);
    border-radius: 1em;
    box-sizing: border-box;
    padding: 5vmin;
    margin: 5vmin;
    text-align: center;
}

h1 {
    font-family: monospace;
}

@keyframes slide {
    0% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(25%);
    }
}


.bg-dot-customer {
    border: 1px solid;
    background: #f1f1f1 !important;
    color: #005EAA;
}


.success-step .v-timeline-divider__after {
    background: #00679F;
}


.success-step+div .v-timeline-divider__before {
    background-color: #00679F
        /* Tu color de fondo aquí */
    ;
}

.scroll-container {
    max-width: 344px;
    max-height: 500px;
    /* Ajusta según tus necesidades */
    overflow-y: scroll;
    /* Permite el scroll vertical */
}

/* Estilos para ocultar la barra de desplazamiento en WebKit (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

/* Estilos para ocultar la barra de desplazamiento en Firefox */
.scroll-container {
    scrollbar-width: none;
}


.mouse {
    width: 28px;
    height: 55px;
    border-radius: 20px;
    border: 2px solid #666;
    position: absolute;
    bottom: 0px;
    right: 0px;

}

.mouse:before,
.mouse:after {
    content: "";
    display: block;
    position: absolute;
}


.m-2:before {
    width: 2px;
    height: 34px;
    border-radius: 0px;
    top: 10px;
    left: calc(50% - 1px);
    background: #999;
    animation: m-2-ball 1.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

@-webkit-keyframes m-2-ball {

    0%,
    50%,
    60% {
        transform: scaleY(0.118);
    }

    30% {
        transform: scaleY(1);
    }

    0%,
    29.99% {
        transform-origin: top;
    }

    30%,
    100% {
        transform-origin: bottom;
    }

    90%,
    100% {
        transform: scaleY(0.118) translateY(-255px);
    }
}

@keyframes m-2-ball {

    0%,
    50%,
    60% {
        transform: scaleY(0.118);
    }

    30% {
        transform: scaleY(1);
    }

    0%,
    29.99% {
        transform-origin: top;
    }

    30%,
    100% {
        transform-origin: bottom;
    }

    90%,
    100% {
        transform: scaleY(0.118) translateY(-255px);
    }
}

.scroll-container-wrapper {
    position: relative;
    max-width: 344px;
}


/* Sombra superior */
.top-shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 30px;
    background: linear-gradient(to bottom, rgb(255, 255, 255), transparent);
    z-index: 5;
}

/* Sombra inferior */
.bottom-shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background: linear-gradient(to top, rgb(255, 255, 255), transparent);
    z-index: 5;
}



.list-container-documents, .two-columns .v-selection-control-group  {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
    /* Ajusta el espacio entre columnas */
}

.two-columns .v-selection-control-group .v-selection-control{
    grid-area: auto !important;
}

@media (max-width: 600px) {
    .list-container-documents, .two-columns .v-selection-control-group{
        grid-template-columns: 1fr;
        /* Una columna para pantallas pequeñas */
    }
}

.v-timeline--vertical.v-timeline--align-start .v-timeline-divider {
    justify-content: center !important;
}

.v-timeline--vertical.v-timeline--align-start {
    align-items: center !important;
}
</style>
