<template>
  <v-row>
    <v-col v-for="(group, index) in groupedResponses" :key="index" md="4" cols="12">
      <v-card outlined class="pa-4 mb-4" elevation="0">
        <v-card-title>{{ group.name_group }}</v-card-title>

        <v-list v-for="question in group.questions" :key="question.question_id">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-wrap mb-2">{{
                question.question_text
              }}</v-list-item-title>

              <v-list-item-subtitle class="text-wrap d-block" v-if="question.question_type === 'text'">
                <b>Respuesta:</b> {{ question.text_response }}
              </v-list-item-subtitle>

              <v-list-item-subtitle class="text-wrap d-block"
                v-else-if="question.question_type === 'single_choice'"
              >
                <b>Opción seleccionada:</b> {{ question.selected_options.text }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else-if="question.question_type === 'multiple_choice'"
              >
                <b>Opciones seleccionadas:</b>
                <v-chip-group column>
                  <v-chip class="text-wrap d-block py-2 h-auto" v-for="option in question.selected_options" :key="option.id">
                    {{ option.text }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    responses: {
      type: Array,
      required: true,
    },
  },
  name: "FormResponses3",
  data() {
    return {
      groupConfig: [
        { name_group: "Identificación del Representante", question_ids: [2, 28, 3] },
        { name_group: "Datos Básicos", question_ids: [1, 4] },
        {
          name_group: "Información Personal y Grupos Poblacionales",
          question_ids: [5, 6, 7, 8],
        },
        { name_group: "Registro y Tarjeta Profesional", question_ids: [62, 63, 64, 65] },
        {
          name_group: "Actividad de Guianza y Profesión",
          question_ids: [66, 67, 68, 69, 70, 70],
        },
        { name_group: "Experiencia", question_ids: [71, 72] },
        { name_group: "Especialidad en Guianza Turística", question_ids: [73] },
        {
          name_group: "Idiomas en Guianza Turística",
          question_ids: [74, 74, 75, 75, 77, 77, 78, 78, 79, 79, 80, 80, 81],
        },
        { name_group: "Tarifas de Guianza", question_ids: [82, 83] },
        { name_group: "Contratación y Disponibilidad", question_ids: [84, 85] },
      ],
    };
  },
  computed: {
    groupedResponses() {
      return this.groupConfig
        .map((group) => {
          // Filtra las preguntas que pertenecen al grupo actual
          const questions = this.responses.filter((response) =>
            group.question_ids.includes(response.question_id)
          );

          // Retorna el grupo solo si contiene preguntas
          return questions.length > 0
            ? { name_group: group.name_group, questions }
            : null;
        })
        .filter((group) => group !== null); // Filtra los grupos que son null
    },
  },
};
</script>
