export default [
    {
        path: '/:role/configuracion/informacion-personal',
        name: 'ProfileEntrepreneur',
        component: () => import('@/views/user/InfoPersonal.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [2, 3], requiresAuth: false, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/:role/configuracion/foto-perfil',
        name: 'PhotoProfileEntrepreneur',
        component: () => import('@/views/user/PhotoProfile.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [2, 3], requiresAuth: false, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/:role/configuracion/informacion-contacto',
        name: 'ContactInfoEntrepreneur',
        component: () => import('@/views/user/ContactInfo.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [2, 3], requiresAuth: false, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/:role/configuracion/cambiar-contrasena',
        name: 'ChangePasswordEntrepreneur',
        component: () => import('@/views/user/ChangePassword.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [2, 3], requiresAuth: false, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },

];