<template>


    <div class="content-animation">
        <div class="font-weight-bold text-center text-h3">
            {{ title }}
            <span v-for="(word, wordIndex) in words" :key="wordIndex" class="word"
                :style="{ opacity: currentWord === wordIndex ? 1 : 0 }">
                <span v-for="(letter, letterIndex) in wordArray[wordIndex]" :key="letterIndex"
                    :class="letter.className">
                    {{ letter.character === ' ' ? '&nbsp;' : letter.character }}
                </span>
            </span>
        </div>
        <p class="font-weight-regular text-body-1 text-justify pt-4">
            {{ subTitle }}
        </p>

    </div>

<div class="hero"> 
  <div class="cube"></div>
  <div class="cube"></div>
  <div class="cube"></div>
  <div class="cube"></div>
  <div class="cube"></div>
  <div class="cube"></div>
</div>

    

</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    name: 'LeftPanelAnimation3',
    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: true
        },
        wordsAnimate: {
            type: Array,
            required: true
        },
      
      
    },
    setup(props) {
        const words = ref(props.wordsAnimate);
        const wordArray = ref([]);
        const currentWord = ref(0);

        onMounted(() => {
            words.value.forEach((word, index) => {
                splitLetters(word, index);
            });

            changeWord();
        });

        function changeWord() {
            const cw = wordArray.value[currentWord.value];
            const nw = currentWord.value === words.value.length - 1 ? wordArray.value[0] : wordArray.value[currentWord.value + 1];

            cw.forEach((letter, index) => {
                animateLetterOut(cw, index);
            });

            nw.forEach((letter, index) => {
                letter.className = 'letter behind';
                animateLetterIn(nw, index);
            });

            currentWord.value = (currentWord.value === wordArray.value.length - 1) ? 0 : currentWord.value + 1;
        }

        function animateLetterOut(cw, i) {
            setTimeout(() => {
                cw[i].className = 'letter out';
            }, i * 80);
        }

        function animateLetterIn(nw, i) {
            setTimeout(() => {
                nw[i].className = 'letter in';
            }, 340 + (i * 80));
        }

        function splitLetters(word) {
            const letters = [];
            for (let i = 0; i < word.length; i++) {
                letters.push({ character: word[i], className: 'letter' });
            }
            wordArray.value.push(letters);
            
        }

        return {
            words,
            currentWord,
            wordArray,
        }
    },
}
</script>

<style>

.word {
    position: relative;
    white-space: nowrap;
    transition: opacity 0.5s ease-in-out;
}

.letter {
    display: inline-block;
    transition: all 0.3s ease;
}

.letter.out {
    transform: translateY(-100%);
    opacity: 0;
}

.letter.behind {
    transform: translateY(100%);
    opacity: 0;
}

.letter.in {
    transform: translateY(0);
    opacity: 1;
}


.letter2 {
    font-family: 'Be Vietnam Pro', sans-serif;
    display: inline-block;
    position: relative;
    color: #00679F;
    transform-style: preserve-3d;
    perspective: 400;
    z-index: 1;
}

.letter2:before,
.letter2:after {
    position: absolute;
    content: attr(data-letter);
    transform-origin: top left;
    top: 0;
    left: 0;
}

.letter2,
.letter2:before,
.letter2:after {
    transition: all 0.3s ease-in-out;
}

.letter2:before {
    color: #212121;

    z-index: 3;
    transform:
        rotateX(0deg) rotateY(-15deg) rotateZ(0deg);
}

.letter2:after {
    color: rgba(0, 0, 0, .11);
    z-index: 2;
    transform:
        scale(1.08, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 1deg);
}

.letter2:hover:before {
    color: #212121;
    transform:
        rotateX(0deg) rotateY(-40deg) rotateZ(0deg);
}

.letter2:hover:after {
    transform:
        scale(1.08, 1) rotateX(0deg) rotateY(40deg) rotateZ(0deg) skew(0deg, 22deg);
}



.hero{
  /* background: linear-gradient(132deg,  #72BF44, #00679F, #00A650); */
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding:0;
  margin:0px;
}
.cube {
  position: absolute;
  top: 98vh;
  left: 33vw;
  width: 10px;
  height: 10px;
  border: solid 1px #FFF;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 12s ease-in forwards infinite;
  animation-delay: 0s;
}
.cube:nth-child(2n) {
  border-color: #FFF ;
}
.cube:nth-child(2) {
  animation-delay: 2s;
  left: 25vw;
  top: 40vh;
}
.cube:nth-child(3) {
    animation-delay: 5s;
    left: 9vw;
    top: 12vh;
}
.cube:nth-child(4) {
  animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}
.cube:nth-child(5) {
  animation-delay: 8s;
  left: 10vw;
  top: 85vh;
}
.cube:nth-child(6) {
  animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}


@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}


</style>
