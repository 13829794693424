<template>
    <v-container fluid class="px-0" style="height: 100%;">
        <v-card-text class="px-0">
            <v-row style="height: 100%;">
                <v-col cols="12" md="12" class="">
                    <div class="d-flex">





                        <v-spacer></v-spacer>





                        <!-- <v-btn @click="drawerRol = true" color="primary" rounded="lg" class="text-capitalize">
                            <v-icon start>mdi-plus-circle-outline</v-icon>
                            Agregar Rol </v-btn> -->

                    </div>
                </v-col>
                <v-col cols="12" md="12" class="">
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th class="border-0 text-center bg-primary py-4 text-left">
                                    ID
                                </th>
                                <th class="border-0 text-center bg-primary py-4 text-left">
                                    NOMBRE
                                </th>

                                <th class="border-0 text-center bg-primary py-4 text-left">

                                </th>
                            </tr>
                            <tr>
                                <th colspan="8" style="background: #FCFCFC; height: 15px; border: 0 ;"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="item in desserts" :key="item.name">
                                <td>{{ item.calories }}</td>
                                <td>{{ item.name }}</td>
                                <td class="text-center">
                                    <!-- <div class="d-flex justify-end">
                                        <v-btn rounded="lg" style="border: 2px solid" class="my-2 mx-1 text-capitalize"
                                            height="35" size="x-small" variant="flat" color="secondary">
                                            <v-icon start size="20">mdi-account-key</v-icon> Permisos
                                        </v-btn>
                                        <v-btn rounded="lg" style="border: 2px solid" class="my-2 mx-1 font-weight-bold"
                                            height="35" max-width="25" size="x-small" variant="outlined"
                                            color="primary">
                                            <v-icon size="20">mdi-file-edit-outline</v-icon>
                                        </v-btn>
                                    </div> -->
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-col>
                <!-- <v-col cols="8" offset="4" class="d-flex">
                    <v-spacer></v-spacer>
                    <v-pagination :total-visible="4" v-model="page" variant="flat" border="1" active-color="secondary"
                        density="compact" :length="totalPage"></v-pagination>
                </v-col> -->
            </v-row>
        </v-card-text>

    </v-container>

    <v-navigation-drawer class="drawer-rol" temporary touchless v-model="drawerRol" width="400" location="right"
        style="height: 100%; top: 0; z-index: 1006; position: fixed;">
        <template v-slot:prepend>
            <v-list-item>
                <v-list-item-action>
                    <v-card-title class="font-weight-bold text-h5">Agregar Nuevo Rol</v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="drawerRol = false" icon size="x-small" variant="outlined"
                        style="border: 2px solid;"><v-icon size="20">mdi-close</v-icon></v-btn>
                </v-list-item-action>
            </v-list-item>
        </template>


        <v-card elevation="0" color="transparent">
            
            <v-card-text class="pt-0">
                <p class="text-subtitle px-3">
                    Ingresa el nombre del nuevo rol y selecciona los tipos de servicios asociados.
                </p>
                <v-row>
                    <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
                        <p class="font-weight-bold mb-1">Nombre del Rol</p>
                        <v-text-field rounded="lg" clearable v-model="formRol.name" density="compact"
                            variant="outlined"></v-text-field>
                    </v-col>
                    <v-col xs="12" sm="12" md="12" cols="12" class="py-1">
                        <p class="font-weight-bold mb-1">Tipo de Permiso</p>
                        <v-select rounded="lg" clearable v-model="formRol.name" density="compact"
                        variant="outlined"></v-select>                    
                    </v-col>
                    

                </v-row>

            </v-card-text>

        </v-card>
        <template v-slot:append>
            <div class="px-8 pb-9">
                <v-btn rounded="lg" :disabled="loadingGetData" :loading="loadingGetData"  @click="drawerRol = false"
                    class="text-capitalize mb-3" variant="text" block>
                    Cancelar
                </v-btn>
                <v-btn rounded="lg" :disabled="loadingGetData" :loading="loadingGetData" @click="rolApply"
                    class="text-capitalize" color="primary" block>
                    Guardar
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'PermissionsAdmin',
    data() {
        return {
            drawerRol: false,
            isRolCustomer: false,
            loadingGetData: false,
            page: 1,
            totalPage: 5,
            cantPerPage: 15,

            watchPage: true,

            formRol: {
                name: '',
                name_contact: '',
                email: '',
            },

            desserts: [
                {
                    name: 'Administrador',
                    calories: 159,
                    status: "Activo"
                },
                {
                    name: 'Atractivo',
                    calories: 237,
                    status: "Inactivo"
                },
                {
                    name: ' Policía de Turismo',
                    calories: 262,
                    status: "Activo"
                },
                {
                    name: 'Empresario',
                    calories: 305,
                    status: "Activo"
                },
              
               

            ],
        }
    },
    methods: {
        rolApply() {
            this.isRolCustomer = true;
            this.drawerRol = false;

        },

        cleanRol() {
            this.formRol = {
                name: null,
                position: null,
                email: null
            };
            this.isRolCustomer = false;


        },
    },
}
</script>

<style>
.drawer-rol+.v-navigation-drawer__scrim {
    position: fixed !important;
}
</style>