<template>
  <v-select
    :menu-props="{ 'max-width': '100%' }"
    v-model="selectedValue"
    :items="items"
    item-value="id"
    item-title="text"
    :rules="validations"
    multiple
    :density="dens == 1 ? 'compact' : 'default'"
    :variant="customv"
    :hide-details="hidedetails"
    @update:modelValue="emitSelectedValue"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index < 2">
        <span>{{
          item.title && item.title.length > 20
            ? item.title.substring(0, 20) + "..."
            : item.title
        }}</span>
      </v-chip>
      <span v-if="index === 2" class="text-grey text-caption align-self-center">
        (+{{ selectedValue.length - 2 }}
        {{ selectedValue.length - 2 == 1 ? "opción" : "opciones" }})
      </span>
    </template>

    <template v-slot:item="{ props, item, index }">
      <v-list-item v-bind="props" class="text-caption-font">
        <template v-slot:prepend="{ isActive }">
          <v-list-item-action start>
            <v-checkbox-btn
              color="primary"
              :true-value="true"
              :model-value="isActive"
            ></v-checkbox-btn>
          </v-list-item-action>
        </template>
        <v-list-item-title class="text-wrap text-caption">
          <span class="text-caption">{{ item.raw.title }} </span>
        </v-list-item-title>
      </v-list-item>
      <v-divider v-if="index + 1 < items.length"></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "CustomSelectMultimpleComponent",
  props: {
    hidedetails: {
      type: Boolean,
      default: false,
    },
    customv: {
      type: String,
      default: "underlined",
    },
    dens: {
      type: String,
      default: "default",
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    validations: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.selectedValue = newValue;
    },
  },
  methods: {
    emitSelectedValue(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
