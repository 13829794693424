<template>
    <v-row class="fill-height">
        <v-col cols="12" md="12">

            <div class="d-flex pt-3">
                <v-img height="60" :src="require('@/assets/img/logo-guaviare-color.png')"></v-img>
                <v-spacer></v-spacer>
            </div>

            <div class="d-flex justify-center align-center mb-16 pb-6" style="height: 100%;">

                <v-form ref="formreset">
                    <v-card elevation="0" max-width="410" class="mx-auto">
                        <!-- <v-img height="120" :src="require('@/assets/img/logo-guaviare-large.png')"></v-img> -->
                        <v-card-title class="">
                            <h4 class="text-left text-h4 font-weight-bold"
                                style="white-space: normal;word-break: auto-phrase;">
                                Recupera tu contraseña</h4>
                        </v-card-title>
                        <v-card-subtitle class="text-wrap pb-9">
                            Ingresa tu correo electrónico y te enviaremos una nueva contraseña.
                        </v-card-subtitle>
                        <v-card-text>

                            <v-responsive class="mx-auto">
                                <span class="font-weight-medium">
                                    Correo electrónico
                                </span>
                                <v-text-field  rounded="lg" color="secondary" required name="email"
                                :rules="[validations.emailFormat]" autocomplete="new-username"
                                variant="outlined" density="compact" type="input"
                                    v-model="email"></v-text-field>
                            </v-responsive>


                            <v-responsive class="mx-auto mt-5 pt-5">

                                <v-hover>
                                    <template v-slot:default="{ isHovering, props }">
                                        <v-btn v-bind="props" :disabled="loadingForm" rounded="lg"
                                            :loading="loadingForm" @click="resetPassword" color="primary" block
                                            :variant="isHovering ? 'outlined' : 'flat'" class="text-capitalize">
                                            <span v-if="isHovering"
                                                :class="{ 'animate__animated  animate__slideInLeft': isHovering }">
                                                Enviar nueva contraseña </span>
                                            <span class="animate__animated animate__slideInRight"
                                                :class="{ 'd-none': isHovering }"> Enviar nueva contraseña </span>
                                            <div class="area">
                                                <ul class="circles">
                                                    <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                                                </ul>
                                            </div>
                                        </v-btn>
                                    </template>
                                </v-hover>

                            </v-responsive>

                            <v-responsive class="mx-auto py-5 mt-4 text-center">
                                <span> ¿Ya tienes cuenta?

                                    <router-link to="/login"
                                        class="text-decoration-none text-primary font-weight-medium">
                                        Inicia sesión aquí</router-link>
                                </span>
                            </v-responsive>


                        </v-card-text>
                    </v-card>
                </v-form>
            </div>
        </v-col>
    </v-row>

    <DialogResponse @cancel="cancelDialogResponse" v-if="dialogResponse" :dialog="dialogResponse"></DialogResponse>

</template>

<script>

import validationFields from './functions/validationFields';
import DialogResponse from '@/components/dialog/DialogResponse.vue';

export default {
    name: "ResetPassword",
    mixins: [validationFields],
    components: {
        DialogResponse
    },

    setup() {
        // Get toast interface
    },
    data() {
        return {
            email: null,
            loadingForm: false,

            dialogResponse: null,

        };
    },
    methods: {
        async resetPassword() {

            const { valid } = await this.$refs.formreset.validate()
            if (valid) {

                this.loadingForm = true;
                setTimeout(() => {

                    this.dialogResponse = {
                        title: "Recuperación Exitosa",
                        message: "Hemos enviado un correo electrónico a la dirección <b>"+this.email+"</b> con las instrucciones para recuperar tu contraseña.",
                        type: "success",
                        color: "secondary",
                        icon: "mdi-check-circle-outline",
                        show: true,
                        redirect: "/login"
                    }
                    this.loadingForm = false;
                    this.email = null;
                    this.$refs.formreset.resetValidation();

                }, 2000);

            }

        },

        async cancelDialogResponse() {
            this.dialogResponse = null;
        }

    },

}
</script>