<template>
    <div class="container">
        <h4>No se ha podido procesar la solicitud.</h4>
        <ul class="ml-5" v-if="errorMessage && errorMessage.error">
            <li v-for="(item, index) in errorMessage.error" :key="index">
            {{ item[0] ? item[0] : item }}</li>
        </ul>
        <!-- {{ errorMessage }} -->
    </div>
</template>
<script>
export default {
  props: {
    errorMessage: {
      type: Array,
      required: true
    }
  }
}
</script>