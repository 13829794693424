<template>
  <v-row>
    <v-col v-for="(group, index) in groupedResponses" :key="index" md="4" cols="12">
      <v-card outlined class="pa-4 mb-4" elevation="0">
        <v-card-title>{{ group.name_group }}</v-card-title>

        <v-list v-for="question in group.questions" :key="question.question_id">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-wrap mb-2">{{
                question.question_text
              }}</v-list-item-title>

              <v-list-item-subtitle class="text-wrap d-block" v-if="question.question_type === 'text'">
                <b>Respuesta:</b> {{ question.text_response }}
              </v-list-item-subtitle>

              <v-list-item-subtitle class="text-wrap d-block"
                v-else-if="question.question_type === 'single_choice'"
              >
                <b>Opción seleccionada:</b> {{ question.selected_options.text }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else-if="question.question_type === 'multiple_choice'"
              >
                <b>Opciones seleccionadas:</b>
                <v-chip-group column>
                  <v-chip class="text-wrap d-block py-2 h-auto" v-for="option in question.selected_options" :key="option.id">
                    {{ option.text }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    responses: {
      type: Array,
      required: true,
    },
  },
  name: "FormResponses4",
  data() {
    return {
      groupConfig: [
        { name_group: "Datos Básicos", question_ids: [14, 15, 1] },
        { name_group: "Ubicación y contacto", question_ids: [16, 18, 19] },
        { name_group: "Registro Nacional de Turismo", question_ids: [24, 25, 25] },
        { name_group: "Cámara de Comercio", question_ids: [27] },
        {
          name_group: "Número de empleados",
          question_ids: [
            86,
            87,
            88,
            89,
            90,
            91,
            92,
            93,
            94,
            95,
            96,
            97,
            98,
            99,
            100,
            101,
            102,
            103,
            104,
            105,
            106,
            107,
            108,
            109,
            110,
            111,
            112,
            113,
          ],
        },
        { name_group: "Diversidad de Género en Cargos", question_ids: [114] },
        {
          name_group: "Grupos Poblacionales",
          question_ids: [
            115,
            116,
            117,
            118,
            119,
            120,
            121,
            122,
            123,
            124,
            125,
            126,
            127,
            128,
            129,
            130,
            131,
            132,
            133,
            134,
            135,
            136,
            137,
            138,
            139,
            140,
            141,
            142,
            143,
            144,
            145,
            146,
            147,
            148,
            149,
            150,
            151,
            152,
            153,
            154,
            155,
            156,
            157,
            158,
            159,
            160,
            161,
            162,
            163,
          ],
        },
        {
          name_group: "Salario Mensual",
          question_ids: [
            164,
            165,
            166,
            167,
            168,
            169,
            170,
            171,
            172,
            173,
            174,
            175,
            176,
            177,
            178,
            179,
            180,
            181,
            182,
            183,
          ],
        },
         {
           name_group: "Pago Diario",
           question_ids: [
            184,
            185,
            186,
            187,
            188,
            189,
            190,
            191,
            192,
            193,
            194,
            195,
            196,
            197,
            198,
           ],
         },
        { name_group: "Idiomas Extranjeros", question_ids: [199, 200] },
        { name_group: "Información de Guías", question_ids: [201, 202, 203] },
        { name_group: "Nivel Educativo", question_ids: [
          204,
          205,
          206,
          207,
          208,
          209,
          210,
          211,
          212,
          213,
          214,
        ] },
        { name_group: "Empleabilidad Guaviare", question_ids: [215] },
        {
          name_group: "Documentos Requeridos para Contratación de Empleados",
          question_ids: [216],
        },
        { name_group: "Obligaciones Cumplidas como Empleador", question_ids: [217] },
        {
          name_group: "Documentos Requeridos para Contratación de Personas Jurídicas",
          question_ids: [218],
        },
        { name_group: "Meses con Mayor Contratación", question_ids: [219] },
         { name_group: "Contratación por Temporada", question_ids: [
          220,
          221,
          222,
          223,
          224,
          225
         ] },
        {
          name_group: "Garantías de Seguridad de Empleados y Clientes",
          question_ids: [226],
        },
        { name_group: "Bienestar de los Trabajadores", question_ids: [227] },
      ],
    };
  },
  computed: {
    groupedResponses() {
      return this.groupConfig
        .map((group) => {
          // Filtra las preguntas que pertenecen al grupo actual
          const questions = this.responses.filter((response) =>
            group.question_ids.includes(response.question_id)
          );

          // Retorna el grupo solo si contiene preguntas
          return questions.length > 0
            ? { name_group: group.name_group, questions }
            : null;
        })
        .filter((group) => group !== null); // Filtra los grupos que son null
    },
  },
};
</script>
