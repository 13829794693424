<template>
  <v-container class="fill-height">
    <v-row class="align-center justify-center">
      <v-col md="4" class="align-center justify-center d-flex">
        <v-card max-width="500" elevation="0">
          <v-card-text>
            <v-empty-state headline="Ups, 403" title="Acceso Denegado"
              text="Lo sentimos, no tienes permiso para acceder a la página solicitada."
              :image="require('@/assets/img/admin/403.png')"></v-empty-state>
          </v-card-text>
          <v-card-actions class="text-center d-block">
            <v-btn color="primary" variant="flat" rounded="pill" class="text-capitalize px-5" @click="goBack">Regresar a
              la página anterior</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    // Función para regresar a la página anterior
    goBack() {
      this.$router.go(-1); // Retrocede una página en el historial de navegación
    }
  }
}
</script>