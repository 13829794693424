import FormEmployability from '../views/forms/FormEmployability.vue';
import FormOfferPst from '../views/forms/FormOfferPst.vue';
import FormEntrepreneurRegistration from '../views/forms/FormEntrepreneurRegistration.vue';
import FormTouristRegistration from '../views/forms/FormTouristRegistration.vue';
import FormVisitorEntry from '../views/forms/FormVisitorEntry.vue';
import FormDestinyPerception from '../views/forms/FormDestinyPerception.vue';
import FormOfferGuides from '../views/forms/FormOfferGuides.vue';
import FormPerceptionTouristAttractive from '../views/forms/FormPerceptionTouristAttractive.vue';
import FormPerceptionTouristLodging from '../views/forms/FormPerceptionTouristLodging.vue';
import FormSustainability from '../views/forms/FormSustainability.vue';
import FormAttractions from '@/views/forms/FormAttractions.vue';

export default [
    {
        path: '/formularios/empleabilidad',
        name: 'FormEmployability',
        component: FormEmployability,
        meta: { role: 'admin', role_id: [1,2,3], requiresAuth: true }
      },
      {
        path: '/formularios/oferta-pst',
        name: 'FormOfferPst',
        component: FormOfferPst,
        meta: { role: 'admin', role_id: [1,2,3], requiresAuth: true }
      },
      {
        path: '/formularios/registro-empresarios',
        name: 'FormEntrepreneurRegistration',
        component: FormEntrepreneurRegistration,
        meta: { role: 'admin', role_id: [1,2,3], requiresAuth: false }
      },
      {
        path: '/formularios/registro-visitantes',
        name: 'FormTouristRegistration',
        component: FormTouristRegistration,
        meta: { role: 'admin', role_id: [1,2,3], requiresAuth: true }
      },
      {
        path: '/formularios/ingreso-visitantes',
        name: 'FormVisitorEntry',
        component: FormVisitorEntry,
        meta: { role: 'admin', role_id: [1,2,3], requiresAuth: true }
      },
      
      {
        path: '/formularios/ofertas-guias',
        name: 'FormOfferGuides',
        component: FormOfferGuides,
        meta: { role: 'admin', role_id: [1,2,3], requiresAuth: true }
      },
      {
        path: '/formularios/percepcion-turista-atractivo',
        name: 'FormPerceptionTouristAttractive',
        component: FormPerceptionTouristAttractive,
        meta: { role: 'admin', role_id: [1,2,3], requiresAuth: false }
      },
    
      {
        path: '/formularios/percepcion-turista-alojamiento',
        name: 'FormPerceptionTouristLodging',
        component: FormPerceptionTouristLodging,
        meta: { role: 'admin', role_id: [1,2,3], requiresAuth: false }
      },

      {
        path: '/formularios/percepcion-destino',
        name: 'FormDestinyPerception',
        component: FormDestinyPerception,
        meta: { role: 'admin', role_id: [1,2,3], requiresAuth: false }
      },
      {
        path: '/formularios/sostenibilidad',
        name: 'FormSustainability',
        component: FormSustainability,
        meta: { role: 'admin', role_id: [1,2,3], requiresAuth: true }
      },
      {
        path: '/formularios/atractivos',
        name: 'FormAttractions',
        component: FormAttractions,
        meta: { role: 'admin', role_id: [1,2,3], requiresAuth: true }
      },

];