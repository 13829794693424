<template>
  <v-row>
    <v-col md="12">
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <!-- <v-text-field
          variant="outlined"
          bg-color="white"
          placeholder="Buscar registros"
          density="compact"
        >
          <template v-slot:append-inner>
            <v-icon class="opacity-100" color="primary">mdi-magnify</v-icon>
          </template>
        </v-text-field> -->
      </div>
    </v-col>

    <v-col md="12">
      <v-table density="compact">
        <thead>
          <tr>
            <th class="border-0 text-center bg-primary py-4 text-left">ID</th>
            <th class="border-0 text-center bg-primary py-4 text-left">EMPRESA</th>
            <th class="border-0 text-center bg-primary py-4 text-left">ENCUESTA</th>

            <th class="border-0 text-center bg-primary py-4 text-left">
              FECHA DE CREACIÓN
            </th>
            <th class="border-0 text-center bg-primary py-4 text-left">
              FECHA DE MODIFICACIÓN
            </th>
            <th class="border-0 text-center bg-primary py-4 text-left"></th>
          </tr>
          <tr>
            <th colspan="8" style="background: #fcfcfc; height: 15px; border: 0"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in desserts" :key="item.id">
            <td>{{ item.id }}</td>
            <td>
              <p>
                {{
                  item.user && item.user.entrepreneur
                    ? item.user.entrepreneur.company_name
                    : ""
                }}
                <br />
                <span class="text-caption text-grey">
                  {{ item.user ? "(" + item.user.name + ")" : "" }}
                </span>
              </p>
            </td>
            <td>{{ item.form.title }}</td>

            <td class="text-center">{{ item.created_at }}</td>
            <td class="text-center">{{ item.updated_at }}</td>
            <td class="text-center">
              <div class="d-flex justify-center">
                <v-btn
                  disabled
                  rounded="lg"
                  style="border: 2px solid"
                  class="my-2 mx-1 font-weight-bold"
                  height="35"
                  max-width="25"
                  size="x-small"
                  variant="outlined"
                  color="red-app"
                >
                  <v-icon size="20">mdi-trash-can</v-icon>
                </v-btn>
                <!-- <v-btn
                  rounded="lg"
                  style="border: 2px solid"
                  class="my-2 mx-1 font-weight-bold"
                  height="35"
                  max-width="25"
                  size="x-small"
                  variant="outlined"
                  color="primary"
                >
                  <v-icon size="20">mdi-file-edit-outline</v-icon>
                </v-btn> -->
                <v-btn
                  @click="viewDetailFormResponse(item.id, item.form_id, item.form.title)"
                  rounded="lg"
                  style="border: 2px solid"
                  class="my-2 mx-1 font-weight-bold"
                  height="35"
                  max-width="25"
                  size="x-small"
                  variant="outlined"
                  color="secondary"
                >
                  <v-icon size="20">mdi-eye-outline</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-col>
    <v-col cols="8" offset="4" class="d-flex">
      <v-spacer></v-spacer>
      <v-pagination
        :total-visible="4"
        v-model="page"
        variant="flat"
        border="1"
        active-color="secondary"
        density="compact"
        :length="totalPage"
      ></v-pagination>
    </v-col>
  </v-row>
  <v-dialog
    v-model="dialogDetailFormResponse"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <v-card>
      <v-toolbar color="secondary">
        <v-btn icon="mdi-close" @click="dialogDetailFormResponse = false"></v-btn>

        <v-toolbar-title>{{ form_name }}</v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text :class="{ 'align-center justify-center d-flex': loadingData }">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="50"
          v-if="loadingData"
        ></v-progress-circular>
        <FormResponses2 v-if="!loadingData && form_id == 2" :responses="responses" />
        <FormResponses3 v-else-if="!loadingData && form_id == 3" :responses="responses" />
        <FormResponses4 v-else-if="!loadingData && form_id == 4" :responses="responses" />
        <FormResponses5 v-else-if="!loadingData && form_id == 5" :responses="responses" />
        <FormResponses6 v-else-if="!loadingData && form_id == 6" :responses="responses" />
        <FormResponses10
          v-else-if="!loadingData && form_id == 10"
          :responses="responses"
        />
        <FormResponses11
          v-else-if="!loadingData && form_id == 11"
          :responses="responses"
        />
        <FormResponses8 v-else-if="!loadingData && form_id == 8" :responses="responses" />
        <FormResponses7 v-else-if="!loadingData && form_id == 7" :responses="responses" />
        <FormResponses9 v-else-if="!loadingData && form_id == 9" :responses="responses" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import FormResponses2 from "./FormResponses2.vue";
import FormResponses3 from "./FormResponses3.vue";
import FormResponses4 from "./FormResponses4.vue";
import FormResponses5 from "./FormResponses5.vue";
import FormResponses6 from "./FormResponses6.vue";
import FormResponses10 from "./FormResponses10.vue";
import FormResponses11 from "./FormResponses11.vue";
import FormResponses8 from "./FormResponses8.vue";
import FormResponses7 from "./FormResponses7.vue";
import FormResponses9 from "./FormResponses9.vue";

export default {
  props: {
    is_admin: { type: Boolean, default: false },
  },
  name: "ViewData",
  components: {
    FormResponses2,
    FormResponses3,
    FormResponses4,
    FormResponses5,
    FormResponses6,
    FormResponses10,
    FormResponses11,
    FormResponses8,
    FormResponses7,
    FormResponses9,
  },
  data() {
    return {
      response_id: null,
      form_id: null,
      form_name: null,
      page: 1,
      totalPage: 1,
      cantPerPage: 10,
      desserts: [],
      responses: [],
      dialogDetailFormResponse: false,
      loadingData: true,
    };
  },
  watch: {
    page() {
      this.getAllResponses();
    },
  },
  mounted() {
    this.getAllResponses();
  },

  methods: {
    viewDetailFormResponse(id, form_id, form_name) {
      this.response_id = id;
      this.form_id = form_id;
      this.form_name = form_name;
      this.loadingData = true;

      axios
        .get(
          process.env.VUE_APP_API_URL +
            `/form-responses/detail-response?response_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          }
        )
        .then((response) => {
          this.responses = response.data;
          this.dialogDetailFormResponse = true;
          this.loadingData = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingData = false;
        });
    },
    getAllResponses() {
      try {
        let url = process.env.VUE_APP_API_URL;
        const token = this.$store.state.token;

        axios
          .get(
            url +
              `/form-responses/all-responses?user_id=` +
              (this.is_admin ? -1 : this.$store.state.user.id) +
              "&perpage=" +
              this.cantPerPage +
              "&page=" +
              this.page,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.desserts = response.data.data;
            this.totalPage = Math.ceil(response.data.total / this.cantPerPage);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
