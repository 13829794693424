<template>
  <v-container
    class="pa-0"
    style="height: 100%; width: 100%; min-width: 100%; position: relative"
  >
    <NavBarAnimation2
      v-if="isMdOrLower"
      color="primary"
      title="Atractivo"
      subTitle="¡Hola! Apreciado administrador de atractivo, en esta sección podrá registrar los datos del sitio. Recuerde que estos datos no serán utilizados en ningún momento para realizar control fiscal o de impuestos, los datos que nos suministra ayudan a caracterizar la oferta de servicios turísticos a nivel departamental. Los datos que se solicitan en este formulario son estrictamente confidenciales y se utilizarán únicamente con fines estadísticos, por lo que no se compartiran con otras entidades como: DIAN, Camara y Comercio, Alcaldias, etc."
    >
    </NavBarAnimation2>

    <v-app-bar class="elevation-0" v-if="isMdOrLower">
      <v-slide-group
        v-model="stepFormServiceOrder"
        class="py-4"
        center-active
        show-arrows
      >
        <v-slide-group-item
          v-for="(item, index) in stepTitles.filter((step) => step.show)"
          :key="index"
          v-slot="{ isSelected }"
          :value="item.id"
        >
          <v-list-item
            :color="isSelected ? 'primary' : 'grey-lighten-1'"
            @click="stepFormServiceOrder = index + 1"
          >
            <template v-slot:prepend>
              <v-avatar
                :color="
                  isSelected || stepFormServiceOrder > index + 1
                    ? 'primary'
                    : 'grey-lighten-1'
                "
                class="text-white"
                size="x-small"
              >
                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1"
                  >mdi-pencil</v-icon
                >
                <span v-else>{{ index + 1 }}</span>
              </v-avatar>
            </template>
            <v-list-item-title
              class="text-caption"
              :class="
                isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-slide-group-item>
      </v-slide-group>
    </v-app-bar>

    <v-row class="h-100 mh-100 auth">
      <v-col
        lg="5"
        xl="4"
        cols="12"
        class="d-lg-flex d-none align-center justify-center bg-primary pa-0"
        style="position: fixed; height: 100%"
      >
        <LeftPanelAnimation2
          title=""
          subTitle="¡Hola! Apreciado administrador de atractivo, en esta sección podrá registrar los datos del sitio. Recuerde que estos datos no serán utilizados en ningún momento para realizar control fiscal o de impuestos, los datos que nos suministra ayudan a caracterizar la oferta de servicios turísticos a nivel departamental. Los datos que se solicitan en este formulario son estrictamente confidenciales y se utilizarán únicamente con fines estadísticos, por lo que no se compartiran con otras entidades como: DIAN, Camara y Comercio, Alcaldias, etc."
          :wordsAnimate="['Atractivo']"
        >
        </LeftPanelAnimation2>
      </v-col>
      <v-col lg="7" xl="8" cols="12" style="background: #fff; z-index: 9" class="ml-auto">
        <v-row class="h-100 mh-100">
          <v-col
            lg="5"
            xl="4"
            cols="12"
            class="d-lg-flex d-none align-center justify-center position-relative"
          >
            <div class="scroll-container-wrapper">
              <div
                class="scroll-container position-relative"
                ref="scrollContainer"
                @scroll="handleScroll"
              >
                <div class="item">
                  <div
                    class="mouse m-2"
                    :class="{
                      'animate__animated animate__fadeInDown': showIndicator,
                      'animate__animated animate__fadeOutDown': !showIndicator,
                    }"
                  ></div>
                </div>
                <v-timeline class="py-8" align="start" side="end" :line-thickness="1">
                  <v-timeline-item
                    v-for="(item, index) in stepTitles.filter((step) => step.show)"
                    :key="index"
                    :class="stepFormServiceOrder > item.id - 1 ? 'success-step' : ''"
                    :dot-color="
                      stepFormServiceOrder > item.id - 1 ? 'primary' : 'dot-customer'
                    "
                    size="small"
                  >
                    <template v-slot:opposite>
                      <p class="font-weight-medium text-end">
                        {{ showGroupLabel(item, index) ? item.label : null }}
                      </p>
                    </template>
                    <template v-slot:icon>
                      <v-btn
                        width="20"
                        height="20"
                        @click="stepFormServiceOrder = item.id"
                        size="x-small"
                        icon
                        variant="text"
                      >
                        <v-icon v-if="stepFormServiceOrder == item.id">mdi-pencil</v-icon>
                        <v-icon v-else-if="stepFormServiceOrder > item.id"
                          >mdi-check</v-icon
                        >
                        <span v-else>{{ index + 1 }}</span>
                      </v-btn>
                    </template>
                    <div class="d-flex">
                      <div :ref="'timelineItem' + item.id"></div>

                      <div
                        class="text-left text-body-2"
                        :class="{ 'text-primary': stepFormServiceOrder > item.id }"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-if="showTopShadow" class="top-shadow"></div>
              <div v-if="showBottomShadow" class="bottom-shadow"></div>
            </div>
          </v-col>

          <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
            <v-card width="100%" elevation="0">
              <v-card-text>
                <p class="text-h4 font-weight-bold text-primary">
                  {{ currentTitle.label }}
                </p>
                <p class="text-h5 font-weight-bold">
                  {{ currentTitle.title }}
                </p>
              </v-card-text>
              <v-col md="12" cols="12" class="py-1">
                <br />
              </v-col>

              <v-window
                :touch="false"
                direction="vertical"
                v-model="stepFormServiceOrder"
              >
                <v-window-item :value="1">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep1">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Representante legal de la empresa</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-text-field
                            v-model="form.field_a_rep_name"
                            :rules="[validations.required]"
                            color="primary"
                            variant="underlined"
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Sitios/Atractivos que administra</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_managed_sites"
                            :items="formItems.itemsAdminSitesManage"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_a_managed_sites.length - 2 }}
                                {{
                                  form.field_a_managed_sites.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsAdminSitesManage.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep2">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Ciudad de ubicación del atractivo</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            v-model="form.field_i_attraction_location_city"
                            :rules="[validations.required]"
                            color="primary"
                            variant="underlined"
                            :items="formItems.itemsCityAttractives"
                            item-value="id"
                            item-title="text"
                            placeholder="Selecciona una opción"
                          ></v-select>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Ubicación del atractivo</span>
                          <span class="font-weight-bold">*</span>

                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            color="primary"
                            @input="onPlaceChanged"
                            v-model="form.field_i_attraction_location"
                            ref="addressField"
                            variant="underlined"
                            placeholder="Escribe la dirección de la empresa"
                          ></v-text-field>

                          <div
                            ref="map"
                            class="map mb-3"
                            style="width: 100%; height: 400px"
                          ></div>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Página del atractivo</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            v-model="form.field_i_attraction_web_page"
                            :rules="[validations.required, validations.url]"
                            color="primary"
                            variant="underlined"
                            placeholder="Ingrese la URL de la página del atractivo"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="3">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep3">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            ¿Cuáles actividades de turismo de naturaleza se realizan en el
                            sitio?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_i_nature_tourism_activities"
                            :items="formItems.itemsNatureTourismActivities"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_i_nature_tourism_activities.length - 2 }}
                                {{
                                  form.field_i_nature_tourism_activities.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 <
                                  formItems.itemsNatureTourismActivities.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="4">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep4">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Qué servicios se ofrecen en el atractivo?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_i_attraction_services"
                            :items="formItems.itemsAttractionServices"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_i_attraction_services.length - 2 }}
                                {{
                                  form.field_i_attraction_services.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsAttractionServices.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_i_attraction_services.includes(590)"
                        >
                          <v-text-field
                            color="primary"
                            variant="underlined"
                            placeholder="¿Cuál?"
                            v-model="form.field_i_other_service"
                            :rules="[validations.required]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="5">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep5">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuáles de los siguientes ecosistemas se encuentran en el
                            atractivo?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_i_ecosystems"
                            :items="formItems.itemsEcosystems"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_i_ecosystems.length - 2 }}
                                {{
                                  form.field_i_ecosystems.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsEcosystems.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="6">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep6">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >El atractivo está ubicado en área protegida?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_i_protected_area"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsProtectedArea"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_i_protected_area == item.id,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_i_protected_area == 593"
                        >
                          <span class="font-weight-bold">Tipo de área protegida </span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_i_protected_area_type"
                            :items="formItems.itemsTypeAreaProtected"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.checkboxRequired]"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_i_protected_area_type.length - 2 }}
                                {{
                                  form.field_i_protected_area_type.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsTypeAreaProtected.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="7">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep7">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuáles de los siguientes elementos e infraestructura tiene
                            su atractivo?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_i_infrastructure_elements"
                            :items="formItems.itemsInfrastructureElements"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_i_infrastructure_elements.length - 2 }}
                                {{
                                  form.field_i_infrastructure_elements.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsInfrastructureElements.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="8">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep8">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique cuál es el estado de los elementos e infraestructura,
                            teniendo en cuenta que 5
                            <v-icon color="orange-lighten-1">mdi-star</v-icon> es
                            "Excelente" y 1 "Malo"</span
                          >
                          <v-list style="width: 100%">
                            <v-list-item
                              v-for="(item, index) in filteredRatingsStateInfrastructure"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                                <v-rating
                                  color="orange-lighten-1"
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      large
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="9">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep9">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Tipo de propiedad en donde queda el atractivo</span
                          >
                          <!-- El tipo de propiedad puede ser Privada, Mixta, Publica -->
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_i_attraction_property_type"
                            :items="formItems.itemsPropertyType"
                            item-value="id"
                            item-title="text"
                            variant="underlined"
                          ></v-select>
                        </v-col>
                        <!-- Tipo de via de acceso -->
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Tipo de vía de acceso</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_i_attraction_access_road_type"
                            :items="formItems.itemsRoadType"
                            item-value="id"
                            item-title="text"
                            variant="underlined"
                          ></v-select>

                          <ul class="my-3 text-caption">
                            <li>
                              <span class="text-secondary font-weight-medium"
                                >Ayuda 1:</span
                              >
                              las vías secundarias son las que unen las cabeceras
                              municipales entre sí o que conectan una cabecera municipal
                              con una carretera Primaria.
                            </li>
                            <li>
                              <span class="text-secondary font-weight-medium"
                                >Ayuda 2:</span
                              >
                              Las vias terciarias son las que comunican la cabecera
                              municipal con veredas o que comunican veredas entre sí.
                            </li>
                          </ul>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="10">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep10">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿El precio de la entrada es más bajo para los habitantes de
                            la comunidad local?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_i_local_community_price"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsLocalCommunityPrice"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_i_protected_area == item.id,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿El precio de la entrada para los visitantes nacionales es
                            diferente al de los visitantes extranjeros?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_i_national_foreign_price"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsNationalForeignPrice"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_i_national_foreign_price == item.id,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿El precio de la entrada es más bajo para niños?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_i_children_price"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsChildrenPrice"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_i_children_price == item.id,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="11">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep11">
                      <v-row>
                        <v-col sm="12" md="12" cols="12" class="py-1 align-center">
                          <span class="font-weight-bold"
                            >Indique los precios de entrada *
                          </span>
                          <v-row>
                            <v-col
                              sm="12"
                              md="12"
                              cols="12"
                              class="py-1 d-md-flex align-center"
                              v-if="form.field_i_national_foreign_price == 627"
                            >
                              <span>Tarifa general </span>
                              <v-text-field
                                min-width="300"
                                max-width="200"
                                class="ml-auto"
                                :rules="[
                                  validations.required,
                                  validations.maxLength(255),
                                ]"
                                v-numbers-only-app
                                v-model="form.field_i_general_rate"
                                color="primary"
                                variant="underlined"
                                placeholder=""
                              ></v-text-field>
                            </v-col>
                            <v-col
                              sm="12"
                              md="12"
                              cols="12"
                              class="py-1 d-md-flex align-center"
                              v-if="form.field_i_local_community_price == 624"
                            >
                              <span class="">Tarifa para locales </span>
                              <v-text-field
                                min-width="300"
                                max-width="200"
                                class="ml-auto"
                                :rules="[
                                  validations.required,
                                  validations.maxLength(255),
                                ]"
                                v-numbers-only-app
                                v-model="form.field_i_local_rate"
                                color="primary"
                                variant="underlined"
                                placeholder=""
                              ></v-text-field>
                            </v-col>
                            <v-col
                              sm="12"
                              md="12"
                              cols="12"
                              class="py-1 d-md-flex align-center"
                              v-if="form.field_i_national_foreign_price == 626"
                            >
                              <span class="">Tarifa para nacionales </span>
                              <v-text-field
                                min-width="300"
                                max-width="200"
                                class="ml-auto"
                                :rules="[
                                  validations.required,
                                  validations.maxLength(255),
                                ]"
                                v-numbers-only-app
                                v-model="form.field_i_national_rate"
                                color="primary"
                                variant="underlined"
                                placeholder=""
                              ></v-text-field>
                            </v-col>
                            <v-col
                              sm="12"
                              md="12"
                              cols="12"
                              class="py-1 d-md-flex align-center"
                              v-if="form.field_i_national_foreign_price == 626"
                            >
                              <span class="">Tarifa para extranjeros </span>
                              <v-text-field
                                min-width="300"
                                max-width="200"
                                class="ml-auto"
                                :rules="[
                                  validations.required,
                                  validations.maxLength(255),
                                ]"
                                v-numbers-only-app
                                v-model="form.field_i_foreign_rate"
                                color="primary"
                                variant="underlined"
                                placeholder=""
                              ></v-text-field>
                            </v-col>
                            <v-col
                              sm="12"
                              md="12"
                              cols="12"
                              class="py-1 d-md-flex align-center"
                              v-if="form.field_i_children_price == 628"
                            >
                              <span class="">Tarifa para niños </span>
                              <v-text-field
                                min-width="300"
                                max-width="200"
                                class="ml-auto"
                                :rules="[
                                  validations.required,
                                  validations.maxLength(255),
                                ]"
                                v-numbers-only-app
                                v-model="form.field_i_children_rate"
                                color="primary"
                                variant="underlined"
                                placeholder=""
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="12">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep12">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Marque los meses en los que el atractivo está abierto al
                            público</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_i_open_months"
                            :items="formItems.itemsMonthsOpen"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_i_open_months.length - 2 }}
                                {{
                                  form.field_i_open_months.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsMonthsOpen.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="form.field_i_open_months"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="13">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep13">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Marque los meses que conforman la temporada alta</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_i_high_season_months"
                            :items="formItems.itemsMonthsHighSeason"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_i_high_season_months.length - 2 }}
                                {{
                                  form.field_i_high_season_months.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsMonthsHighSeason.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="form.field_i_high_season_months"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="14">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep13">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿El atractivo cuenta con el estudio de capacidad de carga?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_i_carrying_capacity_study"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsCapacityStudy"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_i_carrying_capacity_study == item.id,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>

                        <!-- Año en el que se realizo el estudio -->
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_i_carrying_capacity_study == 654"
                        >
                          <span class="font-weight-bold"
                            >Año en el que se realizó el estudio</span
                          >
                          <v-text-field
                            v-model="form.field_i_study_year"
                            :rules="[validations.required]"
                            color="primary"
                            variant="underlined"
                            type="number"
                          ></v-text-field>
                        </v-col>

                        <!-- Capacidad de carga que aparece en el estudio -->
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_i_carrying_capacity_study == 654"
                        >
                          <span class="font-weight-bold"
                            >Digite la capacidad de carga que aparece en el estudio
                            (Número de personas)</span
                          >
                          <v-text-field
                            v-model="form.field_i_carrying_capacity"
                            :rules="[validations.required]"
                            color="primary"
                            variant="underlined"
                            type="number"
                          ></v-text-field>
                        </v-col>

                        <!-- Indique qué otras condiciones para la operación tiene el atractivo (personas por recorrido o actividad, cantidad de personas por grupo, etc) -->
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_i_carrying_capacity_study == 654"
                        >
                          <span class="font-weight-bold"
                            >Indique qué otras condiciones para la operación tiene el
                            atractivo (personas por recorrido o actividad, cantidad de
                            personas por grupo, etc)</span
                          >
                          <v-text-field
                            v-model="form.field_i_other_conditions"
                            :rules="[validations.required]"
                            color="primary"
                            variant="underlined"
                          ></v-text-field>
                        </v-col>
                        <!-- Indique qué entidad, empresa u organización hizo el estudio -->
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_i_carrying_capacity_study == 654"
                        >
                          <span class="font-weight-bold"
                            >Indique qué entidad, empresa u organización hizo el
                            estudio</span
                          >
                          <v-text-field
                            v-model="form.field_i_study_entity"
                            :rules="[validations.required]"
                            color="primary"
                            variant="underlined"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <v-card-actions>
                <br />
              </v-card-actions>
              <v-card-actions class="mb-9">
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  :disabled="stepFormServiceOrder > 1 ? false : true"
                  variant="text"
                  @click="goToPreviousStep"
                >
                  Anterior
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  v-if="
                    stepFormServiceOrder <
                    stepTitles.filter((step) => step.show)[
                      stepTitles.filter((step) => step.show).length - 1
                    ].id
                  "
                  color="primary"
                  variant="text"
                  @click="goToNextStep"
                >
                  Siguiente
                </v-btn>
                <v-btn
                  @click="saveForm"
                  class="text-capitalize"
                  v-else
                  color="primary"
                  variant="flat"
                >
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center position-fixed"
      scrim="#FFF"
      :opacity="1"
      v-model="loadingPageOverlay"
    >
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h4 class="text-h4 mt-4 font-weight-medium text-primary">Enviando formulario</h4>
        <p class="text-h6 font-weight-medium mt-2">
          Por favor, espera mientras procesamos tu solicitud.
        </p>
      </div>
    </v-overlay>
    <UserExperience></UserExperience>
    <DialogResponse
      @cancel="cancelDialogResponse"
      v-if="dialogResponse"
      :dialog="dialogResponse"
    ></DialogResponse>
  </v-container>
</template>

<script>
/* global google */

import anime from "animejs/lib/anime.es.js";
import { useDisplay } from "vuetify";
import validationFields from "../functions/validationFields";
import LeftPanelAnimation2 from "./includes/LeftPanelAnimation2.vue";
import NavBarAnimation2 from "./includes/NavBarAnimation2.vue";
import UserExperience from "./includes/UserExperience.vue";
import axios from "axios";
import { useToast } from "vue-toastification";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import DialogResponse from "@/components/dialog/DialogResponse.vue";

export default {
  name: "FormAttractions",
  mixins: [validationFields],
  components: {
    LeftPanelAnimation2,
    NavBarAnimation2,
    UserExperience,
    DialogResponse,
  },

  setup() {
    const { mdAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
    };
  },

  data() {
    return {
      showTopShadow: false,
      showBottomShadow: true,
      form_relations_question: null,
      toast: useToast(),
      loadingPageOverlay: false,
      dialogResponse: null,
      formConfig: null,
      formItems: {
        itemsRepresentativeLegal: [],
        itemsAdminSitesManage: [],
        itemsCityAttractives: [],
        itemsNatureTourismActivities: [],
        itemsAttractionServices: [],
        itemsEcosystems: [],
        itemsProtectedArea: [],
        itemsTypeAreaProtected: [],
        itemsInfrastructureElements: [],
        itemsPropertyType: [],
        itemsRoadType: [],
        itemsLocalCommunityPrice: [],
        itemsNationalForeignPrice: [],
        itemsChildrenPrice: [],
        itemsMonthsOpen: [],
        itemsMonthsHighSeason: [],
        itemsCapacityStudy: [],
      },
      menuDateCheckin: false,
      menuDateCheckout: false,
      form: {
        field_a_rep_name: null,
        field_a_managed_sites: null,
        field_i_attraction_location_city: null,
        field_i_attraction_location: null,
        field_i_attraction_web_page: null,
        field_i_nature_tourism_activities: [],
        field_i_attraction_services: [],
        field_i_other_service: null,
        field_i_ecosystems: [],
        field_i_protected_area: null,
        field_i_protected_area_type: [],
        field_i_infrastructure_elements: [],
        field_i_infrastructure_bathrooms_state: null,
        field_i_infrastructure_resting_place_state: null,
        field_i_infrastructure_trails_state: null,
        field_i_infrastructure_viewpoint_state: null,
        field_i_infrastructure_signage_state: null,
        field_i_infrastructure_parking_state: null,
        field_i_infrastructure_ramps_state: null,
        field_i_infrastructure_recycling_points_state: null,
        field_i_infrastructure_solar_energy_state: null,
        field_i_infrastructure_internet_state: null,
        field_i_infrastructure_interpretation_centers_state: null,
        field_i_infrastructure_pier_state: null,
        field_i_infrastructure_ticket_office_state: null,
        field_i_infrastructure_sewage_plant_state: null,
        field_i_attraction_property_type: null,
        field_i_attraction_access_road_type: null,
        field_i_local_community_price: null,
        field_i_national_foreign_price: null,
        field_i_children_price: null,
        field_i_general_rate: null,
        field_i_local_rate: null,
        field_i_national_rate: null,
        field_i_foreign_rate: null,
        field_i_children_rate: null,
        field_i_open_months: [],
        field_i_high_season_months: [],
        field_i_carrying_capacity_study: null,
        field_i_study_year: null,
        field_i_carrying_capacity: null,
        field_i_other_conditions: null,
        field_i_study_entity: null,
      },

      map: null,

      ratingsStateInfrastructure: [
        {
          model: "field_i_infrastructure_bathrooms_state",
          label: "Baños dentro del atractivo",
          value: 0,
          id: 597,
        },
        {
          model: "field_i_infrastructure_resting_place_state",
          label: "Lugar de descanso",
          value: 0,
          id: 598,
        },
        {
          model: "field_i_infrastructure_food_consumption_place_state",
          label: "Lugar para consumo de alimentos",
          value: 0,
          id: 599,
        },
        {
          model: "field_i_infrastructure_trails_state",
          label: "Senderos",
          value: 0,
          id: 600,
        },
        {
          model: "field_i_infrastructure_viewpoint_state",
          label: "Mirador o plataforma de observación",
          value: 0,
          id: 601,
        },
        {
          model: "field_i_infrastructure_signage_state",
          label: "Señaletica",
          value: 0,
          id: 602,
        },
        {
          model: "field_i_infrastructure_parking_state",
          label: "Parqueadero",
          value: 0,
          id: 603,
        },
        {
          model: "field_i_infrastructure_ramps_state",
          label: "Rampas de acceso para discapacitados",
          value: 0,
          id: 604,
        },
        {
          model: "field_i_infrastructure_recycling_points_state",
          label: "Puntos de reciclaje",
          value: 0,
          id: 605,
        },
        {
          model: "field_i_infrastructure_solar_energy_state",
          label: "Energía solar",
          value: 0,
          id: 606,
        },
        {
          model: "field_i_infrastructure_internet_state",
          label: "Internet",
          value: 0,
          id: 607,
        },
        {
          model: "field_i_infrastructure_interpretation_centers_state",
          label: "Centros de interpretación",
          value: 0,
          id: 608,
        },
        {
          model: "field_i_infrastructure_pier_state",
          label: "Muelle",
          value: 0,
          id: 609,
        },
        {
          model: "field_i_infrastructure_ticket_office_state",
          label: "Taquilla",
          value: 0,
          id: 610,
        },
        {
          model: "field_i_infrastructure_sewage_plant_state",
          label: "Planta de aguas residuales",
          value: 0,
          id: 611,
        },
      ],

      isMobile: false,

      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

      stepFormServiceOrder: 1,
      stepTitles: [
        {
          group: 1,
          label: "Datos de la empresa",
          id: 1,
          title: "Identificación",
          show: true,
        },
        {
          group: 2,
          label: "Servicios y área del atractivo",
          id: 2,
          title: "Ubicación del Atractivo",
          show: true,
        },
        {
          group: 2,
          label: "Servicios y área del atractivo",
          id: 3,
          title: "Actividades de turismo de naturaleza",
          show: true,
        },
        {
          group: 2,
          label: "Servicios y área del atractivo",
          id: 4,
          title: "Servicios en el Atractivo",
          show: true,
        },
        {
          group: 2,
          label: "Servicios y área del atractivo",
          id: 5,
          title: "Ecosistemas en el Atractivo",
          show: true,
        },
        {
          group: 2,
          label: "Servicios y área del atractivo",
          id: 6,
          title: "Area protegida",
          show: true,
        },
        {
          group: 3,
          label: "Infraestructura del atractivo",
          id: 7,
          title: "Elementos e Infraestructura",
          show: true,
        },
        {
          group: 3,
          label: "Infraestructura del atractivo",
          id: 8,
          title: "Estado de Elementos e Infraestructura",
          show: true,
        },
        {
          group: 3,
          label: "Infraestructura del atractivo",
          id: 9,
          title: "Tipo de Propiedad y vía de acceso",
          show: true,
        },
        {
          group: 4,
          label: "Tarifas",
          id: 10,
          title: "Precio de la entrada",
          show: true,
        },
        {
          group: 4,
          label: "Tarifas",
          id: 11,
          title: "Tarifas",
          show: false,
        },
        {
          group: 5,
          label: "Oferta y Demanda",
          id: 12,
          title: "Disponibilidad mensual",
          show: true,
        },
        {
          group: 5,
          label: "Oferta y Demanda",
          id: 13,
          title: "Temporada Alta",
          show: true,
        },
        {
          group: 6,
          label: "Capacidad de carga",
          id: 14,
          title: "Estudio de Capacidad de Carga",
          show: true,
        },
      ],

      showIndicator: true, // Initially show the indicator
      activeWatchVisit: true,
    };
  },
  watch: {
    "form.field_i_protected_area"() {
      this.form.field_i_protected_area_type = null;
    },

    "form.field_i_local_community_price"() {
      this.form.field_i_local_rate = null;
      this.showStepTarifas();
    },
    "form.field_i_national_foreign_price"() {
      this.form.field_i_general_rate = null;
      this.form.field_i_national_rate = null;
      this.form.field_i_foreign_rate = null;

      this.showStepTarifas();
    },
    "form.field_i_children_price"() {
      this.form.field_i_children_rate = null;
      this.showStepTarifas();
    },

    "form.field_i_attraction_services"() {
      this.form.field_i_other_service = null;
    },

    // 'form.field_f_document_number'() {
    //   this.getAutocompleteFormRegistroVisitantes();
    // },

    stepFormServiceOrder(newVal) {
      if (newVal < this.stepTitles.length) {
        this.scrollToItem(newVal);
      }
      if (
        this.stepFormServiceOrder == 2 &&
        this.form.field_i_attraction_location == null
      ) {
        setTimeout(() => {
          this.initMap();
          this.initAutocomplete();
        }, 2000);
      }
    },
  },
  methods: {
    showStepTarifas(){

      if (
        this.form.field_i_local_community_price == 624 ||
        this.form.field_i_national_foreign_price == 627 || 
        this.form.field_i_national_foreign_price == 626 || 
        this.form.field_i_children_price == 628
      ) {
        const form11 = this.stepTitles.findIndex((step) => step.id === 11);

        this.stepTitles[form11].show = true;

      } else {
        
        const form11 = this.stepTitles.findIndex((step) => step.id === 11);

        this.stepTitles[form11].show = false;
        
      }

    },
    initMap() {
      this.geocoder = new google.maps.Geocoder(); // Inicializa el servicio de Geocoder

      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat: 3.432973174626513, lng: -73.24313931951718 },
        zoom: 7,
        disableDefaultUI: true, // Desactivar los controles predeterminados
        zoomControl: false, // Desactivar los controles de zoom
        streetViewControl: false, // Desactivar el control de Street View
        mapTypeControl: false, // Desactivar el control de tipo de mapa
        fullscreenControl: false, // Desactivar el control de pantalla completa
        styles: [
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#e0efef",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry.fill",
            stylers: [
              {
                visibility: "on",
              },
              {
                hue: "#1900ff",
              },
              {
                color: "#c0e8e8",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                lightness: 100,
              },
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: 700,
              },
            ],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#7dcdcd",
              },
            ],
          },
        ],
      });
      this.marker = new google.maps.Marker({
        map: this.map,
        position: this.map.getCenter(),
        draggable: true, // Permitir arrastrar el marcador
      });

      // Añadir un listener para el evento click en el mapa
      this.map.addListener("click", (event) => {
        this.setAddressFromCoordinates(event.latLng);
      });

      // Añadir un listener para el evento dragend del marcador
      this.marker.addListener("dragend", (event) => {
        this.setAddressFromCoordinates(event.latLng);
      });
    },

    initAutocomplete() {
      // Asegurarse de que el campo de texto está correctamente referenciado
      const input = this.$refs.addressField.$el.querySelector("input");

      if (input) {
        const autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.bindTo("bounds", this.map);

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (!place.geometry || !place.geometry.location) {
            return;
          }

          this.form.field_i_attraction_location = place.formatted_address;
          this.map.setCenter(place.geometry.location);
          this.marker.setPosition(place.geometry.location);
        });
      } else {
        console.error(
          "No se pudo encontrar el campo de entrada para Google Maps Autocomplete."
        );
      }
    },

    setAddressFromCoordinates(latLng) {
      if (this.geocoder) {
        this.geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              this.form.field_i_attraction_location = results[0].formatted_address;
              this.marker.setPosition(latLng);
              this.map.setCenter(latLng);
            } else {
              console.error("No se encontraron resultados.");
            }
          } else {
            console.error("Error en Geocoder: " + status);
          }
        });
      } else {
        console.error("Geocoder no está inicializado.");
      }
    },

    onPlaceChanged() {
      // This method is to handle manual address entry
    },

    /* DATOS DE OTRO FORMULARIO, VALIDAR PARA REEMPLAZAR O QUITAR */

    async saveForm() {
      this.loadingPageOverlay = true;
      let otherThis = this;
      await setTimeout(async function () {
        const allValid = await otherThis.validateAllSteps();
        //const allValid = true;

        if (allValid) {
          otherThis.formResponseStore();
        } else {
          otherThis.loadingPageOverlay = false;
          console.log("Hay errores en algunos de los formularios.");
        }
      }, 1000);
    },

    async validateAllSteps() {
      let allValid = true;

      // Filtramos los pasos visibles y recorremos su longitud
      const visibleSteps = this.stepTitles.filter((step) => step.show);

      for (let i = 0; i < visibleSteps.length; i++) {
        this.stepFormServiceOrder = visibleSteps[i].id; // Navegar al paso correspondiente
        await this.$nextTick(); // Esperar a que el DOM se actualice y el formulario se monte

        const formRef = this.$refs[`formStep${visibleSteps[i].id}`];

        if (formRef && formRef.validate) {
          const { valid } = await formRef.validate();

          if (!valid) {
            allValid = false;

            // Resaltar los campos con errores
            this.$nextTick(() => {
              const firstErrorField = formRef.$el.querySelector(".v-input--error");
              if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: "smooth" });
              }
            });

            break; // Detener la validación en el primer error encontrado
          }
        }
      }

      return allValid;
    },
    formResponseStore() {
      let url = process.env.VUE_APP_API_URL;

      axios
        .post(url + "/form-responses/store", {
          form: this.form,
          form_relations_question: this.form_relations_question,
          form_id: 10,
          user_id: this.$store.state.user.id,
          season_id: this.season_id,
        })
        .then((response) => {
          this.dialogResponse = {
            title: "¡Formulario enviado con éxito!",
            message:
              "Tus respuestas han sido registradas correctamente. Gracias por tomarte el tiempo de completar el formulario. Puedes estar seguro de que tus datos han sido guardados de manera segura.",
            type: "success",
            color: "secondary",
            icon: "mdi-check",
            show: true,
            redirect: false,
          };
          console.log(response);
          this.loadingPageOverlay = false;
          //resetear todo el componente
          this.resetFormFields();
          this.stepFormServiceOrder = 1;
        })
        .catch((error) => {
          this.loadingPageOverlay = false;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status == 422
          ) {
            //ERROR RETORNADO POR LAS VALIDACIONES
            this.toast.error({
              component: ErrorLayoutComponent,
              props: {
                errorMessage: error.response.data,
              },
              timeout: 8000,
            });
          } else {
            this.dialogResponse = {
              title: "Error al enviar el formulario",
              message:
                "Ocurrió un problema al intentar guardar tus respuestas. Por favor, revisa los campos y vuelve a intentarlo. Si el problema persiste, contacta al soporte técnico.",
              type: "success",
              color: "red-app",
              icon: "mdi-cloud-alert",
              show: true,
              redirect: false,
            };
          }

          console.log(error);
        });
    },
    resetFormFields() {
      this.form = {
        field_i_attraction_services: [],
        field_i_other_service: null,
        field_i_ecosystems: [],
        field_i_protected_area: null,
        field_i_protected_area_type: [],
        field_i_infrastructure_elements: [],
        field_i_attraction_property_type: null,
        field_i_attraction_access_road_type: null,
        field_i_local_community_price: null,
        field_i_foreign_price: null,
        field_i_national_price: null,
        field_i_open_months: [],
        field_i_high_season_months: [],
        field_i_capacity_study: null,
      };

      this.stepFormServiceOrder = 1;
    },

    async getInfoForm() {
      try {
        let url = process.env.VUE_APP_API_URL;
        const token = this.$store.state.token;

        axios
          .get(url + `/forms/show/10`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.formConfig = response.data.form_config;
            this.form_relations_question = response.data.form_relations_question;

            if (this.formConfig && this.formConfig.questions) {
              const field_a_managed_sites = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_managed_sites"
              );
              if (field_a_managed_sites) {
                this.formItems.itemsAdminSitesManage = field_a_managed_sites.options;
              }

              const items_attraction_location_city = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_attraction_location_city"
              );
              if (items_attraction_location_city) {
                this.formItems.itemsCityAttractives =
                  items_attraction_location_city.options;
              }

              const item_atraction_nature_activities = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_nature_tourism_activities"
              );
              if (item_atraction_nature_activities) {
                this.formItems.itemsNatureTourismActivities =
                  item_atraction_nature_activities.options;
              }

              const field_i_attraction_services = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_attraction_services"
              );
              if (field_i_attraction_services) {
                this.formItems.itemsAttractionServices =
                  field_i_attraction_services.options;
              }
              const field_i_ecosystems = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_ecosystems"
              );
              if (field_i_ecosystems) {
                this.formItems.itemsEcosystems = field_i_ecosystems.options;
              }
              const field_i_protected_area = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_protected_area"
              );
              if (field_i_protected_area) {
                this.formItems.itemsProtectedArea = field_i_protected_area.options;
              }
              const field_i_protected_area_type = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_protected_area_type"
              );
              if (field_i_protected_area_type) {
                this.formItems.itemsTypeAreaProtected =
                  field_i_protected_area_type.options;
              }
              const field_i_infrastructure_elements = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_infrastructure_elements"
              );
              if (field_i_infrastructure_elements) {
                this.formItems.itemsInfrastructureElements =
                  field_i_infrastructure_elements.options;
              }
              const field_i_attraction_property_type = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_attraction_property_type"
              );
              if (field_i_attraction_property_type) {
                this.formItems.itemsPropertyType =
                  field_i_attraction_property_type.options;
              }
              const field_i_attraction_access_road_type = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_attraction_access_road_type"
              );
              if (field_i_attraction_access_road_type) {
                this.formItems.itemsRoadType =
                  field_i_attraction_access_road_type.options;
              }
              const field_i_local_community_price = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_local_community_price"
              );
              if (field_i_local_community_price) {
                this.formItems.itemsLocalCommunityPrice =
                  field_i_local_community_price.options;
              }
              const field_i_national_foreign_price = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_national_foreign_price"
              );
              if (field_i_national_foreign_price) {
                this.formItems.itemsNationalForeignPrice =
                  field_i_national_foreign_price.options;
              }
              const field_i_children_price = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_children_price"
              );
              if (field_i_children_price) {
                this.formItems.itemsChildrenPrice = field_i_children_price.options;
              }
              const field_i_open_months = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_open_months"
              );
              if (field_i_open_months) {
                this.formItems.itemsMonthsOpen = field_i_open_months.options.sort(
                  (a, b) => a.id - b.id
                );
              }
              const field_i_high_season_months = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_high_season_months"
              );
              if (field_i_high_season_months) {
                this.formItems.itemsMonthsHighSeason = field_i_high_season_months.options.sort(
                  (a, b) => a.id - b.id
                );
              }
              const field_i_carrying_capacity_study = this.formConfig.questions.find(
                (q) => q.v_model === "field_i_carrying_capacity_study"
              );
              if (field_i_carrying_capacity_study) {
                this.formItems.itemsCapacityStudy =
                  field_i_carrying_capacity_study.options;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.showDeleteConfirmation = false;
      }
    },

    getAccommodationPlace() {
      /* this.formItems.itemsAccommodationPlace; */

      let url = process.env.VUE_APP_API_URL;
      axios
        .get(url + "/select-option/get-accommodations")
        .then((response) => {
          this.formItems.itemsAccommodationPlace = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

        // Control de las sombras
        this.showTopShadow = container.scrollTop > 0;
        this.showBottomShadow =
          container.scrollTop < container.scrollHeight - container.clientHeight;
      }
    },
    scrollToItem(index) {
      const item = this.$refs["timelineItem" + index][0]; // Acceso directo al primer elemento
      if (item) {
        const container = this.$refs.scrollContainer;
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Ajuste adicional para el margen superior
        const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

        container.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
        console.log(
          "Item Rect:",
          itemRect,
          "Container Rect:",
          containerRect,
          "Offset Top:",
          offsetTop
        );
      }
    },

    toggleActividadesCamara(activity) {
      const index = this.selectActividadesCamara.indexOf(activity);
      if (index === -1) {
        this.selectActividadesCamara.push(activity);
      } else {
        this.selectActividadesCamara.splice(index, 1);
      }
    },

    toggleActividadesOfrecidas(activity) {
      const index = this.selectActividadesOfrecidas.indexOf(activity);
      if (index === -1) {
        this.selectActividadesOfrecidas.push(activity);
      } else {
        this.selectActividadesOfrecidas.splice(index, 1);
      }
    },

    toggleServicioAdicional(activity) {
      const index = this.selectServicioAdicional.indexOf(activity);
      if (index === -1) {
        this.selectServicioAdicional.push(activity);
      } else {
        this.selectServicioAdicional.splice(index, 1);
      }
    },

    toggleGremiosAsociaciones(activity) {
      const index = this.selectGremiosAsociaciones.indexOf(activity);
      if (index === -1) {
        this.selectGremiosAsociaciones.push(activity);
      } else {
        this.selectGremiosAsociaciones.splice(index, 1);
      }
    },

    starryNight() {
      anime({
        targets: ["#sky .star"],
        opacity: [
          { duration: 700, value: "0" },
          { duration: 700, value: "1" },
        ],
        easing: "linear",
        loop: true,
        delay: (el, i) => 50 * i,
      });
    },
    shootingStars() {
      anime({
        targets: ["#shootingstars .wish"],
        easing: "linear",
        loop: true,
        delay: (el, i) => 1000 * i,
        opacity: [{ duration: 700, value: "1" }],
        width: [{ value: "150px" }, { value: "0px" }],
        translateX: 350,
      });
    },
    randomRadius() {
      return Math.random() * 0.7 + 0.6;
    },
    getRandomX() {
      return Math.floor(Math.random() * Math.floor(this.vw)).toString();
    },
    getRandomY() {
      return Math.floor(Math.random() * Math.floor(this.vh)).toString();
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    },

    goToPreviousStep() {
      if (this.previousStep) {
        this.stepFormServiceOrder = this.previousStep.id;
      }
    },
    async goToNextStep() {
      if (this.nextStep) {
        //VALIDAR FORMULARIO ACTUAL EN REF formStep1,2,3... etc
        const { valid } = await this.$refs[
          "formStep" + this.stepFormServiceOrder
        ].validate();
        if (valid) {
          this.stepFormServiceOrder = this.nextStep.id;
        }
      }
    },
    showGroupLabel(item, index) {
      // Verifica si es el primer paso del grupo visible
      return index === 0 || item.group !== this.visibleSteps[index - 1].group;
    },
    async cancelDialogResponse() {
      this.dialogResponse = null;
    },
  },

  mounted() {
    this.starryNight();
    this.shootingStars();

    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);

    this.getInfoForm();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  computed: {
    filteredRatingsStateInfrastructure() {
      return this.ratingsStateInfrastructure.filter((item) =>
        this.form.field_i_infrastructure_elements.includes(item.id)
      );
    },

    visibleSteps() {
      // Filtrar los pasos visibles
      return this.stepTitles.filter((item) => item.show);
    },
    currentTitle() {
      const step = this.stepTitles[this.stepFormServiceOrder - 1];
      // Busca el label del grupo al que pertenece el step actual
      const groupLabel = this.stepTitles.find(
        (item) => item.group === step.group && item.label
      )?.label;

      return {
        title: step.title,
        label: groupLabel || "", // Si no se encuentra el label, se asigna una cadena vacía
      };
    },

    nextStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return this.stepTitles.slice(currentIndex + 1).find((step) => step.show) || null;
    },

    previousStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return (
        this.stepTitles
          .slice(0, currentIndex)
          .reverse()
          .find((step) => step.show) || null
      );
    },
  },
};
</script>
<style>
.v-field__input {
  padding-top: 8px;
}

/* #sky {
      width: 100vw;
      height: 100vh;
      position: fixed;
      overflow: hidden;
      margin: 0;
      padding: 0;
  } */

/* #shootingstars {
      margin: 0;
      padding: 0;
      width: 150vh;
      height: 100vw;
      position: fixed;
      overflow: hidden;
      transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
  } */

/* .wish {
      height: 2px;
      top: 300px;
      width: 100px;
      margin: 0;
      opacity: 0;
      padding: 0;
      background-color: white;
      position: absolute;
      background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
      filter: drop-shadow(0 0 6px white);
      overflow: hidden;
  } */

.v-row {
  margin: 0;
}

.two-columns-div {
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  display: grid;
}
</style>
