<template>
  <v-row>
    <v-col v-for="(group, index) in groupedResponses" :key="index" md="4" cols="12">
      <v-card outlined class="pa-4 mb-4" elevation="0">
        <v-card-title>{{ group.name_group }}</v-card-title>

        <v-list v-for="question in group.questions" :key="question.question_id">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-wrap mb-2">{{
                question.question_text
              }}</v-list-item-title>

              <v-list-item-subtitle
                class="text-wrap d-block"
                v-if="question.question_type === 'text'"
              >
                <b>Respuesta:</b> {{ question.text_response }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                class="text-wrap d-block"
                v-else-if="question.question_type === 'single_choice'"
              >
                <b>Opción seleccionada:</b> {{ question.selected_options.text }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else-if="question.question_type === 'multiple_choice'"
              >
                <b>Opciones seleccionadas:</b>
                <v-chip-group column>
                  <v-chip
                    class="text-wrap d-block py-2 h-auto"
                    v-for="option in question.selected_options"
                    :key="option.id"
                  >
                    {{ option.text }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    responses: {
      type: Array,
      required: true,
    },
  },
  name: "FormResponses8",
  data() {
    return {
      groupConfig: [
        { name_group: "Información general", question_ids: [313, 242, 255] },
        { name_group: "Zonas Comunes", question_ids: [280, 281, 282] },
        {
          name_group: "Servicio de recepción y servicio a la habitación",
          question_ids: [283, 284, 285, 286],
        },
        { name_group: "Habitación", question_ids: [287, 288, 289, 290] },
        { name_group: "Alojamiento", question_ids: [291,
292,
293,
294,
295,
296,
297,
298,
299,
300,
301,
302] },
        { name_group: "Consumo de alimentos", question_ids: [303] },
        { name_group: "Alimentos", question_ids: [304,
305,
306,
307,
308,
309] },
        { name_group: "Condiciones de las instalaciones", question_ids: [310] },
        { name_group: "Relación calidad precio", question_ids: [311] },
        { name_group: "Satisfacción general", question_ids: [312] },
      ],
    };
  },
  computed: {
    groupedResponses() {
      return this.groupConfig
        .map((group) => {
          // Filtra las preguntas que pertenecen al grupo actual
          const questions = this.responses.filter((response) =>
            group.question_ids.includes(response.question_id)
          );

          // Retorna el grupo solo si contiene preguntas
          return questions.length > 0
            ? { name_group: group.name_group, questions }
            : null;
        })
        .filter((group) => group !== null); // Filtra los grupos que son null
    },
  },
};
</script>
