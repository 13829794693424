<template>
  <v-container fluid class="px-0" style="height: 100%">
    <v-card-text class="px-0">
      <v-row style="height: 100%">
        <v-col cols="12" md="12" class="">
          <v-layout>
            <!-- <v-navigation-drawer floating permanent color="transparent">
              <v-list rounded density="compact" color="primary" value="profile">
                <v-list-item
                  link
                  rounded="pill"
                  :class="menuProfile == 'profile' ? 'bg-primary' : null"
                  @click="menuProfile = 'profile'"
                >
                  <v-list-item-title class="px-2 font-weight-regular text-body-2">
                    <v-icon start>mdi-account-edit</v-icon> Información Personal
                  </v-list-item-title>
               
                </v-list-item>
                <v-list-item
                  link
                  rounded="pill"
                  :class="menuProfile == 'secure' ? 'bg-primary' : null"
                  @click="menuProfile = 'secure'"
                >
                  <v-list-item-title class="px-2 font-weight-regular text-body-2">
                    <v-icon start>mdi-form-textbox-password</v-icon> Seguridad
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  disabled
                  link
                  rounded="pill"
                  :class="menuProfile == 'notification' ? 'bg-primary' : null"
                  @click="menuProfile = 'notification'"
                >
                  <v-list-item-title class="px-2 font-weight-regular text-body-2">
                    <v-icon start>mdi-bell</v-icon> Notificaciones
                  </v-list-item-title>
                  <template v-slot:append>
                    <v-icon>mdi-lock</v-icon>
                  </template>
                </v-list-item>
                <v-list-item
                  disabled
                  link
                  rounded="pill"
                  :class="menuProfile == 'priv' ? 'bg-primary' : null"
                  @click="menuProfile = 'priv'"
                >
                  <v-list-item-title class="px-2 font-weight-regular text-body-2">
                    <v-icon start>mdi-shield-account</v-icon> Privacidad
                  </v-list-item-title>
                  <template v-slot:append>
                    <v-icon>mdi-lock</v-icon>
                  </template>
                </v-list-item>
                <v-list-item
                  disabled
                  link
                  rounded="pill"
                  :class="menuProfile == 'priv' ? 'bg-primary' : null"
                  @click="menuProfile = 'priv'"
                >
                  <v-list-item-title class="px-2 font-weight-regular text-body-2">
                   <v-icon start>mdi-cog</v-icon> Configuración de Cuenta
                  </v-list-item-title>
                  <template v-slot:append>
                    <v-icon>mdi-lock</v-icon>
                  </template>
                </v-list-item>
                <v-list-item
                  disabled
                  link
                  rounded="pill"
                  :class="menuProfile == 'priv' ? 'bg-primary' : null"
                  @click="menuProfile = 'priv'"
                >
                  <v-list-item-title class="px-2 font-weight-regular text-body-2">
                   <v-icon start>mdi-history</v-icon> Historial de Sesiones
                  </v-list-item-title>
                  <template v-slot:append>
                    <v-icon>mdi-lock</v-icon>
                  </template>
                </v-list-item>
              </v-list>
            </v-navigation-drawer> -->
            <v-main class="" style="min-height: 500px">
              <v-card
                rounded="lg"
                class="mx-auto mb-5 animate__animated animate__fadeIn"
                color="transparent elevation-0"
                max-width="1000"
                v-if="menuProfile == 'profile'"
              >
                <v-card-title class="text-wrap text-h6 mb-5">Datos Básicos</v-card-title>
                <v-card-text style="max-width: 800px" class="mx-auto">
                  <v-row>
                    <v-col sm="12" md="12" cols="12" class="py-1 mb-5">
                      <span class="font-weight-medium">Foto de Perfil</span>
                      <v-list-item class="px-0">
                        <template v-slot:prepend>
                          <v-avatar class="mx-auto" size="100" color="primary">
                            <v-img
                              src="https://randomuser.me/api/portraits/men/81.jpg"
                              alt="Profile picture"
                              cover
                              srcset=""
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey-lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <!-- <span v-else class="text-h3">{{ getInitials($root.infoUser.name,
                                                $root.infoUser.last_name) }}</span> -->
                          </v-avatar>
                        </template>

                        <v-item-title>
                          <div>
                            <v-btn
                              rounded="lg"
                              width="130"
                              variant="tonal"
                              color="primary"
                              class="text-capitalize mx-2"
                              >Cargar Foto</v-btn
                            >

                            <v-btn
                              rounded="lg"
                              width="130"
                              variant="text"
                              color="red-app"
                              class="text-capitalize mx-2"
                              >Eliminar Foto</v-btn
                            >
                          </div>
                        </v-item-title>
                      </v-list-item>
                    </v-col>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <span class="font-weight-medium">Nombre</span>
                      <v-text-field
                        rounded="lg"
                        required
                        variant="outlined"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <span class="font-weight-medium">Apellido</span>
                      <v-text-field
                        rounded="lg"
                        required
                        variant="outlined"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <span class="font-weight-medium">Tipo de identificación</span>
                      <v-select
                        rounded="lg"
                        required
                        variant="outlined"
                        density="compact"
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <span class="font-weight-medium">Número de identificación</span>
                      <v-text-field
                        rounded="lg"
                        required
                        variant="outlined"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <span class="font-weight-medium">Teléfono</span>
                      <v-text-field
                        rounded="lg"
                        required
                        variant="outlined"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-title class="text-wrap text-h6 mb-5"
                  >Detalles Adicionales</v-card-title
                >
                <v-card-text style="max-width: 800px" class="mx-auto">
                  <v-row>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <span class="font-weight-medium">Fecha de Nacimiento</span>
                      <v-text-field
                        rounded="lg"
                        required
                        variant="outlined"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <span class="font-weight-medium">Dirección</span>
                      <v-text-field
                        rounded="lg"
                        required
                        variant="outlined"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <span class="font-weight-medium">Género</span>
                      <v-select
                        rounded="lg"
                        required
                        variant="outlined"
                        density="compact"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded="lg"
                    width="200"
                    variant="tonal"
                    disabled
                    color="black"
                    class="text-capitalize mx-2"
                    >Guardar Cambios</v-btn
                  >
                </v-card-actions>
              </v-card>

              <v-card
                rounded="lg"
                class="mx-auto mb-5 animate__animated animate__fadeIn"
                color="transparent elevation-0"
                max-width="1000"
                v-if="menuProfile == 'secure'"
              >
                <v-card-title class="text-wrap text-h6 mb-5"
                  >Ingreso al Sistema</v-card-title
                >
                <v-card-text style="max-width: 800px" class="mx-auto">
                  <v-row>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <span class="font-weight-medium">Correo Electrónico</span>
                      <v-text-field
                        rounded="lg"
                        required
                        variant="outlined"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <!-- <span class="font-weight-medium">Apellido</span>
                      <v-text-field
                        rounded="lg"
                        required
                        variant="outlined"
                        density="compact"
                      ></v-text-field> -->
                    </v-col>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <span class="font-weight-medium">Contraseña Actual</span>
                      <v-text-field
                        :type="visible ? 'text' : 'password'"
                        rounded="lg"
                        density="compact"
                        variant="outlined"
                      >
                        <template v-slot:append-inner="">
                          <v-icon
                            style="opacity: 1"
                            color="primary"
                            @click="visible = !visible"
                          >
                            {{ visible ? "mdi-eye-off" : "mdi-eye" }}
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" cols="12" class="py-1">
                      <span class="font-weight-medium">Nueva Contraseña</span>
                      <v-text-field
                        :type="visible ? 'text' : 'password'"
                        rounded="lg"
                        density="compact"
                        variant="outlined"
                      >
                        <template v-slot:append-inner="">
                          <v-icon
                            style="opacity: 1"
                            color="primary"
                            @click="visible = !visible"
                          >
                            {{ visible ? "mdi-eye-off" : "mdi-eye" }}
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded="lg"
                    width="200"
                    variant="tonal"
                    disabled
                    color="black"
                    class="text-capitalize mx-2"
                    >Guardar Cambios</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-main>
          </v-layout>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>
<script>
export default {
  name: "ProfileAdmin",
  data() {
    return {
      loadingGetData: false,
      menuProfile: "profile",
      watchPage: true,
      visible: false,
    };
  },
  methods: {},
};
</script>

<style>
.profile-heading {
  margin-block-start: -6.5rem;
}
</style>
