<template>
  <v-row>
    <v-col
      v-for="(group, index) in groupedResponses"
      :key="index"
      :md="group.name_group == 'Registro' ? 12 : 4"
      cols="12"
    >
      <v-card outlined class="pa-4 mb-4" elevation="0">
        <v-card-title>{{ group.name_group }}</v-card-title>

        <v-list v-for="question in group.questions" :key="question.question_id">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-wrap mb-2">{{
                question.question_text
              }}</v-list-item-title>

              <v-list-item-subtitle class="text-wrap d-block" v-if="question.question_type === 'text'">
                <b>Respuesta:</b> {{ question.text_response }}
              </v-list-item-subtitle>

              <v-list-item-subtitle class="text-wrap d-block"
                v-else-if="question.question_type === 'single_choice'"
              >
                <b>Opción seleccionada:</b> {{ question.selected_options.text }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else-if="question.question_type === 'multiple_choice'"
              >
                <b>Opciones seleccionadas:</b>
                <v-chip-group column>
                  <v-chip class="text-wrap d-block py-2 h-auto" v-for="option in question.selected_options" :key="option.id">
                    {{ option.text }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-subtitle>

              <!-- SI ES TIPO JSON DEBE RECORRER Y MOSTRAR LA INFO  -->
              <v-list-item-subtitle v-else-if="question.question_type === 'json'">
                <b>Respuesta:</b>
                <v-list>
                  <v-list-item
                    v-for="(value, key) in JSON.parse(question.text_response)"
                    :key="key"
                  >
                    <v-table density="compact" class="integrantes-table-responsive">
                      <thead>
                        <tr>
                          <th colspan="5"></th>
                          <th colspan="3" class="text-center text-caption">
                            Procedencia
                          </th>
                          <th colspan="4"></th>
                        </tr>
                        <tr>
                          <th class="text-center text-caption">Tipo Documento</th>
                          <th class="text-center text-caption">
                            Número de identificación
                          </th>
                          <th class="text-center text-caption">Nombre completo</th>
                          <th class="text-center text-caption">Sexo</th>
                          <th class="text-center text-caption">Edad</th>
                          <th class="text-center text-caption">Grupo Poblacional</th>
                          <th class="text-center text-caption">País</th>
                          <th class="text-center text-caption">Departamento/Estado</th>
                          <th class="text-center text-caption">Ciudad</th>
                          <th class="text-center text-caption">Nivel Educativo</th>
                          <th class="text-center text-caption">Teléfono</th>
                          <th class="text-center text-caption">Correo Electrónico</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody style="display: contents">
                        <tr>
                          <td data-label="Tipo Documento" class="text-caption">
                            {{ value.tipo_doc ? value.tipo_doc.text : null }}
                          </td>
                          <td data-label="Número de identificación" class="text-caption">
                            {{ value.numero_identificacion }}
                          </td>
                          <td data-label="Nombre completo" class="text-caption">
                            {{ value.nombre_completo }}
                          </td>
                          <td data-label="Sexo" class="text-caption">
                            {{ value.sexo ? value.sexo.text : null }}
                          </td>
                          <td data-label="Edad" class="text-caption">
                            {{ value.edad }}
                          </td>
                          <td data-label="Grupo Poblacional" class="text-caption">
                            {{
                              value.grupo_poblacional
                                ? value.grupo_poblacional.text
                                : null
                            }}
                          </td>
                          <td data-label="País" class="text-caption">
                            {{
                              value.procedencia && value.procedencia.pais
                                ? value.procedencia.pais.name
                                : null
                            }}
                          </td>
                          <td data-label="Departamento/Estado" class="text-caption">
                            {{
                              value.procedencia && value.procedencia.departamento
                                ? value.procedencia.departamento.name
                                : null
                            }}
                          </td>
                          <td data-label="Ciudad" class="text-caption">
                            {{
                              value.procedencia && value.procedencia.ciudad
                                ? value.procedencia.ciudad.name
                                : null
                            }}
                          </td>
                          <td data-label="Nivel Educativo" class="text-caption">
                            {{
                              value.nivel_educativo ? value.nivel_educativo.text : null
                            }}
                          </td>
                          <td data-label="Teléfono" class="text-caption">
                            {{ value.telefono }}
                          </td>
                          <td data-label="Correo Electrónico" class="text-caption">
                            {{ value.correo_electronico }}
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-list-item>
                </v-list>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    responses: {
      type: Array,
      required: true,
    },
  },
  name: "FormResponses6",
  data() {
    return {
      groupConfig: [
        { name_group: "Atractivo Turístico", question_ids: [246, 247] },
        { name_group: "Detalle de visita y Guía", question_ids: [248, 249, 250, 251] },
        {
          name_group: "Registro",
          question_ids: [252],
        },
      ],
    };
  },
  computed: {
    groupedResponses() {
      return this.groupConfig
        .map((group) => {
          // Filtra las preguntas que pertenecen al grupo actual
          const questions = this.responses.filter((response) =>
            group.question_ids.includes(response.question_id)
          );

          // Retorna el grupo solo si contiene preguntas
          return questions.length > 0
            ? { name_group: group.name_group, questions }
            : null;
        })
        .filter((group) => group !== null); // Filtra los grupos que son null
    },
  },
};
</script>

<style>
.integrantes-table-responsive {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 800px) {
  .integrantes-table-responsive .v-table__wrapper {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .integrantes-table-responsive table {
    border-collapse: collapse;
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .integrantes-table-responsive thead {
    display: none;
  }

  .integrantes-table-responsive tbody tr {
    display: block;
  }

  .integrantes-table-responsive tbody td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
  }

  .integrantes-table-responsive tbody td::before {
    content: attr(data-label);
    flex-basis: 50%;
    text-align: left;
    font-weight: bold;
    padding-right: 10px;
  }

  .integrantes-table-responsive td {
    border-bottom: 1px solid #e0e0e0;
    height: 40px !important;
  }
}
</style>
