<template>
  <v-row>
    <v-col v-for="(group, index) in groupedResponses" :key="index" md="4" cols="12">
      <v-card outlined class="pa-4 mb-4" elevation="0">
        <v-card-title>{{ group.name_group }}</v-card-title>

        <v-list v-for="question in group.questions" :key="question.question_id">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-wrap mb-2">{{
                question.question_text
              }}</v-list-item-title>

              <v-list-item-subtitle class="text-wrap d-block" v-if="question.question_type === 'text'">
                <b>Respuesta:</b> {{ question.text_response }}
              </v-list-item-subtitle>

              <v-list-item-subtitle class="text-wrap d-block"
                v-else-if="question.question_type === 'single_choice'"
              >
                <b>Opción seleccionada:</b> {{ question.selected_options.text }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-else-if="question.question_type === 'multiple_choice'"
              >
                <b>Opciones seleccionadas:</b>
                <v-chip-group column>
                  <v-chip class="text-wrap d-block py-2 h-auto" v-for="option in question.selected_options" :key="option.id">
                    {{ option.text }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    responses: {
      type: Array,
      required: true,
    },
  },
  name: "FormResponses2",
  data() {
    return {
      groupConfig: [
        { name_group: "Información Legal", question_ids: [30, 31, 32] },
        { name_group: "Información de Contacto", question_ids: [33, 34] },
        { name_group: "Registro y Categoría", question_ids: [35, 36, 36] },
        { name_group: "Actividades Económicas", question_ids: [37] },
        { name_group: "Clasificación de Turismo", question_ids: [38, 38] },
        { name_group: "Turismo de Base Comunitaria", question_ids: [39] },
        { name_group: "Actividades Ofrecidas", question_ids: [40] },
        { name_group: "Ocupación y Alojamiento", question_ids: [41, 42] },
        { name_group: "Clasificación del Alojamiento", question_ids: [43] },
        { name_group: "Capacidad de Alojamiento", question_ids: [44, 45, 46] },
        { name_group: "Servicios Adicionales", question_ids: [47] },
        { name_group: "Precio Promedio de los Platos", question_ids: [48] },
        { name_group: "Restaurantes y Bar", question_ids: [49, 50, 51, 52, 53] },
        { name_group: "Aforo y Vehículos", question_ids: [54, 55] },
        { name_group: "Información de Turistas", question_ids: [56, 57] },
        { name_group: "Información de Ventas", question_ids: [58, 59] },
        { name_group: "Asociaciones y Estrategias", question_ids: [60, 61] },
      ],
    };
  },
  computed: {
    groupedResponses() {
      return this.groupConfig
        .map((group) => {
          // Filtra las preguntas que pertenecen al grupo actual
          const questions = this.responses.filter((response) =>
            group.question_ids.includes(response.question_id)
          );

          // Retorna el grupo solo si contiene preguntas
          return questions.length > 0
            ? { name_group: group.name_group, questions }
            : null;
        })
        .filter((group) => group !== null); // Filtra los grupos que son null
    },
  },
};
</script>
